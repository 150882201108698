import React, { Component } from "react";
import dompurify from "dompurify";
import Button from "react-bootstrap/Button";
import Modal from "react-modal";
import VideoPlayer from "./../modules/videoPlayer/VideoPlayer"
import { Image, Transformation } from "cloudinary-react";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./OnDemandModal.module.scss";

export default class OnDemandModal4 extends Component {

  onDemandVideoPlayed = () => {
    requestPoints("viewOnDemandVideo", this.props.selectedOnDemand._id);
  };

  onDemandPDFViewed = (pdfURL) => {
    requestPoints("viewOnDemandPDF", pdfURL);
  };

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  render() {
    return (
      <Modal
        isOpen={this.props.showOnDemandModal}
        ariaHideApp={false}
        onRequestClose={this.props.closeOnDemandModal}
        contentLabel="OnDemand Modal"
        overlayClassName={styles.modalOverlay}
        className={styles.modalContent}
      >
        <div className={styles.modalFlexboxColumn}>
          <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
            <h1 className={styles.modalPageTitle}>
              {this.props.selectedOnDemand && this.props.selectedOnDemand ? this.props.selectedOnDemand.contentTitle : "OnDemand Content"}
            </h1>
            <button
              className={styles.modalClose}
              variant="success"
              onClick={this.props.closeOnDemandModal}
              aria-label="Close"
            >
              Close
              <img
                className={styles.modalCloseIcon}
                src="/icons/close-no-circle.png"
                alt="Close"
              />
            </button>
          </div>

          <div className={styles.scrollableArea}>
            {this.props.selectedOnDemand != null ? (

              (this.props.selectedOnDemand.pdfURL ?
                (
                  this.props.selectedOnDemand.pdfThumbnailID ?
                    <a href={this.props.selectedOnDemand.pdfURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => this.onDemandPDFViewed(this.props.selectedOnDemand.pdfURL)}
                    >
                      <Image
                        cloudName="oijqec973f1nf"
                        publicId={this.props.selectedOnDemand.pdfThumbnailID}
                        width="450"
                        height="250"
                        secure={true}
                      >
                        <Transformation
                          height="250"
                          width="450"
                          crop="pad"
                        />
                      </Image> </a> :
                    <a href={this.props.selectedOnDemand.pdfURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => this.onDemandPDFViewed(this.props.selectedOnDemand.pdfURL)}
                    >
                      <img src={window.$videoPath + "icons/webLink.png"} className={styles.pdfIcon} alt="Web Link Icon" />
                    </a>
                )
                : (this.props.selectedOnDemand.videoID ? (
                  <div className={styles.onDemandVideoAspectDiv}>
                    <div className={styles.onDemandVideoDiv}>
                      <VideoPlayer
                        resourceLocator={this.props.selectedOnDemand.videoID}
                        location={"onDemand"}
                        userProfile={this.props.userProfile}
                      />
                    </div>
                  </div>
                ) : null
                ))
            ) : null}

            {
              this.props.selectedOnDemand ?
                <div className={styles.infoArea}>
                  <h2 className={styles.contentTitle}>
                    {this.props.selectedOnDemand && this.props.selectedOnDemand.contentTitle}
                  </h2>
                  <h2 className={styles.onDemandPresenterNameText}>
                    {this.props.selectedOnDemand && this.props.selectedOnDemand.speakerName ?
                      <div>Presenter: {this.props.selectedOnDemand.speakerName}</div> : null}
                  </h2>
                  <div className={styles.aboutTextDiv}>
                    <h2 className={styles.aboutText}
                      dangerouslySetInnerHTML={this.sanitizeCustomField(
                        this.props.selectedOnDemand && this.props.selectedOnDemand.aboutText
                      )}>
                    </h2>
                  </div>
                </div> : null
            }
          </div>
        </div>
      </Modal>
    );
  }
}
