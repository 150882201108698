import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import styles from "./CustomLogin.module.scss";

export default class Login extends Component {
  render() {
    return (
      <div>
        <div className={styles.proportionalDiv}>
          <img
            className={styles.backgroundImage}
            src={this.props.showMobile ? window.$videoPath + "customLoginPage/loginBackgroundMobile.jpg" : window.$videoPath + "customLoginPage/loginBackgroundFull.jpg"}
            alt="Background"
          ></img>
      
          <img
            className={styles.armWhiteLogo}
            src={window.$videoPath + "customLoginPage/smallLogo.png"}
            alt="Arm logo"
          />
          <div className={styles.loginBox}>
            <br />
            <br />
            <img
              className={styles.lockUpWhite}
              src={window.$videoPath + "customLoginPage/logoFromDark.png"}
              alt="Arm logo"
            />
            <br />

            <div className={styles.loginTextEntry}>
              <br />
              <h2 className={styles.signInText}>
                Welcome to Big Picture on Arm!
                <br />
                <br />
                Click below to build your profile
              </h2>

              <br />
              <a href="https://login.microsoftonline.com/armh.onmicrosoft.com/oauth2/v2.0/authorize?response_type=code&client_id=affe496e-1983-47bf-bf4c-73ce02240dc2&redirect_uri=https://bigpicture.arm.com/ssoLogin&scope=openid">
              <button
                className="btn float-left"
                style={{backgroundColor: "#ffc700", borderColor: "#ffc700"}}
              >
                Sign in with Arm SSO
              </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
