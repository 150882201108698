import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import styles from "./CustomLogin.module.scss";
const queryString = require("query-string");

export default class SSOLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToHome: false,
    };
  }

  componentDidMount() {
    console.log("Mounting SSOLogin component");
    // Get code parameter from page request
    const queryParameters = this.props.location.search;
    const queryParamatersParsed = queryString.parse(queryParameters);
    const code = queryParamatersParsed.code;
    console.log("Received code: " + code);
    // Send code to API
    this.submitCode(code);
    // Get given JWT and then redirect into rest of site
    // Need to check JWT here and set admin privs as per standard login
    // Also check whether user is awaiting approval etc. if whitelist in place
  }

  submitCode(code) {
    console.log("Submitting code: " + code);
    fetch("/api/obtainSSOToken", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ code: code }),
    })
      .then((res) => {
        console.log("Received code submit response: " + res.status);
        if (res.status === 200) {
          this.props.checkLoginStatus();
          this.setState({ redirectToHome: true });

          /**res.json().then((data) => {
          console.log("Code submit response: " + data.youAre);
        });
        
          // Note login priviledge
          res.json().then((data) => {
            const roleResponse = data.role;
            console.log("Login response: " + roleResponse);
            if (roleResponse.includes("awaitingConfirmation")) {
              alert(
                "Please ensure you have clicked the link in your account confirmation email"
              );
            } else if (roleResponse.includes("awaitingApproval")) {
              alert(
                "Your registration request will be processed shortly - for any enquiries please contact ..."
              );
            } else if (roleResponse.includes("standard")) {
              this.setState({ redirectToHome: true });
            } else if (roleResponse.includes("admin")) {
              this.setState({ redirectToHome: true });
            }
            this.props.checkLoginStatus();
          }); */
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        //console.error(err);
        alert("Error logging in via SSO - please contact your administrator");
      });
  }

  render() {
    const redirectToHome = this.state.redirectToHome;
    if (redirectToHome === true) {
      return (
        <div>
          <Redirect to="/register" />
        </div>
      );
    } else {
      return (
        <div>
          <div className={styles.proportionalDiv}>
            <img
              className={styles.backgroundImage}
              src={this.props.showMobile ? window.$videoPath + "customLoginPage/loginBackgroundMobile.jpg" : window.$videoPath + "customLoginPage/loginBackgroundFull.jpg"}
              alt="Background"
            ></img>
            <img
              className={styles.armWhiteLogo}
              src={window.$videoPath + "customLoginPage/smallLogo.png"}
              alt="Arm logo"
            />
            <div className={styles.loginBox}>
              <br />
              <br />
              <img
                className={styles.lockUpWhite}
                src={window.$videoPath + "customLoginPage/logoFromDark.png"}
                alt="Arm logo"
              />
              <div className={styles.loginTextEntry}>
                <h2 className={styles.signInText}>
                  Attempting to log in via SSO
                </h2>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
