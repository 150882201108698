import React, { Component } from "react";
import styles from "./RequestPasswordReset.module.scss";
import preLoginStyles from "./Pre-Login.module.scss";

export default class RequestPasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.email.length < 1) {
      alert("Please enter your email address");
    } else {
      fetch("/api/requestPasswordReset", {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.props.handleCloseRequestPasswordResetDiv();
            alert(
              "Thank you.  If there is an account registered to this email address, you will shortly receive an email with a link to reset your password."
            );
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
          alert("Error resetting password please try again");
        });
    } // End of else for form validation
  };

  render() {
    return (
      <div className={styles.requestPasswordResetModal}>
        <div className={styles.requestPasswordResetModalInnerDiv}>
          <img
            className={preLoginStyles.preLoginBoxLogo}
            src={window.$videoPath + "logo.png"}
            alt="Logo"
          />
          <img
            className={preLoginStyles.helpTab}
            src={window.$videoPath + "login/helpTab.png"}
            onClick={this.props.showHelp}
            alt="Help"
          />
          <br />
          <br />
          <h2 className={preLoginStyles.titleText}>RESET PASSWORD</h2>
          <br />

          <form onSubmit={this.handleSubmit} noValidate>
            <input
              className={preLoginStyles.textEntry}
              type="email"
              name="email"
              placeholder="Email address"
              tabIndex="200"
              value={this.state.email}
              onChange={this.handleChange}
            />
            <br />
            <br />
            <p>
              If a valid user account exists for the email address entered, you
              will shortly receive an email with a link to reset your password.
            </p>
            <br />


            <div className={preLoginStyles.loginButtonsRow}>
              <button
                className={preLoginStyles.button}
                onClick={() =>
                  this.setState(this.props.handleCloseRequestPasswordResetDiv)
                }
                type="button"
              >
                Back
              </button>
              <div style={{ flex: 1 }} />
              <input
                type="submit"
                value="Reset Password"
                className={preLoginStyles.highlightedButton}
              />
            </div>
          </form>

          <img src={window.$videoPath + "login/poweredBy.png"} className={preLoginStyles.loginBoxFooter} alt="Login Footer" />

        </div>
      </div >
    );
  }
}
