import React, { Component } from "react";
import styles from "./Login.module.scss";

export default class HoldingPage extends Component {
  render () {
    return (
      <div>
        <div className={styles.proportionalDiv}>
          <img
            className="imageNavBackground"
            src={window.$videoPath + "login/loginBackground.jpg"}
            alt="Background"
          ></img>

          <div className={styles.loginBox}>
            <br />
            <br />
            <img
              className={styles.loginBoxLogo}
              src={window.$videoPath + "logo.png"}
              alt="Logo"
            />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className={styles.loginTextEntry}>
              <h2 className={styles.signInText}>
                The event will open on ...<br />
                <br />
                Please return then to gain access to the platform.
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
