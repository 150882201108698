import React, { Component } from "react";
import dompurify from "dompurify";
import { Link } from "react-router-dom";
import Player from "@vimeo/player";
import { CSSTransition } from "react-transition-group";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./Breakout.module.scss";

// Define once to avoid multiple on plays being registered
var iframe;
var player = null;

export default class Breakout extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      standName: "",
      breakoutLiveURL: "",
      breakoutPrerecordURL: "",
      showMoreInfoIframe: false,
      showQuestionsModal: false,
      isSmallBreakout: true,
      videoStateForwards: true,
      showContent: false,
      breakoutLiveControlChannel: 1,
      slidoEventID: "",
    };
    this.breakoutVideo = React.createRef();
    this.reverseBreakoutVideo = React.createRef();
  }

  componentDidMount () {
    // If this is a small breakout we need to change the opening frame on load
    // In this case all breakouts are small breakouts
    /**if (
      this.props.match.params.breakoutID.includes("5f240c59a23ba10b65f7f67d")
    ) {
      this.setState({ isSmallBreakout: true });
    } */

    fetch(
      "/api/getBreakout?breakoutID=" +
      // Get selected stand ID from React Router path
      this.props.match.params.breakoutID
    )
      // Need to somehow request by received prop ID
      .then((res) => res.json())
      .then((data) => this.loadResponseIntoState(data));

    // Move straight to presentation state
    this.viewPresentation();
  }

  componentDidUpdate () { }

  componentWillUnmount () {
    // Restore corner menu
    this.props.setCornerMenuStatus(1);
  }

  loadResponseIntoState (data) {
    this.setState({
      standName: data.breakout.standName,
      breakoutLiveURL: data.breakout.breakoutLiveURL,
      breakoutPrerecordURL: data.breakout.breakoutPrerecordURL,
      breakoutLiveControlChannel: data.breakout.breakoutLiveControlChannel,
      slidoEventID: data.breakout.slidoEventID,
    });
  }

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  viewPresentation = () => {
    if (this.breakoutVideo) {
      this.breakoutVideo.play();
    }
    // Set timeout in case video does not load/finish
    setTimeout(
      function () {
        this.forwardsVideoEnded();
      }.bind(this),
      3000
    );
  };

  leavePresentation = () => {
    // Invalidate reference to Vimeo player to ensure we re-register when we zoom back in
    player = null;
    this.setState({ showContent: false });
    if (this.reverseBreakoutVideo) {
      this.reverseBreakoutVideo.play();
    }
    // Set timeout in case video does not load/finish
    setTimeout(
      function () {
        this.reverseVideoEnded();
      }.bind(this),
      3000
    );
  };

  registerVimeoPlayEvent = () => {
    // Select appropriate remote control channel to listen to
    var breakoutLive = 0;
    switch (this.state.breakoutLiveControlChannel) {
      case 1:
        breakoutLive = this.props.breakout1Live;
        break;
      case 2:
        breakoutLive = this.props.breakout2Live;
        break;
      case 3:
        breakoutLive = this.props.breakout3Live;
        break;
      case 4:
        breakoutLive = this.props.breakout4Live;
        break;
      default:
        breakoutLive = this.props.breakout1Live;
        break;
    }
    console.log("played the video!");
    requestPoints(
      "playBreakoutVideo",
      this.props.match.params.breakoutID + ":" + breakoutLive
    );
  };

  registerVimeoPlayListener = () => {
    // Track Vimeo plays for analytics
    iframe = document.getElementById("contentIframe");
    //querySelector('iframe[href*="vimeo.com"]');
    console.log("Looking for iframe");
    console.log(iframe);
    console.log(player);
    if (iframe && player === null) {
      // Check here that we haven't already registered a play event for this page
      //console.log("Found iframe");
      player = new Player(iframe);
      player.on("play", this.registerVimeoPlayEvent);

      player.on("pause", function () {
        console.log("paused the video!");
      });
    }
  };

  forwardsVideoEnded = () => {
    this.setState({ videoStateForwards: false, showContent: true });
    // Hide corner menu
    this.props.setCornerMenuStatus(3);
    // The iFrame is not a vimeo player in here
    //this.registerVimeoPlayListener();
  };

  reverseVideoEnded = () => {
    // Need to reset breakout video back to start before transitioning to it
    if (this.breakoutVideo) {
      this.breakoutVideo.currentTime = 0;
    }
    this.setState({ videoStateForwards: true, showContent: false });
  };

  render () {
    // Select appropriate remote control channel to listen to
    var breakoutLive = 0;
    switch (this.state.breakoutLiveControlChannel) {
      case 1:
        breakoutLive = this.props.breakout1Live;
        break;
      case 2:
        breakoutLive = this.props.breakout2Live;
        break;
      case 3:
        breakoutLive = this.props.breakout3Live;
        break;
      case 4:
        breakoutLive = this.props.breakout4Live;
        break;
      default:
        breakoutLive = this.props.breakout1Live;
        break;
    }

    const iFrameContent =
      breakoutLive === 1
        ? this.state.breakoutLiveURL
        : breakoutLive === 0
          ? this.state.breakoutPrerecordURL
          : "https://player.vimeo.com/video/454347783";

    return (
      <div>
        <div className="imageNavDiv">
          {this.state.videoStateForwards ? (
            <div>
              <div
                className={styles.goToPresentation}
                onClick={this.viewPresentation}
              ></div>
              <Link to="/breakoutsStill">
                <img
                  className={`${styles.hoverHotspot} ${styles.exitHoverHotspot}`}
                  src="/hoverShapes/hoverSquare.png"
                  alt="Exit"
                ></img>
              </Link>
            </div>
          ) : null}
          {!this.state.videoStateForwards ? (
            <div
              className={styles.leavePresentation}
              onClick={this.leavePresentation}
            ></div>
          ) : null}

          <div>
            <video
              className="imageNavBackground"
              id="myVideo"
              loop={false}
              muted
              ref={(breakoutVideo) => (this.breakoutVideo = breakoutVideo)}
              onEnded={() => this.forwardsVideoEnded()}
            >
              <source
                src={window.$videoPath + "visuals/" + this.props.language + "/largeSeatedBreakout.mp4"}
                type="video/mp4"
              />
              <source
                src={window.$videoPath + "visuals/" + this.props.language + "/largeSeatedBreakout.webm"}
                type="video/webm"
              />
            </video>
            <CSSTransition
              in={!this.state.videoStateForwards}
              timeout={1500}
              classNames="imageNavBackground"
              unmountOnExit
            >
              <video
                className="imageNavBackground"
                id="myVideo"
                loop={false}
                muted
                ref={(reverseBreakoutVideo) =>
                  (this.reverseBreakoutVideo = reverseBreakoutVideo)
                }
                onEnded={() => this.reverseVideoEnded()}
              >
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/largeSeatedBreakoutReverse.mp4"}
                  type="video/mp4"
                />
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/largeSeatedBreakoutReverse.webm"}
                  type="video/webm"
                />
              </video>
            </CSSTransition>
          </div>

          <CSSTransition
            in={this.state.showContent && iFrameContent.length > 0}
            timeout={1500}
            classNames="imageNavBackground"
            unmountOnExit
          >
            <div className={styles.largeBreakoutiFrameDiv}>
              {this.props.userProfile && this.props.userProfile.firstName && this.props.userProfile.surname ?
                <iframe
                  id="contentIframe"
                  title="Content iFrame"
                  className={styles.iFrameClass}
                  autoPlay
                  //src={iFrameContent}
                  src={this.props.webinarURL + "?displayName=" + this.props.userProfile.firstName}
                  allow="camera;microphone"
                ></iframe> : null}

              {/**<iframe
                id="contentIframe"
                title="Content iFrame"
                className={styles.iFrameClass}
                autoPlay
                src={"/zoomCall.html?meetingID=" + this.props.zoomMeetingID + "&password=" + this.props.zoomPassword + "&username=Will"}
                allow="camera;microphone"
              ></iframe>*/}

              {/**<iframe sandbox={"allow-scripts allow-same-origin allow-presentation allow-forms"} height='615' width={"20%"} src={"https://pollev.com/williamnutbr562"} ></iframe>*/}
              {/**<iframe sandbox={"allow-scripts allow-same-origin allow-presentation allow-forms"} height='615' width={"20%"} src={"https://www.menti.com/ao9grd978y"} ></iframe>*/}
            </div>
          </CSSTransition>

        </div>
      </div>
    );
  }
}
