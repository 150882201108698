import React, { Component } from "react";
import dompurify from "dompurify";
import Button from "react-bootstrap/Button";
import Modal from "react-modal";
import VideoPlayer from "../modules/videoPlayer/VideoPlayer"
import { Image, Transformation } from "cloudinary-react";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./CustomOnDemandModal.module.scss";

export default class OnDemandModal4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoID: ""
    };
  }


  onDemandVideoPlayed = ( selectedOnDemandID, urlID, videoID) => {
    requestPoints("viewOnDemandVideo", selectedOnDemandID + ":" + urlID + ":" + videoID);
  };

  onDemandPDFViewed = (pdfURL) => {
    requestPoints("viewOnDemandPDF", pdfURL);
  };

  onDemandLinkClicked = ( selectedOnDemandID, urlID, linkURL) => {
    requestPoints("other", "onDemandLink:" + selectedOnDemandID + ":" + urlID + ":" + linkURL);
  };

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  linkButtons = () => {
    return this.props.selectedOnDemand.urls.map((url) => {
      return (
        <div key={url.id} className={styles.linkDiv}>
          {url.url ?
            <a href={url.url} target="_blank" rel="noopener noreferrer">
              <button
                className={styles.urlButton}
                onClick={() => this.onDemandLinkClicked(this.props.selectedOnDemand.id, url.id, url.url)}
              >
                {url.title}
                <img
                  src={window.$videoPath + "icons/onDemandLinkArrow.jpg"}
                  className={styles.linkArrow}
                />
              </button>
            </a>
            : null}

          {url.videoID ?
            <button
              className={styles.urlButton}
              onClick={() => {
                this.setState({ videoID: url.videoID })
                this.onDemandVideoPlayed(this.props.selectedOnDemand.id, url.id, url.videoID);
              }
              }
            >{url.title}
              <img
                src={window.$videoPath + "icons/onDemandLinkArrow.jpg"}
                className={styles.linkArrow}
              />
            </button>
            : null}
        </div>
      )
    }

    )
  }

  render () {
    return (
      <Modal
        isOpen={this.props.showOnDemandModal}
        onAfterClose={() => this.setState({ videoID: "" })}
        ariaHideApp={false}
        onRequestClose={this.props.closeOnDemandModal}
        contentLabel="OnDemand Modal"
        overlayClassName={styles.modalOverlay}
        className={styles.modalContent}
      >
        <div className={styles.modalFlexboxColumn}>
          <div className={styles.modalTitleBar} >
            <h1 className={styles.modalPageTitle}>
              {this.props.selectedOnDemand && this.props.selectedOnDemand ? this.props.selectedOnDemand.contentTitle : "OnDemand Content"}
            </h1>
            <button
              className={styles.modalClose}
              variant="success"
              onClick={this.props.closeOnDemandModal}
              aria-label="Close"
            >
              Close
              <img
                className={styles.modalCloseIcon}
                src="/icons/close-no-circle.png"
                alt="Close"
              />
            </button>
          </div>

          <div className={styles.scrollableArea}>
            {
              this.props.selectedOnDemand ?
                <div className={styles.infoArea}>

                  {/** Show either video player or buttons depending on state of videoID */}
                  {this.state.videoID ?
                    <>
                      <br />
                      <button
                        className={styles.highlightedButton}
                        style={{ align: "centre" }}
                        onClick={() => this.setState({ videoID: "" })}>
                        Back to {this.props.selectedOnDemand.contentTitle}
                      </button>
                      <br />
                      <br />
                      <VideoPlayer
                        resourceLocator={this.state.videoID}
                        location={"onDemand"}
                        userProfile={this.props.userProfile}
                      />
                    </>
                    :
                    <>
                      <h4>
                        {this.props.selectedOnDemand.contentInfo}
                        <br />
                        <br />
                      </h4>
                      {this.linkButtons()}
                    </>
                  }

                </div> : null}
          </div>
        </div>
      </Modal>
    );
  }
}
