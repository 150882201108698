import React, { Component } from "react";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import styles from "./../UserSessions.module.scss";

export default class UserSessionsOptionsBreakouts2 extends Component {


    render () {
        return (
            <div>
                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={0}
                        disabled={this.props.session1Disabled}
                        className={styles.sessionOption}
                    //data-tip="<h4>No session selected</h4><br /><br />"
                    //data-for={"sessionInfo"}
                    >
                        No session selected
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={1}
                        disabled={this.props.session1Disabled}
                        className={styles.sessionOption}
                    //data-tip="<h4>Additional text 1</h4><br /><br />"
                    //data-for={"sessionInfo"}
                    >
                        Sustainable Events
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={2}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                    //data-tip="<h4>Additional Text 2</h4><br /><br />"
                    //data-for={"sessionInfo"}
                    >
                        Importance of CSR
                    </ToggleButton>

                </ToggleButtonGroup>

            </div>
        );
    }
}