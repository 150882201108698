import React, { Component } from "react";

import MessageForm from "./MessageForm";
import MessageList from "./MessageList";

import styles from "./ChatFunction.module.scss";
const Chat = require("twilio-chat");

export default class ChatFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      username: null,
      channel: null,
    };
  }

  componentDidMount = () => {
    this.getToken()
      .then(this.createChatClient)
      .then(this.joinGeneralChannel)
      .then(this.configureChannelEvents)
      .catch((error) => {
        this.addMessage({ body: `Error: ${error.message}` });
      });
  };

  getToken = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        messages: [...this.state.messages, { body: `Connecting...` }],
      });

      const userName =
        this.props.userProfile.firstName + " " + this.props.userProfile.surname;
      console.log("Attempt to connect to chat room for user: " + userName);

      fetch("/api/twilioChatToken?userName=" + userName)
        .then((res) => res.json())
        .then((token) => {
          console.log("Received token: " + token.tokenValue);
          resolve(token);
          this.setState({ username: token.identity });
        })
        .catch((err) => {
          console.error(err);
          reject(Error("Failed to connect"));
        });
    });
  };

  createChatClient = (token) => {
    return new Promise((resolve, reject) => {
      resolve(Chat.Client.create(token.tokenValue));
    });
  };

  /**listChannels = (chatClient) => {
    console.log("Listing channels");
    chatClient.getPublicChannelDescriptors().then(function(paginator) {
      var i;
      for (i = 0; i < paginator.items.length; i++) {
        const channel = paginator.items[i];
        console.log("Channel: " + channel.friendlyName);
      }
    });
  }; */

  joinGeneralChannel = (chatClient) => {
    return new Promise((resolve, reject) => {
      chatClient
        .getPublicChannelDescriptors()
        .then(() => {
          console.log("Checking for channel: " + this.props.chatChannel);
          chatClient
            .getChannelByUniqueName(this.props.chatChannel)
            .then((channel) => {
              console.log("Foundchannel");
              this.addMessage({ body: "Joining channel..." });
              this.setState({ channel });
              channel.leave()
                .then(() => {
                  channel
                    .join()
                    .then(() => {
                      this.addMessage({
                        body: `Joined channel as ${this.state.username}`,
                      });
                      window.addEventListener("beforeunload", () =>
                        channel.leave()
                      );
                    })
                    .catch(() => reject(Error("Could not joinchannel.")));

                })
              resolve(channel);
            })
            .catch(() => this.createGeneralChannel(chatClient));
        })
        .catch(() => reject(Error("Could not get channel list.")));
    });
  };

  createGeneralChannel = (chatClient) => {
    return new Promise((resolve, reject) => {
      this.addMessage({ body: "Creating channel..." });
      chatClient
        .createChannel({
          uniqueName: this.props.chatChannel,
          friendlyName: "Chat",
        })
        .then(() => this.joinGeneralChannel(chatClient))
        .catch(() => reject(Error("Could not create channel.")));
    });
  };

  addMessage = (message) => {

    const messageData = {
      ...message,
      me: message.author === this.state.username,
    };

    this.setState({
      messages: [...this.state.messages, messageData],
    });
  };

  handleNewMessage = (text) => {
    if (this.state.channel) {
      this.state.channel.sendMessage(text);
    }
  };

  configureChannelEvents = (channel) => {
    channel.on("messageAdded", ({ author, body }) => {
      this.addMessage({ author, body });
    });

    channel.on("memberJoined", (member) => {
      this.addMessage({ body: `${member.identity} has joined the channel.` });
    });

    channel.on("memberLeft", (member) => {
      this.addMessage({ body: `${member.identity} has left the channel.` });
    });
  };

  render () {
    return (
      <div>
        <div className={styles.chatWindow}>
          <MessageList messages={this.state.messages} />
          <MessageForm onMessageSend={this.handleNewMessage} />
        </div>
      </div>
    );
  }
}
