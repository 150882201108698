import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import dompurify from "dompurify";
import { requestPoints } from "./../helpers/requestPoints.js";
import OnDemandModal from "./OnDemandModal";
import VideoPlayer from "./../modules/videoPlayer/VideoPlayer"
import OnDemandMobile from "./OnDemandMobile"
import styles from "./OnDemand.module.scss";

export default class OnDemand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      introVideoComplete: false,
      onDemandContent: [],
      selectedOnDemand: null,
      showOnDemandModal: false,
      visualGallery: false,
    };
  }

  componentDidMount() {
    // Hide corner menu
    this.props.setCornerMenuStatus(3);
    requestPoints("visitOnDemandArea", "visitOnDemandArea");

    // Done this way to provide default option if props.visualGallery is not set
    if (this.props.visualGallery) {
      this.setState({ visualGallery: true })
    }

    this.loadCurrentDatabaseContent();

    // Start timeout in case video does not load
    setTimeout(
      function () {
        this.setState({ introVideoComplete: true });
      }.bind(this),
      6000
    );
  }

  componentDidUpdate() {
    // Double check corner menu status is correct
    if (this.props.cornerMenuStatus !== 3) {
      console.log(this.props.cornerMenuStatus);
      this.props.setCornerMenuStatus(3);
    }
  }

  componentDidUpdate() {
    // Double check corner menu status is correct
    if (this.props.cornerMenuStatus !== 3) {
      console.log(this.props.cornerMenuStatus);
      this.props.setCornerMenuStatus(3);
    }
  }

  loadCurrentDatabaseContent() {
    fetch("/api/getOnDemandContent")
      .then((res) => res.json())
      .then((data) => this.setState({ onDemandContent: data }));
  }

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  showContentModal = (contentItem) => {
    requestPoints("viewOnDemandItem", contentItem._id);
    this.setState({ selectedOnDemand: contentItem, showOnDemandModal: true });
  }

  onDemandFilteredByDay = (selectedDay) => {
    return this.state.onDemandContent.filter(
      (contentItem) => contentItem.day === selectedDay
    );
  };

  contentGallery = (selectedDay) => {
    return this.onDemandFilteredByDay(selectedDay).map((contentItem) => {
      return (
        <div
          key={contentItem._id}
          className={styles.contentContainer}
          onClick={() => this.showContentModal(contentItem)}
        >
          <div className={styles.contentContainerUpper}>
            {contentItem.contentTitle}
          </div>
          <div className={styles.contentContainerLower}>
            <div className={styles.contentVideoArea}>
              {contentItem.videoID ? (
                <div className={styles.onDemandVideoDiv}>
                  <VideoPlayer
                    resourceLocator={contentItem.videoID}
                    location={"onDemand"}
                    userProfile={this.props.userProfile}
                  />
                </div>
              ) : null}
            </div>
            <div className={styles.contentTextDiv}>
              <p className={styles.speakerName}><span className={styles.speakerTitle}>Presenter:<br /></span>
                {contentItem.speakerName}</p>

            </div>
          </div>
          <div className={styles.buttonPositionDiv}>
            <button className={styles.highlightedButton} style={{ width: '120px', height: '40px' }}>More</button>
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <div>
        {this.props.showMobile ?
          <OnDemandMobile
            onDemandContent={this.state.onDemandContent}
            userProfile={this.props.userProfile}
          />
          :
          this.state.visualGallery ?
            <div className="imageNavDiv">
              <video
                className="imageNavBackground"
                autoPlay
                muted
                id="introVideo"
                onEnded={() => this.setState({ introVideoComplete: true })}
              >
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/onDemand.webm"}
                  type="video/webm"
                />
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/onDemand.mp4"}
                  type="video/mp4"
                />
              </video>

              <CSSTransition
                in={this.state.introVideoComplete}
                timeout={1500}
                classNames="imageNavBackground"
                unmountOnExit
              >
                <div>
                  <img
                    className="imageNavBackground"
                    src={window.$videoPath + "visuals/" + this.props.language + "/onDemand.jpg"}
                    alt="Background"
                  ></img>

                  <Link to="/lobby4">
                    <img
                      className={styles.exitHoverHotspot}
                      src="/hoverShapes/hoverSquare.png"
                      alt="Exit"
                    ></img>
                  </Link>

                  <div className={styles.visualTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
                    <h1 className={styles.visualPageTitle}>On-Demand</h1>
                  </div>

                  <section className={styles.onDemandItemsArea}>
                    {this.contentGallery(1)}
                  </section>
                </div>
              </CSSTransition>
            </div>
            :
            <>
              <div className={styles.galleryBackgroundDiv}>
                {/**<img
            className="imageNavBackground"
            src={window.$videoPath + "background.png"}
            alt="Background"
          ></img>*/}
              </div>

              <div className={styles.tabBar}>
                <Link to={"/lobby"} className={styles.backLink}>
                  <div className={styles.tabBackDiv}>
                    <img
                      className={styles.backArrow}
                      src={window.$videoPath + "icons/backArrow.png"}
                      alt="Back"
                    />
                    Back to Lobby
                  </div>
                </Link>
              </div>


              <div className={styles.titleAndSearchBar}>
                <h1 className={styles.pageTitle}>
                  <strong>ON-DEMAND</strong>
                </h1>

                <div className={styles.searchTitleArea}>
                  <p></p>
                </div>
              </div>
              <div className={styles.contentContainerDiv}>
                {/**this.onDemandGallery(1).length > 0 ? <h1 className={styles.dayTitle}>Day 1</h1> : null*/}
                <section className={styles.contentSegmentsArea}>{this.contentGallery(1)}</section>
                {/**this.onDemandGallery(1)*/}
              </div>
            </>
        }

        <OnDemandModal
          showOnDemandModal={this.state.showOnDemandModal}
          closeOnDemandModal={() => this.setState({ showOnDemandModal: false })}
          selectedOnDemand={this.state.selectedOnDemand}
          userProfile={this.props.userProfile}
        />
      </div>
    );
  }
}
