export const determineWhetherUserIsHybrid = (userProfile) => {
  if (userProfile && userProfile.country) {
    if (userProfile.country == "Bangalore" || 
    //userProfile.country == "Noida" || 
    //userProfile.country == "Hsinchu" || 
    //userProfile.country == "Taipei" || 
    userProfile.country == "Austin" || 
    //userProfile.country == "Boston" || 
    //userProfile.country == "Chandler" || 
    userProfile.country == "San Jose" || 
    userProfile.country == "Cambridge" || 
    userProfile.country == "Manchester" || 
    //userProfile.country == "Sheffield" || 
    //userProfile.country == "Lund" || 
    userProfile.country == "Sophia") 
    //userProfile.country == "Oslo" || 
    //userProfile.country == "Trondheim" || 
    //userProfile.country == "Budapest") 
    {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}
