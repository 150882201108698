import React, { Component } from "react";
//import TimezoneSelect from 'react-timezone-select'
import styles from "./Register.module.scss";
import preLoginStyles from "./Pre-Login.module.scss";
import userProfileAndRegistration from "./../userAccount/UserProfileAndRegistration.module.scss";

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      surname: "",
      jobTitle: "",
      country: "",
      company: "",
      areasOfInterest: "",
      email: "",
      password: "",
      confirmPassword: "",
      userProfilePictureID: "placeholder_nhryv1",
      fileInputDisabled: false,
      userLanguage: "en",
      userTimezone: { value: "Europe/London" },
      userAgeBracket: 2,
      regCheckFailed: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // Upload load, check for language cookie and pre-populate reg form to this
    const { cookies } = this.props;
    const languageSetCookie = cookies.get("languageSet");
    if (languageSetCookie !== undefined) {
      this.setState({ userLanguage: languageSetCookie })
    }
  }

  handleProfilePictureUpload = (event) => {
    this.setState({ fileInputDisabled: true });
    const data = new FormData();
    data.append("file", event.target.files[0]);

    fetch("/api/uploadImage", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          userProfilePictureID: data.publicID,
        });
        console.log("Returned upload ID: " + this.state.userProfilePictureID);
        this.setState({ fileInputDisabled: false });
        alert("Profile picture successfully uploaded");
      });
  };

  checkUserPreReg = (email) => {
    console.log("Pre reg check");
    fetch("/api/preRegStatus?email=" + encodeURIComponent(email), {
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.response.includes("successfullyMatched")) {
          console.log("matched");
          this.submitShortRegistration();
        } else {
          // Trigger full form request
          this.setState({ regCheckFailed: true });
        }

      });
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  validateEmail = (email) => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  };

  submitShortRegistration = () => {
    if (this.state.email.length < 5) {
      alert("Please enter your email address");
    } else if (!this.validateEmail(this.state.email)) {
      alert("Please enter a valid email address");
    } else if (this.state.password.length < 6) {
      alert("Please use a password of at least 6 characters");
    } else if (this.state.password !== this.state.confirmPassword) {
      alert("Please ensure your passwords match");
    } else {
      fetch("/api/register", {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            alert(
              "Thank you for setting up your Account. We have sent you a verification email. Please click on the link in the email to verify your account."
            );
            this.props.handleCloseRegisterDiv();
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
          alert(
            "An account already exists for this email address. Please reset your password."
          );
        });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    // If pre reg check not failed then do pre-reg check
    // Assume not failed (so only email and password visible)
    if (this.state.regCheckFailed === false) {
      this.checkUserPreReg(this.state.email);
      // No further action until triggered by callack from email check response
    } else {

      if (this.state.firstName.length < 1) {
        alert("Please enter your first name");
      } else if (this.state.surname.length < 1) {
        alert("Please enter your surname");
      } else if (this.state.jobTitle.length < 1) {
        alert("Please enter your job title");
        /** } else if (this.state.country.length < 1) {
          alert("Please enter your country");
        } else if (this.state.company.length < 1) {
          alert("Please enter your company/affiliation");*/
      } else if (this.state.email.length < 5) {
        alert("Please enter your email address");
      } else if (!this.validateEmail(this.state.email)) {
        alert("Please enter a valid email address");
      } else if (this.state.password.length < 6) {
        alert("Please use a password of at least 6 characters");
      } else if (this.state.password !== this.state.confirmPassword) {
        alert("Please ensure your passwords match");
      } else {
        fetch("/api/register", {
          method: "POST",
          body: JSON.stringify(this.state),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.status === 200) {
              alert(
                "Thank you for setting up your Beehive Open House Account. We have sent you a verification email. Please click on the link in the email to verify your account."
              );
              this.props.handleCloseRegisterDiv();
            } else {
              const error = new Error(res.error);
              throw error;
            }
          })
          .catch((err) => {
            console.error(err);
            alert(
              "An account already exists for this email address. Please reset your password."
            );
          });
      }
    }
  };

  render() {
    return (
      <div className={styles.registerModal}>
        <div className={styles.registerModalInnerDiv}>

        <img
                className={preLoginStyles.helpTab}
                src={window.$videoPath + "login/helpTab.png"}
                onClick={this.props.showHelp}
                alt="Help"
              />

          <h2 className={styles.titleText}>REGISTRATION</h2>
          <br />

          <div className={userProfileAndRegistration.profileGridContainer}>
            {this.state.regCheckFailed ?
              <>
                <div className={userProfileAndRegistration.profileGridElement}>

                  <label htmlFor="firstName" className={userProfileAndRegistration.formLabel}>
                    First Name: *
                  </label>
                  <input
                    className={`${userProfileAndRegistration.formField} ${preLoginStyles.textEntry}`}
                    type="text"
                    name="firstName"
                    placeholder="First name"
                    tabIndex="10"
                    value={this.state.firstName}
                    onChange={this.handleChange}
                  />
                </div>

                <div className={userProfileAndRegistration.profileGridElement}>
                  <label htmlFor="surname" className={userProfileAndRegistration.formLabel}>
                    Surname: *
                  </label>
                  <input
                    className={`${userProfileAndRegistration.formField} ${preLoginStyles.textEntry}`}
                    type="text"
                    name="surname"
                    placeholder="Surname"
                    tabIndex="20"
                    value={this.state.surname}
                    onChange={this.handleChange}
                  />
                </div>

                <div className={userProfileAndRegistration.profileGridElement}>
                  <label htmlFor="jobTitle" className={userProfileAndRegistration.formLabel}>
                    Job title: *
                  </label>
                  <input
                    className={`${userProfileAndRegistration.formField} ${preLoginStyles.textEntry}`}
                    type="text"
                    name="jobTitle"
                    placeholder="Job title"
                    tabIndex="30"
                    value={this.state.jobTitle}
                    onChange={this.handleChange}
                  />
                </div>

                <div className={userProfileAndRegistration.profileGridElement}>
                  <label htmlFor="company" className={userProfileAndRegistration.formLabel}>
                    Company/Affiliation: *
                  </label>
                  <input
                    className={`${userProfileAndRegistration.formField} ${preLoginStyles.textEntry}`}
                    type="text"
                    name="company"
                    placeholder="Company/Affiliation"
                    tabIndex="40"
                    value={this.state.company}
                    onChange={this.handleChange}
                  />
                </div>



                {/**<div className={userProfileAndRegistration.profileGridElement}>
                    <label htmlFor="userLanguage" className={userProfileAndRegistration.formLabel}>
                      Preferred Language:
                    </label>
                    <select
                      className={`${userProfileAndRegistration.formField} ${userProfileAndRegistration.dropDownEntry}`}
                      id="userLanguage"
                      name="userLanguage"
                      placeholder="Preferred Language"
                      tabIndex="50"
                      value={this.state.userLanguage}
                      onChange={this.handleChange}
                    >
                      <option value="en">English</option>
                      <option value="es">Spanish</option>
                      <option value="fr">French</option>
                      <option value="zh">Mandarin</option>
                    </select>
              </div>*/}

                {/**<div className={userProfileAndRegistration.profileGridElement}>
                    <label htmlFor="userAgeBracket" className={userProfileAndRegistration.formLabel}>
                      Age:
                    </label>
                    <select
                      className={`${userProfileAndRegistration.formField} ${userProfileAndRegistration.dropDownEntry}`}
                      id="userAgeBracket"
                      name="userAgeBracket"
                      placeholder="Age"
                      tabIndex="60"
                      value={this.state.userAgeBracket}
                      onChange={this.handleChange}
                    >
                      <option value="1">16/17</option>
                      <option value="2">18+</option>
                    </select>
                  </div>
*/}

              </> : null}



            {/**<label htmlFor="country" className={userProfileAndRegistration.formLabel}>
                Country: *
              </label>
              <input
                className={`${userProfileAndRegistration.formField} ${preLoginStyles.textEntry}`}
                type="text"
                name="country"
                placeholder="Country"
                tabIndex="70"
                value={this.state.country}
                onChange={this.handleChange}
              />

              <br />*/}
            <div className={userProfileAndRegistration.profileGridElement}>
              <label htmlFor="email" className={userProfileAndRegistration.formLabel}>
                Email: *
              </label>
              <input
                className={`${userProfileAndRegistration.formField} ${preLoginStyles.textEntry}`}
                type="email"
                name="email"
                placeholder="Email"
                tabIndex="80"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </div>

            <div className={userProfileAndRegistration.profileGridElement}>
              <label htmlFor="password" className={userProfileAndRegistration.formLabel}>
                Password: *
              </label>
              <input
                type="password"
                name="password"
                className={`${userProfileAndRegistration.formField} ${preLoginStyles.textEntry}`}
                size="50"
                placeholder="Enter password"
                tabIndex="90"
                value={this.state.password}
                onChange={this.handleChange}
              />
            </div>

            <div className={userProfileAndRegistration.profileGridElement}>
              <label htmlFor="confirmPassword" className={userProfileAndRegistration.formLabel}>
                Confirm Password: *
              </label>
              <input
                type="password"
                name="confirmPassword"
                className={`${userProfileAndRegistration.formField} ${preLoginStyles.textEntry}`}
                size="50"
                placeholder="Confirm password"
                tabIndex="100"
                value={this.state.confirmPassword}
                onChange={this.handleChange}
              />
            </div>

            {/**<div className={userProfileAndRegistration.profileGridElement}>
                <label htmlFor="userTimezone" className={userProfileAndRegistration.formLabel}>
                  Timezone:
                </label>
                <TimezoneSelect
                  id="userTimezone"
                  tabIndex="110"
                  value={this.state.userTimezone}
                  onChange={(data) => this.setState({ userTimezone: data })} />
              </div>*/}

            <div className={userProfileAndRegistration.profileGridElement}>
              <label className={userProfileAndRegistration.formLabel} htmlFor="profilePicture">
                Profile Picture Upload:
              </label>
              <br />
              <label className={userProfileAndRegistration.customFileUpload}>
                <div className={styles.highlightedButton} style={{textAlign: 'center'}}>Upload</div>
                <input
                  className={userProfileAndRegistration.fileInput}
                  name="profilePicture"
                  type="file"
                  tabIndex="120"
                  disabled={this.state.fileInputDisabled}
                  accept="image/*"
                  onChange={this.handleProfilePictureUpload}
                />
              </label>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />


          <div className={preLoginStyles.loginButtonsRow}>
            <button
              className={preLoginStyles.button}
              onClick={() => this.props.handleCloseRegisterDiv()}
              type="button"
            >
              Back
            </button>
            <div style={{ flex: 1 }} />
            <button
              className={preLoginStyles.highlightedButton}
              onClick={this.handleSubmit}
              disabled={this.state.fileInputDisabled}
            >
              Submit
            </button>
          </div>

        </div>
      </div>
    );
  }
}
