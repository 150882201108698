import React, { Component } from "react";
import Modal from "react-modal";
import styles from "./ModalStyles.module.scss";

export default class NetworkingModal extends Component {


  render() {
    return (
      <div>
        <Modal
          ariaHideApp={false}
          isOpen={true}
          onRequestClose={() => this.props.hideNetworking()}
          contentLabel="Networking Modal"
          overlayClassName={styles.modalOverlay}
          className={styles.modalContentShort}
          style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
        >
          <div className={styles.modalFlexboxColumn}>
            <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/holdingModalHeader.png)', }}>
              <h1 className={styles.modalPageTitle}>Networking opportunities</h1>
              <button
                className={styles.modalClose}
                variant="success"
                onClick={() => this.props.hideNetworking()}
                aria-label="Close"
              >
                Close
                <img
                  className={styles.modalCloseIcon}
                  src="/icons/close-no-circle.png"
                  alt="Close"
                />
              </button>
            </div>

            <div className={styles.scrollableArea}>
              <div className={styles.modalContentArea}>

                <p>Networking helps drive success at Arm and throughout this event you will get the chance to engage with other attendees from across the globe and build those important networks. All networking activities are a vital part of the conference, and we hope you enjoy the chance to connect with others.</p><br />

                <p><ol><li>You will have the opportunity to connect directly with fellow attendees during our <strong>Networking Lunch</strong> session. This session is scheduled in the middle of the event agenda (lasting approximately 90 minutes), giving you a chance to break away from the screen, grab a refreshment and meet your fellow attendees. <strong><span style={{color: "#95d600"}}>We therefore ask that you work from the office on the day of the event, where possible, to make the most out of this experience.</span></strong> Don't worry if you can't – there will be a virtual lunch option too. You can find further logistical details tailored to you about this Networking Lunch by clicking on the <strong><i>Hybrid</i></strong> button.</li>

                <br />

                <li>You will also be given time at the end of the day to enjoy a coffee break during the networking session <strong>Connect with Colleagues</strong>. It's a golden opportunity for you to connect with your colleagues and jumpstart your networking efforts. Once the conference is live, you can check out the attendee list on the platform, and if you're in an office, take advantage and get together in person! For those joining from home, why not reach out to someone via zoom or teams. We really urge you to make the most of this time and connect with colleagues who've been part of your conference experience. Together you can reflect on the content that has been shared and get to work on expanding your network.</li></ol></p>

              </div>
            </div>
          </div>

        </Modal>
      </div>
    );
  }
}
