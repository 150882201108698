import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { withCookies } from "react-cookie";
import i18n from "../i18n"
import TopMenu from "./topMenu/TopMenu";
import Routes from "./Routes";
import RemoteControlListener from "./remoteControl/RemoteControlListener";
import Notification from "./notification/Notification";

import "./App.scss";

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      admin: false,
      adminStatusChecked: false,
      userProfileAccessAttempted: false,
      userProfile: null,
      plenaryContent: "",
      plenaryPosition: 0,
      plenaryOpenStatus: "open",
      breakout1Live: true,
      breakout2Live: true,
      breakout3Live: true,
      breakout4Live: true,
      breakout5Live: true,
      breakout6Live: true,
      breakout7Live: true,
      breakout8Live: true,
      breakout9Live: true,
      breakout10Live: true,
      lobbyScreen: "",
      zoomMeetingID: "",
      zoomPassword: "",
      webinarURL: "",
      notificationText: "",
      circlesLive: "closed",
      cornerMenuStatus: 1,
      topMenuStatus: 0,
      showProfileModal: false,
      showSessionSelectionModal: false,
      showSessionBookingModal: false,
      showHelpModal: false,
      showAgendaModal: false,
      showLeaderboardModal: false,
      language: "en",
      showMobile: false,
    };
  }

  componentDidMount () {
    this.checkLoginStatus();

    // Listen for changes to screen size
    window.addEventListener("resize", this.updateScreenSize);
    // Check screen size on first load too
    this.updateScreenSize();
  }

  componentDidUpdate (prevProps, prevState) {
    var previousLanguage = "unspecified";
    if (prevState.userProfile && prevState.userProfile.userLanguage) {
      previousLanguage = prevState.userProfile.userLanguage
    }
    var currentLanguage = "unspecified";
    if (this.state.userProfile && this.state.userProfile.userLanguage) {
      currentLanguage = this.state.userProfile.userLanguage
    }

    // Only fire this if language has actually changed
    if (this.state.userProfile != null && previousLanguage !== currentLanguage) {
      //alert("Language changed from " + previousLanguage + " to " + currentLanguage);
      // User profile has changed, update
      this.setLanguage(this.state.userProfile.userLanguage)
    }

  }

  updateScreenSize = () => {
    if (window.innerWidth < 700) {
      this.setState({ showMobile: true });
    } else {
      this.setState({ showMobile: false });
    }
  }

  checkLoginStatus = () => {
    this.checkOrdinaryLoginStatus();
    this.checkAdminStatus();
  };

  checkOrdinaryLoginStatus = () => {
    console.log("Checking token status");
    fetch("/api/checkToken")
      .then((res) => {
        console.log("Received response: " + res.status);
        if (res.status === 200) {
          console.log("Show hidden menu");
          this.setState({ topMenuStatus: 1 });
          this.retrieveRemoteControlSettingsFromMongo();
          this.getUserProfile();
        } else if (res.status === 401) {
          console.log("No access");
          this.setState({ topMenuStatus: 0, userProfile: null, userProfileAccessAttempted: true });
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        this.setState({ topMenuStatus: 0, userProfile: null });
      });
  };

  checkAdminStatus = () => {
    fetch("/api/checkAdminToken")
      .then((res) => {
        if (res.status === 200) {
          console.log("Status here: " + res.status);
          this.setState({ admin: true, adminStatusChecked: true });
        } else if (res.status === 401) {
          console.log("No admin access");
          this.setState({ adminStatusChecked: true });
        } else {
          const error = new Error(res.error);
          this.setState({ adminStatusChecked: true });
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ adminStatusChecked: true });
      });
  };

  getUserProfile = () => {
    fetch("/api/getUserProfile")
      .then((res) => res.json())
      .then((data) => this.setState({ userProfile: data.user, userProfileAccessAttempted: true }));
  };

  retrieveRemoteControlSettingsFromMongo () {
    fetch("/api/getRemoteControlSettings")
      .then((res) => res.json())
      .then((data) => this.loadRemoteControlResponseIntoState(data));
  }

  loadRemoteControlResponseIntoState (data) {
    console.log("Update: " + data.remoteControlSettings.navigation);
    this.setState({
      //navigation: data.remoteControlSettings.navigation,
      //popUp: data.remoteControlSettings.popUp,
      //navBarStatus: data.remoteControlSettings.navBarStatus,
      //plenaryOpenStatus: data.remoteControlSettings.plenaryOpenStatus,
      plenaryContent: data.remoteControlSettings.plenaryContent,
      //plenaryPosition: data.remoteControlSettings.plenaryPosition,
      //notificationText: data.remoteControlSettings.notificationText,
      breakout1Live: data.remoteControlSettings.breakout1Live,
      breakout2Live: data.remoteControlSettings.breakout2Live,
      breakout3Live: data.remoteControlSettings.breakout3Live,
      breakout4Live: data.remoteControlSettings.breakout4Live,
      breakout5Live: data.remoteControlSettings.breakout5Live,
      breakout6Live: data.remoteControlSettings.breakout6Live,
      breakout7Live: data.remoteControlSettings.breakout7Live,
      breakout8Live: data.remoteControlSettings.breakout8Live,
      breakout9Live: data.remoteControlSettings.breakout9Live,
      breakout10Live: data.remoteControlSettings.breakout10Live,
      zoomMeetingID: data.remoteControlSettings.zoomMeetingID,
      zoomPassword: data.remoteControlSettings.zoomPassword,
      webinarURL: data.remoteControlSettings.webinarURL,
    });
  }

  setPlenaryContent = (content) => {
    this.setState({ plenaryContent: content });
  };

  setPlenaryPosition = (position) => {
    this.setState({ plenaryPosition: position });
  };

  setPlenaryOpenStatus = (openStatus) => {
    this.setState({ plenaryOpenStatus: openStatus });
  };

  setNotificationText = (notificationText) => {
    this.setState({ notificationText: notificationText });
  };

  setCirclesLive = (circlesLive) => {
    this.setState({ circlesLive: circlesLive });
  };

  setCornerMenuStatus = (cornerMenuStatus) => {
    this.setState({ cornerMenuStatus: cornerMenuStatus });
  };

  setTopMenuStatus = (topMenuStatus) => {
    this.setState({ topMenuStatus: topMenuStatus });
  };

  // Modal visibility control
  hideAllModals = () => {
    this.setState({
      showProfileModal: false,
      showSessionSelectionModal: false,
      showSessionBookingModal: false,
      showAgendaModal: false,
      showHelpModal: false,
      showLeaderboardModal: false,
    })
  }

  setShowProfileModal = (showProfileModal) => {
    this.hideAllModals();
    this.setState({ showProfileModal: showProfileModal });
  };

  setShowSessionSelectionModal = (showSessionSelectionModal) => {
    this.hideAllModals();
    this.setState({ showSessionSelectionModal: showSessionSelectionModal });
  };

  setShowSessionBookingModal = (showSessionBookingModal) => {
    this.hideAllModals();
    this.setState({ showSessionBookingModal: showSessionBookingModal });
  };

  setShowAgendaModal = (showAgendaModal) => {
    this.hideAllModals();
    this.setState({ showAgendaModal: showAgendaModal });
  }

  setShowHelpModal = (showHelpModal) => {
    this.hideAllModals();
    this.setState({ showHelpModal: showHelpModal });
  };

  setShowLeaderboardModal = (showLeaderboardModal) => {
    this.hideAllModals();
    this.setState({ showLeaderboardModal: showLeaderboardModal });
  }
  // End of modal visibility control

  setBreakout1Live = (breakout1Live) => {
    this.setState({ breakout1Live: breakout1Live });
  };

  setBreakout2Live = (breakout2Live) => {
    this.setState({ breakout2Live: breakout2Live });
  };

  setBreakout3Live = (breakout3Live) => {
    this.setState({ breakout3Live: breakout3Live });
  };

  setBreakout4Live = (breakout4Live) => {
    this.setState({ breakout4Live: breakout4Live });
  };

  setBreakout5Live = (breakout5Live) => {
    this.setState({ breakout5Live: breakout5Live });
  };

  setBreakout6Live = (breakout6Live) => {
    this.setState({ breakout6Live: breakout6Live });
  };

  setBreakout7Live = (breakout7Live) => {
    this.setState({ breakout7Live: breakout7Live });
  };

  setBreakout8Live = (breakout8Live) => {
    this.setState({ breakout8Live: breakout8Live });
  };

  setBreakout9Live = (breakout9Live) => {
    this.setState({ breakout9Live: breakout9Live });
  };

  setBreakout10Live = (breakout10Live) => {
    this.setState({ breakout10Live: breakout10Live });
  };

  setLobbyScreen = (lobbyScreen) => {
    this.setState({ lobbyScreen: lobbyScreen });
  };

  setLanguage = (language) => {
    //alert("Language set as " + language);
    this.setState({ language: language });
    i18n.changeLanguage(language);
    const { cookies } = this.props;
    cookies.set("languageSet", language, { path: "/" });

  };

  render () {
    return (
      <div className="App">
        <Router>
          {/** Do not implement the RemoteControlListener if admin */}
          {this.state.userProfile != null ? (
            !this.state.adminStatusChecked || this.state.admin ? null : (
              <RemoteControlListener
                setTopMenuStatus={this.setTopMenuStatus}
                setPlenaryContent={this.setPlenaryContent}
                setPlenaryPosition={this.setPlenaryPosition}
                setPlenaryOpenStatus={this.setPlenaryOpenStatus}
                setNotificationText={this.setNotificationText}
                setCirclesLive={this.setCirclesLive}
                setBreakout1Live={this.setBreakout1Live}
                setBreakout2Live={this.setBreakout2Live}
                setBreakout3Live={this.setBreakout3Live}
                setBreakout4Live={this.setBreakout4Live}
                setBreakout5Live={this.setBreakout5Live}
                setBreakout6Live={this.setBreakout6Live}
                setBreakout7Live={this.setBreakout7Live}
                setBreakout8Live={this.setBreakout8Live}
                setBreakout9Live={this.setBreakout9Live}
                setBreakout10Live={this.setBreakout10Live}
                setLobbyScreen={this.setLobbyScreen}
              />
            )
          ) : null}
          <TopMenu
            adminStatus={this.state.admin}
            userProfile={this.state.userProfile}
            reloadUserProfile={this.getUserProfile}
            cornerMenuStatus={this.state.cornerMenuStatus}
            topMenuStatus={this.state.topMenuStatus}
            showProfileModal={this.state.showProfileModal}
            setShowProfileModal={this.setShowProfileModal}
            showSessionSelectionModal={this.state.showSessionSelectionModal}
            setShowSessionSelectionModal={this.setShowSessionSelectionModal}
            showSessionBookingModal={this.state.showSessionBookingModal}
            setShowSessionBookingModal={this.setShowSessionBookingModal}
            showAgendaModal={this.state.showAgendaModal}
            setShowAgendaModal={this.setShowAgendaModal}
            showHelpModal={this.state.showHelpModal}
            setShowHelpModal={this.setShowHelpModal}
            showLeaderboardModal={this.state.showLeaderboardModal}
            setShowLeaderboardModal={this.setShowLeaderboardModal}
            language={this.state.language}
            setLanguage={this.setLanguage}
            showMobile={this.state.showMobile}
            cookies={this.props.cookies}
          />
          {this.state.userProfile != null &&
            this.state.notificationText.length > 0 ? (
            <Notification
              notificationText={this.state.notificationText}
              setNotificationText={this.setNotificationText}
              showMobile={this.state.showMobile}
            />
          ) : null}

          {/** Don't render the route until we have the user profile for language etc. */}
          {this.state.userProfile || this.state.userProfileAccessAttempted ?
            <Routes
              checkLoginStatus={this.checkLoginStatus}
              cookies={this.props.cookies}
              userProfile={this.state.userProfile}
              reloadUserProfile={this.getUserProfile}
              plenaryContent={this.state.plenaryContent}
              plenaryPosition={this.state.plenaryPosition}
              plenaryOpenStatus={this.state.plenaryOpenStatus}
              cornerMenuStatus={this.state.cornerMenuStatus}
              setCornerMenuStatus={this.setCornerMenuStatus}
              setTopMenuStatus={this.setTopMenuStatus}
              setShowAgendaModal={this.setShowAgendaModal}
              setShowLeaderboardModal={this.setShowLeaderboardModal}
              setShowProfileModal={this.setShowProfileModal}
              setShowHelpModal={this.setShowHelpModal}
              breakout1Live={this.state.breakout1Live}
              breakout2Live={this.state.breakout2Live}
              breakout3Live={this.state.breakout3Live}
              breakout4Live={this.state.breakout4Live}
              breakout5Live={this.state.breakout5Live}
              breakout6Live={this.state.breakout6Live}
              breakout7Live={this.state.breakout7Live}
              breakout8Live={this.state.breakout8Live}
              breakout9Live={this.state.breakout9Live}
              breakout10Live={this.state.breakout10Live}
              lobbyScreen={this.state.lobbyScreen}
              zoomMeetingID={this.state.zoomMeetingID}
              zoomPassword={this.state.zoomPassword}
              webinarURL={this.state.webinarURL}
              language={this.state.language}
              setLanguage={this.setLanguage}
              showMobile={this.state.showMobile}
            /> : null}
        </Router>
      </div>
    );
  }
}

export default withCookies(App);
