import React, { Component } from "react";
import styles from "./NotificationMobile.module.scss";

export default class NotificationMobille extends Component {

  render () {
    return (
      <div className={styles.mobileNotification}>
        <div className={styles.closeArea}>
          <img className={styles.notificationClose} src="/icons/close.png" alt="Close" onClick={() => this.props.setNotificationText("")} />
        </div>

        <div className={styles.messageArea}>
          {this.props.notificationText}
        </div>

      </div>

    );
  }
}
