import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import LobbyMobile from "./LobbyMobile";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./Lobby.module.scss";

export default class Lobby extends Component {
  constructor(props) {
    super(props);
    this.state = {
      introVideoComplete: true,
    };
  }

  componentDidMount () {
    requestPoints("visitLobby", "visitLobby");
    const { cookies } = this.props;
    const lobbyVisitedCookie = cookies.get("lobbyVisited");
    if (lobbyVisitedCookie === undefined) {
      this.props.setShowHelpModal(true);
      //cookies.set("lobbyVisited", "yes", { path: "/" });
    }

    // Start timeout in case video does not load
    setTimeout(
      function () {
        this.setState({ introVideoComplete: true });
      }.bind(this),
      6000
    );
  }

  componentDidUpdate (prevProps, prevState) {
    // Double check corner menu status is correct
    if (this.props.cornerMenuStatus !== 1) {
      this.props.setCornerMenuStatus(1);
    }
  }


  render () {
    return (
      <div>
        {this.props.showMobile ?
          <LobbyMobile
            setShowAgendaModal={this.props.setShowAgendaModal}
            setShowHelpModal={this.props.setShowHelpModal}
            setShowProfileModal={this.props.setShowProfileModal}
            setShowLeaderboardModal={this.props.setShowLeaderboardModal}
          />
          :
          <div className={styles.masterPageDiv}>

            <img
              className="imageNavBackground"
              src={window.$videoPath + "visuals/" + this.props.language + "/lobby.png"}
              alt="Background"
            ></img>

            <Link to="/plenary">
              <img
                className={`${styles.visibleHoverHotspot} ${styles.plenaryHoverHotspot}`}
                src={window.$videoPath + "visualAssets/en/hoverAreas/lobby-talks.png"}
                alt="To plenary"
              ></img>
            </Link>


            <Link to="/networking">
              <img
                className={`${styles.visibleHoverHotspot} ${styles.networkingHoverHotspot}`}
                src={window.$videoPath + "visualAssets/en/hoverAreas/lobby-deck.png"}
                alt="To networking"
              ></img>
            </Link>

            <Link to="/onDemand">
              <img
                className={`${styles.visibleHoverHotspot} ${styles.ondemandHoverHotspot}`}
                src={window.$videoPath + "visualAssets/en/hoverAreas/lobby-ondemand.png"}
                alt="To On-demand"
              ></img>
            </Link>

          </div>
        }
      </div>
    );
  }
}
