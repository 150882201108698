import React, { Component } from "react";
import dompurify from "dompurify";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import { Image, Transformation } from "cloudinary-react";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./JobSearch.module.scss";

export default class JobSearch extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      galleryContent: [],
      searchString: "",

      // Show job detail
      showJobModal: false,
      selectedJob: null,

      // Search modal control
      showAdvancedSearchDiv: false,

      // Search parameters
      workingHours: [],
      contractType: [],
      salary: [],
      sector: [],
    };
  }

  componentDidMount () {
        // Hide corner menu
        this.props.setCornerMenuStatus(3);

    this.loadCurrentDatabaseContent();
    // On load, set default search settings
    this.resetAdvancedSearch();
  }

  componentWillUnmount() {
    // Set corner menu to displayed again
    this.props.setCornerMenuStatus(1);
  }

  componentDidUpdate() {
    // Double check corner menu status is correct
    if (this.props.cornerMenuStatus !== 3) {
      console.log(this.props.cornerMenuStatus);
      this.props.setCornerMenuStatus(3);
    }
  }

  loadCurrentDatabaseContent () {
    fetch("/api/getJobSearch")
      .then((res) => res.json())
      .then((data) => this.setState({ galleryContent: data }));
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleWorkingHoursChange = (value) => {
    this.setState({
      workingHours: value,
    });
  };

  handleContractTypeChange = (value) => {
    this.setState({
      contractType: value,
    });
  };

  handleSalaryChange = (value) => {
    this.setState({
      salary: value,
    });
  };

  handleSectorChange = (value) => {
    this.setState({
      sector: value,
    });
  };

  resetAdvancedSearch = () => {
    this.setState({ workingHours: ["Full Time", "Part Time"], contractType: ["Permanent", "Temporary", "Contract"], salary: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], sector: ["Professional Services", "Retail", "Hospitality", "Finance", "Education", "Healthcare", "Technology", "Arts"] });
  }

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  handleCloseJobModal = () => {
    this.setState({ showJobModal: false });
  };

  viewJob = (job) => {
    this.setState({
      selectedJob: job,
      showJobModal: true,
    });
    requestPoints("viewJob", job._id);
  }


  render () {
    const jobSearchGalleryFiltered = this.state.galleryContent.filter(
      (jobSearch) =>
        (jobSearch.company
          .toLowerCase()
          .includes(this.state.searchString.toLowerCase()) ||
          jobSearch.jobTitle
            .toLowerCase()
            .includes(this.state.searchString.toLowerCase())) &&
        this.state.salary.includes(jobSearch.salaryBracket) &&
        this.state.sector.includes(jobSearch.sector)
      //this.state.contractType.includes(jobSearch.contractType)
    );

    const jobSearchGallery = jobSearchGalleryFiltered.map((jobSearch) => {
      return (

        <div
          onClick={() => this.viewJob(jobSearch)}
          key={jobSearch._id}
          className={styles.jobContainer}
        >
          <Image
            className={styles.companyLogo}
            cloudName="oijqec973f1nf"
            publicId={jobSearch.companyLogoResourceID}
            secure={true}
          >
            <Transformation
              height="100"
              width="300"
              crop="pad"
            />
          </Image>

          <div>
            <div className={styles.jobNameContainer}>
              <p className={styles.standNameText}>
                <strong>{jobSearch.company}</strong>
              </p>
              <p className={styles.jobSummaryText}>
                {jobSearch.jobTitle}
              </p>
            </div>
            <div className={styles.visitStandButton}>Visit</div>
          </div>


        </div>
      );
    });

    return (
      <div>

        {this.state.showAdvancedSearchDiv ? (
          <div className={styles.advancedSearchBox}>
            <div className={styles.advancedSearchTitleBar}>
              <h2>Advanced Job Search<button
                className={styles.advancedSearchModalClose}
                variant="success"
                onClick={() => this.setState({ showAdvancedSearchDiv: false })}
              >
                <img
                  className={styles.closeIcon}
                  src="/icons/close-no-circle.png"
                  alt="Close"
                />
              </button></h2>

            </div>
            <div className={styles.searchArea}>
              <h4>Full Time</h4>
              <ToggleButtonGroup
                type="checkbox"
                value={this.state.workingHours}
                name="workingHours"
                onChange={this.handleWorkingHoursChange}
                size="sm"
              >
                <ToggleButton value={"Full Time"}>Full Time</ToggleButton>
                <ToggleButton value={"Part Time"}>Part Time</ToggleButton>
              </ToggleButtonGroup>


              <br /><br />
              <h4>Contract Type</h4>
              <ToggleButtonGroup
                type="checkbox"
                value={this.state.contractType}
                name="contractType"
                onChange={this.handleContractTypeChange}
                size="sm"
              >
                <ToggleButton value={"Permanent"}>Permanent</ToggleButton>
                <ToggleButton value={"Temporary"}>Temporary</ToggleButton>
                <ToggleButton value={"Contract"}>Contract</ToggleButton>
              </ToggleButtonGroup>

              <br /><br />
              <h4>Salary</h4>
              <ToggleButtonGroup
                type="checkbox"
                value={this.state.salary}
                name="salary"
                onChange={this.handleSalaryChange}
                size="sm"
                className={styles.multiRowButtonGroup}
              >
                <ToggleButton value={0}>Up to £10,000</ToggleButton>
                <ToggleButton value={1}>£10,000-£20,000</ToggleButton>
                <ToggleButton value={2}>£20,000-£30,000</ToggleButton>
                <ToggleButton value={3}>£30,000-£40,000</ToggleButton>
                <ToggleButton value={4}>£40,000-£50,000</ToggleButton>
                <ToggleButton value={5}>£50,000-£60,000</ToggleButton>
                <ToggleButton value={6}>£60,000-£70,000</ToggleButton>
                <ToggleButton value={7}>£70,000-£80,000</ToggleButton>
                <ToggleButton value={8}>£80,000-£90,000</ToggleButton>
                <ToggleButton value={9}>£90,000-£100,000</ToggleButton>
                <ToggleButton value={10}>Over £100,000</ToggleButton>
              </ToggleButtonGroup>

              <br />
              <h4>Sector</h4>
              <ToggleButtonGroup
                type="checkbox"
                value={this.state.sector}
                name="sector"
                onChange={this.handleSectorChange}
                size="sm"
                className={styles.multiRowButtonGroup}
              >
                <ToggleButton value={"Professional Services"}>Professional Services</ToggleButton>
                <ToggleButton value={"Retail"}>Retail</ToggleButton>
                <ToggleButton value={"Hospitality"}>Hospitality</ToggleButton>
                <ToggleButton value={"Finance"}>Finance</ToggleButton>
                <ToggleButton value={"Education"}>Education</ToggleButton>
                <ToggleButton value={"Healthcare"}>Healthcare</ToggleButton>
                <ToggleButton value={"Technology"}>Technology</ToggleButton>
                <ToggleButton value={"Arts"}>Arts</ToggleButton>
              </ToggleButtonGroup>

              <br /><br />

              <Button className="btn btn-success float-right" onClick={() => this.setState({ showAdvancedSearchDiv: false })}>Apply</Button>
              <Button className="btn btn-primary float-right" onClick={() => this.resetAdvancedSearch()}>Reset</Button>

            </div>
          </div>
        ) : null
        }

        <Modal
          isOpen={this.state.showJobModal}
          ariaHideApp={false}
          onRequestClose={this.handleCloseJobModal}
          contentLabel="Job"
          overlayClassName={styles.modalOverlay}
          className={styles.modalContent}
        >
          {this.state.selectedJob ? (
            <div className={styles.modalFlexboxColumn}>
              <h1 className={styles.titleArea}>
                {this.state.selectedJob &&
                  this.state.selectedJob.company}
                <Button
                  className={styles.modalClose}
                  variant="success"
                  onClick={this.handleCloseJobModal}
                >
                  Close
                </Button>
              </h1>
              <hr />

              <div className={styles.scrollableArea}>
                <div className={styles.columnContainer}>
                  <div className={styles.leftProfileColumn}>


                    {this.state.selectedJob &&
                      this.state.selectedJob.companyLogoResourceID ? (
                      <div>
                        <Image
                          cloudName="oijqec973f1nf"
                          publicId={
                            this.state.selectedJob.companyLogoResourceID
                          }
                          width="300"
                          height="100"
                          secure={true}
                        >
                          <Transformation
                            height="100"
                            width="300"
                            crop="pad"
                            gravity="west"
                          />
                        </Image>
                      </div>
                    ) : null}
                    <br />

                    {this.state.selectedJob.jobTitle &&
                      this.state.selectedJob.jobTitle.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/job_title_icon.png"}
                          alt="Job title"
                        />
                        <p className={styles.profileParameterTitles}>Job title:</p>
                        <p className={styles.profileParameterAnswer}>
                          {this.state.selectedJob.jobTitle}
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.selectedJob.company &&
                      this.state.selectedJob.company.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/office_location.png"}
                          alt="Location"
                        />
                        <p className={styles.profileParameterTitles}>
                          Organisation:
                        </p>
                        <p className={styles.profileParameterAnswer}>
                          {this.state.selectedJob.company}
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.selectedJob.talkTitle &&
                      this.state.selectedJob.talkTitle.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/talk_about.png"}
                          alt="Talk about"
                        />
                        <p className={styles.profileParameterTitles}>Talk Title:</p>
                        <p className={styles.profileParameterAnswer}>
                          {this.state.selectedJob.talkTitle}
                          <br />
                        </p>
                      </span>
                    ) : null}

                    {this.state.selectedJob.salary &&
                      this.state.selectedJob.salary.length > 1 ? (
                      <span>
                        <img
                          className={styles.profileIcon}
                          src={window.$videoPath + "icons/previously_worked.png"}
                          alt="Previously worked at"
                        />
                        <p className={styles.profileParameterTitles}>
                          Salary
                        </p>
                        <p className={styles.profileParameterAnswer}>
                          {this.state.selectedJob.salary}
                          <br />
                        </p>
                      </span>
                    ) : null}
                  </div>

                  <div className={styles.rightProfileColumn}>
                    {this.state.selectedJob.jobDetail &&
                      this.state.selectedJob.jobDetail.length > 1 ? (
                      <span>
                        <p className={styles.profileParameterTitles}>
                          About {this.state.selectedJob.jobTitle}
                        </p>
                        <p className={styles.noIconProfileParameterAnswer}>
                          {this.state.selectedJob.jobDetail}
                          <br />
                        </p>
                      </span>
                    ) : null}


                    {this.state.selectedJob.website &&
                      this.state.selectedJob.website.length > 1 ? (
                      <span>
                        <p className={styles.profileParameterTitles}>Website:</p>
                        <p className={styles.noIconProfileParameterAnswer}>
                          <a
                            href={this.state.selectedJob.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {this.state.selectedJob.url}
                          </a>
                          <br />
                        </p>
                      </span>
                    ) : null}



                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </Modal>

        <div className={styles.galleryBackgroundDiv}>
              {/**<img
            className="imageNavBackground"
            src={window.$videoPath + "background.png"}
            alt="Background"
          ></img>*/}
            </div>

            <div className={styles.tabBar}>
              <Link to={"/lobby"} className={styles.backLink}>
                <div className={styles.tabBackDiv}>
                  <img
                    className={styles.backArrow}
                    src={window.$videoPath + "icons/backArrow.png"}
                    alt="Back"
                  />
                  Back to Lobby
                </div>
              </Link>
            </div>

            <div className={styles.titleAndSearchBar}>
              <h1 className={styles.pageTitle}>
            <strong>JOB SEARCH</strong>
          </h1>
          <div className={styles.searchTitleArea}>
            <p style={{ display: "inline" }}>Enter keywords to search for available jobs </p>
          </div>
          <input
            className={styles.searchBox}
            type="text"
            name="searchString"
            placeholder="Search"
            value={this.state.searchString}
            onChange={this.handleChange}
            style={{backgroundImage: 'url(' + window.$videoPath + 'icons/searchIcon.png)', backgroundImageWidth: '100%' }}
          />
        </div>
        <div className={styles.advancedSearchButtonBar}>
          <Button className={styles.advancedSearchButton} onClick={() => this.setState({ showAdvancedSearchDiv: true })}> Advanced Search</Button><br />
        </div>

        <section className={styles.profileSegmentsArea}>{jobSearchGallery}</section>
      </div>
    );
  }
}
