import React, { Component } from "react";
import styles from "./ResetPassword.module.scss";
import preLoginStyles from "./Pre-Login.module.scss";
const queryString = require("query-string");

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      resetToken: "",
      newPassword: "",
      confirmNewPassword: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const queryParameters = this.props.location.search;
    console.log(queryParameters);
    const queryParamatersParsed = queryString.parse(queryParameters);
    console.log(queryParamatersParsed);

    this.setState({
      email: queryParamatersParsed.email,
      resetToken: queryParamatersParsed.resetToken,
    });
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.newPassword.length < 6) {
      alert("Please use a password of at least 6 characters");
    } else if (this.state.newPassword !== this.state.confirmNewPassword) {
      alert("Please ensure your passwords match");
    } else {
      fetch("/api/resetPassword", {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.props.history.push("/");
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
          alert("Error resetting password please try again");
        });
    }
  };

  render() {
    return (
      <div>
        <div className={styles.masterPageDiv}>
          <img
            className={styles.fullScreenBackground}
            src={window.$videoPath + "login/loginBackground.jpg"}
            alt="Background"
          ></img>

          <div className={styles.resetPasswordModal}>
            <div className={styles.resetPasswordModalInnerDiv}>
              <img
                className={preLoginStyles.preLoginBoxLogo}
                src={window.$videoPath + "logo.png"}
                alt="Logo"
              />
              <br />
              <br />
              <h2 className={preLoginStyles.titleText}>Reset Password</h2>

              <form onSubmit={this.handleSubmit} noValidate>
                <br />
                <label htmlFor="newPassword">New Password:</label>
                <input
                  type="password"
                  name="newPassword"
                  className={preLoginStyles.textEntry}
                  size="50"
                  placeholder="New password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  required
                />
                <br />
                <br />
                <label htmlFor="confirmNewPassword">
                  Confirm New Password:
                </label>
                <input
                  type="password"
                  name="confirmNewPassword"
                  className={preLoginStyles.textEntry}
                  size="50"
                  placeholder="Confirm new password"
                  value={this.state.confirmPassword}
                  onChange={this.handleChange}
                  required
                />
                <br />
                <br />

                <div className={preLoginStyles.loginButtonsRow}>

                  <div style={{ flex: 1 }} />
                  <input
                    type="submit"
                    value="Submit"
                    className={preLoginStyles.highlightedButton}
                    style={{width: "48%"}}
                  />
                </div>
              </form>

              <img src={window.$videoPath + "login/poweredBy.png"} className={preLoginStyles.loginBoxFooter} alt="Login Footer" />

            </div>
          </div>
        </div>
      </div>
    );
  }
}
