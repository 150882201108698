import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import VideoPlayer from "./../../modules/videoPlayer/VideoPlayer"
import styles from "./ViewLiveStream.module.scss";

export default class ViewLiveStream extends Component {
  constructor() {
    super();
    this.state = {
      liveStreamID: "",
      streamName: "",
      muxStreamID: "",
      streamKey: "",
      redirectNow: false,
      playbackID: "",
      liveStreamAnalyticsToken: "",
      liveStreamViewerCount: ""
    };
  }

  componentDidMount () {
    this.retrieveLiveStream();
  }

  retrieveLiveStream () {
    fetch("/api/getLiveStream?liveStreamID=" + this.props.match.params.liveStreamID)
      .then((res) => res.json())
      .then((data) => this.loadResponseIntoState(data))
      .then(() => this.getLiveStreamAnalyticsToken())
  }

  loadResponseIntoState (data) {
    this.setState({
      liveStreamID: data.liveStream._id,
      streamName: data.liveStream.streamName,
      muxStreamID: data.liveStream.muxStreamID,
      streamKey: data.liveStream.streamKey,
      playbackID: data.liveStream.playbackID,
    });
  }

  getLiveStreamAnalyticsToken () {
    fetch("/api/getLiveStreamAnalytics?playbackID=" + this.state.playbackID)
      .then((res) => res.json())
      .then((data) => this.loadLiveStreamAnalyticsTokenIntoState(data))
  }

  loadLiveStreamAnalyticsTokenIntoState (data) {
    this.setState({
      liveStreamAnalyticsToken: data.liveStreamAnalyticsToken,
    })
    // Now request live stream viewer count using token
    this.requestLiveStreamViewerCount(data.liveStreamAnalyticsToken);
  }

  requestLiveStreamViewerCount (liveStreamAnalyticsToken) {
    console.log("Fetching: " + "https://stats.mux.com/counts?token=" + liveStreamAnalyticsToken);
    fetch("https://stats.mux.com/counts?token=" + liveStreamAnalyticsToken)
      .then((res) => res.json())
      .then((data) => this.loadLiveStreamViewerCountIntoState(data))
  }

  loadLiveStreamViewerCountIntoState (data) {
    this.setState({
      liveStreamViewerCount: data.data[0],
    });
  }

  updateInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  updateLiveStream = (event) => {
    event.preventDefault();
    if (this.state.streamName.length > 0) {
      fetch("/api/updateLiveStream", {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ redirectNow: true });
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
          alert("Error updating settings -  please try again");
        });
    } else {
      alert("Please enter a live stream name");
    }
  };

  render () {
    return (
      <div className={styles.adminContentArea}>
        {this.state.redirectNow ? <Redirect to={"/liveStreamAdminList"} /> : null}
        <h1>View Live Stream</h1>
        <br />
        <form onSubmit={this.updateLiveStream}>
          <h4>Stream Name:
            <input
              type="text"
              name="streamName"
              size={40}
              placeholder="Live Stream Name"
              value={this.state.streamName}
              onChange={this.updateInput}
            /></h4>
          <br />
          <h4>Stream ID: {this.state.muxStreamID}</h4>
          <br />
          <h4>Stream Key: {this.state.streamKey}</h4>
          <br />
          <h4>Playback ID: mux://{this.state.playbackID}</h4>
          <br />
          <h4>Live Stream Views: {this.state.liveStreamViewerCount.views}</h4>
          <br />
          <h4>Live Stream Viewers: {this.state.liveStreamViewerCount.viewers}</h4>
          <br />
          {<button type="submit">Save Changes</button>}
        </form>
        <br />
        <br />
        <div style={{ width: "300px" }}>
          <VideoPlayer
            resourceLocator={"mux://" + this.state.playbackID}
            location={"Video Admin"}
            userProfile={this.props.userProfile}
          />
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}