import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import BreakoutsMobile from "./BreakoutsMobile"
import styles from "./Breakouts.module.scss";
import { requestPoints } from "../helpers/requestPoints";

export default function Breakouts (props) {
  const [introVideoComplete, setIntroVideoComplete] = useState(false);

  useEffect(() => {
    requestPoints("other", "visitBreakoutLobby")
    // Start timeout in case video does not load
    setTimeout(function () {
      setIntroVideoComplete(true);
    }, 6000);
  }, []);

  return (
    <div>
      {props.showMobile ?
        <BreakoutsMobile />
        :
        <div className="imageNavDiv">
          {props.playVideo ? (
            <div>
              <video
                className="imageNavBackground"
                autoPlay
                id="myVideo"
                webkit-playsinline="true"
                playsInline
                muted
                onEnded={() => setIntroVideoComplete(true)}
              >
                <source
                  src={window.$videoPath + "visuals/" + props.language + "/breakouts.webm"}
                  type="video/webm"
                />
                <source
                  src={window.$videoPath + "visuals/" + props.language + "/breakouts.mp4"}
                  type="video/mp4"
                />
              </video>
              <CSSTransition
                in={introVideoComplete}
                timeout={1500}
                classNames="imageNavBackground"
                unmountOnExit
              >
                <img
                  className="imageNavBackground"
                  src={window.$videoPath + "visuals/" + props.language + "/breakouts.jpg"}
                  alt="Background"
                ></img>
              </CSSTransition>
            </div>
          ) : (
            <img
              className="imageNavBackground"
              src={window.$videoPath + "visuals/" + props.language + "/breakouts.jpg"}
              alt="Background"
            ></img>
          )}

          <Link to="/lobby">
            <img
              className={`${styles.hoverHotspot} ${styles.lobbyHoverHotspot}`}
              src="/hoverShapes/hoverSquare.png"
              alt="Navigate to Lobby"
            ></img>
          </Link>

          {/** Marketplace */}
          <Link to="/resourceLibrary">
            <img
              className={`${styles.hoverHotspot} ${styles.smallBreakout1HoverHotspot}`}
              src="/hoverShapes/hoverSquare.png"
              alt="Navigate to small breakout 1"
            ></img>
          </Link>

          {/** Spatial Chat */}
          <Link to="/customBreakout/61409f528999576d94879702">
            <img
              className={`${styles.hoverHotspot} ${styles.smallBreakout2HoverHotspot}`}
              src="/hoverShapes/hoverSquare.png"
              alt="Navigate to small breakout 2"
            ></img>
          </Link>

          {/** Video */}
          <Link to="/breakout/6086abd759b00d4eefcc69b1">
            <img
              className={`${styles.hoverHotspot} ${styles.smallBreakout3HoverHotspot}`}
              src="/hoverShapes/hoverSquare.png"
              alt="Navigate to small breakout 3"
            ></img>
          </Link>

          {/** Whereby */}
          <Link to="/customBreakout/6086abb259b00d4eefcc69b0">
            <img
              className={`${styles.hoverHotspot} ${styles.smallBreakout4HoverHotspot}`}
              src="/hoverShapes/hoverSquare.png"
              alt="Navigate to small breakout 4"
            ></img>
          </Link>
        </div>
      }
    </div>
  );
}
