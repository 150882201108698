import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Button from "react-bootstrap/Button";
import VideoPlayer from "./../../modules/videoPlayer/VideoPlayer"
import styles from "./ViewVideo.module.scss";

export default class ViewVideo extends Component {
  constructor() {
    super();
    this.state = {
      videoID: "",
      videoName: "",
      playbackID: "",
      aspectRatio: "",
      duration: 0,
      status: "unknown",
      maxStoredResolution: "",
      createdAt: "",
      tracks: [],
      redirectNow: false,
      subtitleFileURL: "",
      selectedLanguageCode: "en-UK",
    };
  }

  componentDidMount () {
    this.retrieveVideo();
  }

  retrieveVideo () {
    fetch("/api/getVideo?videoID=" + this.props.match.params.videoID)
      .then((res) => res.json())
      .then((data) => this.loadResponseIntoState(data));
  }

  loadResponseIntoState (data) {
    // Response here could for an ready to go item, an awaiting upload or a timed out upload
    // Test using the status flag
    console.log("Video status: " + data.response.status);
    if (data.response.status === "ready") {
      // Case for ready to play asset
      console.log(data.response.created_at);
      const uploadDate = new Date(0);
      uploadDate.setUTCSeconds(parseInt(data.response.created_at))
      console.log(uploadDate)
      this.setState({
        videoID: data.video._id,
        videoName: data.video.videoName,
        playbackID: data.video.playbackID,
        muxAssetID: data.response.id,
        muxPlaybackID: data.response.playback_ids[0].id,
        aspectRatio: data.response.aspect_ratio,
        duration: data.response.duration,
        status: data.response.status,
        maxStoredResolution: data.response.max_stored_resolution,
        createdAt: uploadDate,
        tracks: data.response.tracks,
      });
      // Case for asset is ready to play but we may or may not have a playback ID on Beehive yet
      if (data.video.playbackID == null || data.video.playbackID.length < 1) {
        alert("Asset is uploaded and ready to play but we do not have a playback ID yet.  Please click Save Asset to add playback reference to Beehive then re-select this item from the asset list")
      }
    } else if (data.response.status !== "asset_created") {
      // Case for waiting for upload or preparing
      this.setState({
        videoID: data.video._id,
        videoName: data.video.videoName,
        status: data.response.status,
      });
      if (data.response.status === "waiting") {
        alert("Video status is waiting which means it either hasn't been uploaded yet or has not yet finished uploading");
      } else if (data.response.status === "timed_out") {
        alert("Video status is timed out which means that you took too lonng to upload the video.  Please create another video asset and upload immediately");
      } else if (data.response.status === "preparing") {
        alert("Video status preparing which means the upload was successful but we are still processing the video for playback");
      }

    } else if (data.response.status === "asset_created") {
      // Asset has been created but we have been returned an upload response rather than having an asset ID yet
      this.setState({
        videoID: data.video._id,
        videoName: data.video.videoName,
        playbackID: data.video.playbackID,
        muxAssetID: data.response.asset_id,
        status: data.response.status,
      });
      alert("Asset has been uploaded but has not yet been registered on Beehive.  Please click Save Asset to add to Beehive and then re-select this item from the asset list");
    }
  }

  updateInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  updateVideo = (event) => {
    event.preventDefault();
    if (this.state.videoName.length > 0) {
      fetch("/api/updateVideo", {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ redirectNow: true });
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
          alert("Error updating settings -  please try again");
        });
    } else {
      alert("Please enter a video name");
    }
  };

  addSubtitleTrack = (event) => {
    event.preventDefault();
    if (this.state.videoName.length > 0) {
      fetch("/api/addSubtitleTrack", {
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.retrieveVideo();
          } else {
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
          alert("Error updating settings -  please try again");
        });
    } else {
      alert("Please enter a subtitle file URL");
    }
  };

  deleteTrack = (trackID) => {
    fetch("/api/deleteTrack", {
      method: "POST",
      body: JSON.stringify({ muxAssetID: this.state.muxAssetID, trackID: trackID }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.retrieveVideo();
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Error deleting track -  please try again");
      });
  }


  render () {

    const mediaTracks = this.state.tracks.map((track) => {
      return (
        <tr
          key={track.id}
        >
          <td>{track.type}</td>
          <td>
            {track.type === "video" ?
              <p>Width: {track.max_width}<br />
                Height: {track.max_height}
              </p> : null}
            {track.type === "audio" ? <p>Audio Channels: {track.max_channels}</p> : null}
            {track.type === "text" ?
              <p>Text Type: {track.text_type}<br />
                Track Status: {track.status}<br />
                Text Name : {track.name}<br />
                Language : {track.language_code}<br />
              </p> : null}
          </td>
          <td><Button onClick={() => this.deleteTrack(track.id)}>Delete Track</Button></td>

        </tr>
      )
    });

    return (
      <div className={styles.adminContentArea}>
        {this.state.redirectNow ? <Redirect to={"/videoAdminList"} /> : null}
        <h1>View Video</h1>

        <br />

        <form onSubmit={this.updateVideo}>
          <p>Video Name:<input
            type="text"
            name="videoName"
            size={100}
            placeholder="Video Name"
            value={this.state.videoName}
            onChange={this.updateInput}
          /></p>
          <p>Stored Playback ID: {this.state.playbackID}</p>
          <p>Mux Playback ID: {this.state.muxPlaybackID}</p>
          <p>Beehive Asset ID: {this.state.videoID}</p>
          <p>Mux Asset ID: {this.state.muxAssetID}</p>
          <p>Aspect Ratio: {this.state.aspectRatio}</p>
          <p>Duration: {this.state.duration} seconds</p>
          <p>Status: {this.state.status}</p>
          <p>Resolution: {this.state.maxStoredResolution}</p>
          <p>Upload Date/Time: {this.state.createdAt.toString()}</p>
          {<button type="submit">Save Asset</button>}
        </form>

        <br />
        <br />
        <div style={{ width: "300px" }}>
          <VideoPlayer
            resourceLocator={"mux://" + this.state.playbackID}
            location={"Video Admin"}
            userProfile={this.props.userProfile}
            title={this.state.videoName}
            videoID={this.state.videoID}
          />
        </div>

        <br />
        <br />

        <h3>Media Tracks</h3>
        <table>
          <tbody>
            {mediaTracks}
          </tbody>
        </table>
        <br />
        <br />

        <h3>Add Subtitle File</h3>
        <form onSubmit={this.addSubtitleTrack}>
          <label for="selectedLanguageCode">Language:</label>
          <select
            id="selectedLanguageCode"
            name="selectedLanguageCode"
            value={this.state.selectedLanguageCode}
            onChange={this.updateInput}
          >
            <option value="en-UK">UK English</option>
            <option value="de-DE">German</option>
            <option value="en-US">US English</option>
            <option value="es-ES">Spanish</option>
            <option value="fr-FR">French</option>
            <option value="it-IT">Italian</option>
          </select>
          <br /><br />
          <p>Subtitle File URL:<br />
            <input
              type="text"
              name="subtitleFileURL"
              size={100}
              placeholder="Subtitle file URL"
              value={this.state.subtitleFileURL}
              onChange={this.updateInput}
            /></p>
          {<button type="submit">Add Subtitle File</button>}
        </form>
        <br />
        <br />
        <br />
      </div>
    );
  }
}