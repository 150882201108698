import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import styles from "./BreakoutsMobile.module.scss";

export default function Breakouts (props) {

  return (
    <div className={styles.mobileListView} >
      <div className={styles.mobileTitleArea}>
        <h1 className={styles.mobileTitleText}>BREAKOUTS</h1>
        <Link to="/lobby"><Button>Back to Lobby</Button></Link>
      </div>

      <Link to="/breakout/6086ab8b59b00d4eefcc69af">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}>Breakout 1</h3>
        </div>
      </Link>

      <Link to="/breakout/6086ab8b59b00d4eefcc69af">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}>Breakout 2</h3>
        </div>
      </Link>

      <Link to="/breakout/6086abb259b00d4eefcc69b0">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}>Breakout 3</h3>
        </div>
      </Link>

      <Link to="/breakout/6086ab8b59b00d4eefcc69af">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}>Breakout 4</h3>
        </div>
      </Link>

    </div>
  );
}
