import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import NetworkingMobile from "./NetworkingMobile";
import JoinMeetingModal from "./JoinMeetingModal";
import { requestPoints } from "./../helpers/requestPoints.js";
import styles from "./Networking.module.scss";

export default class Networking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      introVideoComplete: false,
      showJoinMeetingModal: false,
    };
  }

  componentDidMount() {
    requestPoints("visitNetworkingLounge", "visitNetworkingLounge");

    // Start timeout in case video does not load
    setTimeout(
      function () {
        this.setState({ introVideoComplete: true });
      }.bind(this),
      6000
    );

    if (this.props.openNetworkingModal) {
      this.setShowJoinMeetingModal(true);
    }
  }

  setShowJoinMeetingModal = (showJoinMeetingModal) => {
    this.setState({ showJoinMeetingModal: showJoinMeetingModal });
  }

  render() {
    return (
      <div>
        {this.props.showMobile ?
          <NetworkingMobile
            breakout1Live={this.props.breakout1Live}
            breakout2Live={this.props.breakout2Live}
            openNetworkingModal={this.props.openNetworkingModal}
          />
          :
          <div className={styles.masterPageDiv}>
            <video
              className="imageNavBackground"
              autoPlay
              webkit-playsinline="true"
              playsInline
              muted
              id="introVideo"
              onEnded={() => this.setState({ introVideoComplete: true })}
            >
              <source
                src={window.$videoPath + "visuals/" + this.props.language + "/networking.webm"}
                type="video/webm"
              />
              <source
                src={window.$videoPath + "visuals/" + this.props.language + "/networking.mp4"}
                type="video/mp4"
              />
            </video>

            <CSSTransition
              in={this.state.introVideoComplete}
              timeout={1500}
              classNames="imageNavBackground"
              unmountOnExit
            >
              <img
                className="imageNavBackground"
                src={window.$videoPath + "visuals/" + this.props.language + "/networking.png"}
                alt="Background"
              ></img>
            </CSSTransition>

            {this.state.introVideoComplete ? (
              <div>
                {/**<div className={styles.videoDiv}>
                  <iframe
                    title="Content iFrame"
                    className={styles.iFrameClass}
                    autoPlay
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    src={"https://player.vimeo.com/video/540208530?autoplay=1"}
                  ></iframe>
                </div>*/}


                <Link to="/lobby">
                  <img
                    className={`${styles.visibleHoverHotspot} ${styles.lobbyHoverHotspot}`}
                    src={window.$videoPath + "visualAssets/en/hoverAreas/networking-lobby.png"}
                    alt="To Lobby"
                  ></img>
                </Link>
                <Link to="/gallery">
                  <img
                    className={`${styles.visibleHoverHotspot} ${styles.attendeesHoverHotspot}`}
                    src={window.$videoPath + "visualAssets/en/hoverAreas/networking-attendees.png"}
                    alt="To Attendees"
                  ></img>
                </Link>


                {/**<img
                  className={`${styles.visibleHoverHotspot} ${styles.joinMeetingHoverHotspot}`}
                  src={window.$videoPath + "visualAssets/en/hoverAreas/networking-networking.png"}
                  alt="Join Meeting"
                  onClick={() => this.setShowJoinMeetingModal(true)}
              ></img>*/}

                
                  <img
                    className={`${styles.visibleHoverHotspot} ${styles.joinMeetingHoverHotspot}`}
                    src={window.$videoPath + "visualAssets/en/hoverAreas/networking-networking.png"}
                    alt="Join Meeting"
                    onClick={() => alert("Networking for this event takes place off platform")}
                  ></img>



                <a href="https://armrunner.com/" target="_blank" rel="noopener noreferrer">
                  <img
                    className={`${styles.visibleHoverHotspot} ${styles.gamingHoverHotspot}`}
                    src={window.$videoPath + "visualAssets/en/hoverAreas/networking-gaming.png"}
                    alt="To gaming"
                  ></img>
                </a>

                <Link to="/ondemand">
                  <img
                    className={`${styles.visibleHoverHotspot} ${styles.ondemandHoverHotspot}`}
                    src={window.$videoPath + "visualAssets/en/hoverAreas/networking-ondemand.png"}
                    alt="To On-demand"
                  ></img>
                </Link>
              </div>

            ) : null}

            {/**<JoinMeetingModal
              showJoinMeetingModal={this.state.showJoinMeetingModal}
              setShowJoinMeetingModal={this.setShowJoinMeetingModal}
              breakout1Live={this.props.breakout1Live}
            breakout2Live={this.props.breakout2Live}
            />*/}
          </div>
        }
      </div>
    );
  }
}
