import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import CustomOnDemandModal from "./CustomOnDemandModal";
import dompurify from "dompurify";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./CustomOnDemandMobile.module.scss";

export default class CustomOnDemand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOnDemand: null,
      showOnDemandModal: false,
    };
  }

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  showOnDemand (onDemand) {
    requestPoints("viewOnDemandItem", onDemand.id);
    this.setState({ selectedOnDemand: onDemand, showOnDemandModal: true });
  }

  onDemandGallery = () => {
    return this.props.onDemandContent.map((contentItem) => {
      return (
        <div
          key={contentItem.id}
          className={styles.mobileListItem}
          onClick={() => this.showOnDemand(contentItem)}
        >
          <h1 className={styles.mobileListTitle}>{contentItem.contentTitle}</h1>
          <hr className={styles.horizRule} />
          <h3>{contentItem.speakerName}</h3>
          <p dangerouslySetInnerHTML={this.sanitizeCustomField(contentItem.aboutText)} className={styles.aboutText}></p>
        </div>
      );
    });
  };

  render () {
    return (
      <div className={styles.mobileListView} >
        <div className={styles.mobileTitleArea}>
          <h1 className={styles.mobileTitleText}>On-Demand</h1>
          {this.props.holdingVersion ?
          <Link to="/holding"><Button>Back to Menu</Button></Link>
          :
          <Link to="/lobby"><Button>Back to Lobby</Button></Link>
  }
        </div>

        {this.onDemandGallery()}

        <CustomOnDemandModal
          showOnDemandModal={this.state.showOnDemandModal}
          closeOnDemandModal={() => this.setState({ showOnDemandModal: false })}
          selectedOnDemand={this.state.selectedOnDemand}
          userProfile={this.props.userProfile}
        />
      </div>
    );
  }
}
