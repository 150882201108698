import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import styles from "./ConfirmUserEmail.module.scss";
import preLoginStyles from "./Pre-Login.module.scss";
const queryString = require("query-string");

export default class ConfirmUserEmail extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      confirmationSuccessful: "pending",
    };
  }

  componentDidMount () {
    const queryParameters = this.props.location.search;
    const queryParamatersParsed = queryString.parse(queryParameters);
    fetch("/api/confirmUserEmail", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userID: queryParamatersParsed.userID,
        confirmationCode: queryParamatersParsed.confirmationCode,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Returned status: " + data.status);
        this.setState({
          confirmationSuccessful: data.status,
        });
      });
  }

  render () {
    return (
      <div className={styles.masterPageDiv}>
        <img
          className={styles.fullScreenBackground}
          src={window.$videoPath + "login/loginBackground.jpg"}
          alt="Background"
        ></img>

        <div className={styles.confirmUserEmailModal}>
          <div className={styles.confirmUserEmailModalInnerDiv}>
            <img
              className={preLoginStyles.preLoginBoxLogo}
              src={window.$videoPath + "logo.png"}
              alt="Logo"
            />
            <br />
            <br />
              <h2 className={preLoginStyles.titleText}>ACCOUNT CONFIRMATION STATUS</h2>
            <br />
            <br />
            <br />
            <h2 className={styles.confirmationStatusText}>
              {this.state.confirmationSuccessful === "success"
                ? "Confirmation Successful"
                : "Awaiting Confirmation"}
            </h2>
          </div>
          {this.state.confirmationSuccessful === "success" ? (
            <Redirect to="/" />
          ) : null}

        </div>
      </div>
    );
  }
}
