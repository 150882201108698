import React, { Component } from "react";
import ReactTooltip from 'react-tooltip';
import Button from "react-bootstrap/Button";
import Modal from "react-modal";
import UserSessionOptionsBreakouts1 from "./userSessionOptions/UserSessionOptionsBreakouts1"
import UserSessionOptionsBreakouts2 from "./userSessionOptions/UserSessionOptionsBreakouts2"
import UserSessionOptionsBreakouts3 from "./userSessionOptions/UserSessionOptionsBreakouts1"
import UserSessionOptionsBreakouts4 from "./userSessionOptions/UserSessionOptionsBreakouts2"
import UserSessionOptionsBreakouts5 from "./userSessionOptions/UserSessionOptionsBreakouts1"
import UserSessionOptionsBreakouts6 from "./userSessionOptions/UserSessionOptionsBreakouts2"
import UserSessionOptionsBreakouts7 from "./userSessionOptions/UserSessionOptionsBreakouts1"
import UserSessionOptionsBreakouts8 from "./userSessionOptions/UserSessionOptionsBreakouts2"
import styles from "./UserSessions.module.scss";

export default class UserSessions extends Component {
    constructor(props) {
        super(props);
        this.state = {

            breakoutSession1Selection: 0,
            breakoutSession2Selection: 0,
            breakoutSession3Selection: 0,
            breakoutSession4Selection: 0,
            breakoutSession5Selection: 0,
            breakoutSession6Selection: 0,
            breakoutSession7Selection: 0,
            breakoutSession8Selection: 0,
            breakoutSession9Selection: 0,
            breakoutSession10Selection: 0,
            breakoutSession11Selection: 0,
            breakoutSession12Selection: 0,
            breakoutSession13Selection: 0,
            breakoutSession14Selection: 0,
            breakoutSession15Selection: 0,
            breakoutSession16Selection: 0,

            page0Active: true,
            page1Active: false,
            page1Complete: false,
            page2Active: false,
            page2Complete: false,
            page3Active: false,
            page3Complete: false,
            page4Active: false,
            page4Complete: false,
            page5Active: false,
            page5Complete: false,
            page6Active: false,
            page6Complete: false,
            page7Active: false,
        };

        this.handleChange = this.handleChange.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);

        this.profileScrollRef = React.createRef();
    }

    componentDidMount () {
        // Get user session selections
        this.retrieveUserDetails();
    }

    resetDialogueStatus () {
        this.setState({
            page0Active: true,
            page1Active: false,
            page1Complete: false,
            page2Active: false,
            page2Complete: false,
            page3Active: false,
            page3Complete: false,
            page4Active: false,
            page4Complete: false,
            page5Active: false,
            page5Complete: false,
            page6Active: false,
            page6Complete: false,
            page7Active: false,
        });
    }

    retrieveUserDetails = () => {
        fetch("/api/getUserProfile")
            .then((res) => {
                if (!res.ok) {
                    throw new Error(res.status);
                } else {
                    return res.json();
                }
            })
            .then((data) => this.loadResponseIntoState(data))
            .catch((error) => {
                console.log("Unable to load user profile");
            });
    };

    loadResponseIntoState (data) {
        this.setState({
            breakoutSession1Selection: data.user.breakoutSession1Selection,
            breakoutSession2Selection: data.user.breakoutSession2Selection,
            breakoutSession3Selection: data.user.breakoutSession3Selection,
            breakoutSession4Selection: data.user.breakoutSession4Selection,
            breakoutSession5Selection: data.user.breakoutSession5Selection,
            breakoutSession6Selection: data.user.breakoutSession6Selection,
            breakoutSession7Selection: data.user.breakoutSession7Selection,
            breakoutSession8Selection: data.user.breakoutSession8Selection,
            breakoutSession9Selection: data.user.breakoutSession9Selection,
            breakoutSession10Selection: data.user.breakoutSession10Selection,
            breakoutSession11Selection: data.user.breakoutSession11Selection,
            breakoutSession12Selection: data.user.breakoutSession12Selection,
            breakoutSession13Selection: data.user.breakoutSession13Selection,
            breakoutSession14Selection: data.user.breakoutSession14Selection,
            breakoutSession15Selection: data.user.breakoutSession15Selection,
            breakoutSession16Selection: data.user.breakoutSession16Selection,
        });
        this.resetDialogueStatus();
    }

    handleChange = (event) => {
        const { value, name } = event.target;
        this.setState({
            [name]: value,
        });
    };


    handleBreakoutSession1SelectionChange = (value) => {
        this.setState({
            breakoutSession1Selection: value,
        });
    };

    handleBreakoutSession2SelectionChange = (value) => {
        this.setState({
            breakoutSession2Selection: value,
        });
    };

    handleBreakoutSession3SelectionChange = (value) => {
        this.setState({
            breakoutSession3Selection: value,
        });
    };

    handleBreakoutSession4SelectionChange = (value) => {
        this.setState({
            breakoutSession4Selection: value,
        });
    };

    handleBreakoutSession5SelectionChange = (value) => {
        this.setState({
            breakoutSession5Selection: value,
        });
    };

    handleBreakoutSession6SelectionChange = (value) => {
        this.setState({
            breakoutSession6Selection: value,
        });
    };

    handleBreakoutSession7SelectionChange = (value) => {
        this.setState({
            breakoutSession7Selection: value,
        });
    };

    handleBreakoutSession8SelectionChange = (value) => {
        this.setState({
            breakoutSession8Selection: value,
        });
    };

    handleBreakoutSession9SelectionChange = (value) => {
        this.setState({
            breakoutSession9Selection: value,
        });
    };

    handleBreakoutSession10SelectionChange = (value) => {
        this.setState({
            breakoutSession10Selection: value,
        });
    };

    handleBreakoutSession11SelectionChange = (value) => {
        this.setState({
            breakoutSession11Selection: value,
        });
    };

    handleBreakoutSession12SelectionChange = (value) => {
        this.setState({
            breakoutSession12Selection: value,
        });
    };

    handleBreakoutSession13SelectionChange = (value) => {
        this.setState({
            breakoutSession13Selection: value,
        });
    };

    handleBreakoutSession14SelectionChange = (value) => {
        this.setState({
            breakoutSession14Selection: value,
        });
    };

    handleBreakoutSession15SelectionChange = (value) => {
        this.setState({
            breakoutSession15Selection: value,
        });
    };

    handleBreakoutSession16SelectionChange = (value) => {
        this.setState({
            breakoutSession16Selection: value,
        });
    };


    saveFormData = () => {
        fetch("/api/updateUserSessionSelection", {
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    console.log("Saved track session selection")
                } else {
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .catch((err) => {
                console.error(err);
                alert("Error saving session selection -  please try again");
            });
    };


    saveAndExit = () => {
        this.saveFormData();
        this.props.closeSessionSelectionModal();
        this.resetDialogueStatus();
    };

    closeWithoutSave = () => {
        this.props.closeSessionSelectionModal();
        this.resetDialogueStatus();
    }

    nextPage0 = () => {
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page0Active: false, page1Active: true });

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage1 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page1Active: false, page0Active: true });
        if (
            this.state.breakoutSession1Selection &&
            this.state.breakoutSession1Selection > 0 &&
            this.state.breakoutSession2Selection &&
            this.state.breakoutSession2Selection > 0 &&
            this.state.breakoutSession3Selection &&
            this.state.breakoutSession3Selection > 0 &&
            this.state.breakoutSession4Selection &&
            this.state.breakoutSession4Selection > 0
        ) {
            this.setState({ page1Complete: true });
        } else {
            this.setState({ page1Complete: false });
        }
    };

    nextPage1 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page1Active: false, page2Active: true });
        if (
            this.state.breakoutSession1Selection &&
            this.state.breakoutSession1Selection > 0 &&
            this.state.breakoutSession2Selection &&
            this.state.breakoutSession2Selection > 0 &&
            this.state.breakoutSession3Selection &&
            this.state.breakoutSession3Selection > 0 &&
            this.state.breakoutSession4Selection &&
            this.state.breakoutSession4Selection > 0
        ) {
            this.setState({ page1Complete: true });
        } else {
            this.setState({ page1Complete: false });
        }
        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage2 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page2Active: false, page1Active: true });
        if (
            this.state.breakoutSession5Selection &&
            this.state.breakoutSession5Selection > 0 &&
            this.state.breakoutSession6Selection &&
            this.state.breakoutSession6Selection > 0 &&
            this.state.breakoutSession7Selection &&
            this.state.breakoutSession7Selection > 0 &&
            this.state.breakoutSession8Selection &&
            this.state.breakoutSession8Selection > 0
        ) {
            this.setState({ page2Complete: true });
        } else {
            this.setState({ page2Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    nextPage2 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page2Active: false, page7Active: true });
        if (
            this.state.breakoutSession5Selection &&
            this.state.breakoutSession5Selection > 0 &&
            this.state.breakoutSession6Selection &&
            this.state.breakoutSession6Selection > 0 &&
            this.state.breakoutSession7Selection &&
            this.state.breakoutSession7Selection > 0 &&
            this.state.breakoutSession8Selection &&
            this.state.breakoutSession8Selection > 0
        ) {
            this.setState({ page2Complete: true });
        } else {
            this.setState({ page2Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage3 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page3Active: false, page2Active: true });
        if (
            this.state.breakoutSession9Selection &&
            this.state.breakoutSession9Selection > 0 &&
            this.state.breakoutSession10Selection &&
            this.state.breakoutSession10Selection > 0 &&
            this.state.breakoutSession11Selection &&
            this.state.breakoutSession11Selection > 0 &&
            this.state.breakoutSession12Selection &&
            this.state.breakoutSession12Selection > 0
        ) {
            this.setState({ page3Complete: true });
        } else {
            this.setState({ page3Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    nextPage3 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page3Active: false, page4Active: true });
        if (
            this.state.breakoutSession9Selection &&
            this.state.breakoutSession9Selection > 0 &&
            this.state.breakoutSession10Selection &&
            this.state.breakoutSession10Selection > 0 &&
            this.state.breakoutSession11Selection &&
            this.state.breakoutSession11Selection > 0 &&
            this.state.breakoutSession12Selection &&
            this.state.breakoutSession12Selection > 0
        ) {
            this.setState({ page3Complete: true });
        } else {
            this.setState({ page3Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage4 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page4Active: false, page3Active: true });
        if (
            this.state.breakoutSession133Selection &&
            this.state.breakoutSession13Selection > 0 &&
            this.state.breakoutSession14Selection &&
            this.state.breakoutSession14Selection > 0 &&
            this.state.breakoutSession15Selection &&
            this.state.breakoutSession15Selection > 0 &&
            this.state.breakoutSession16Selection &&
            this.state.breakoutSession16Selection > 0
        ) {
            this.setState({ page4Complete: true });
        } else {
            this.setState({ page4Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    nextPage4 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page4Active: false, page7Active: true });
        if (
            this.state.breakoutSession133Selection &&
            this.state.breakoutSession13Selection > 0 &&
            this.state.breakoutSession14Selection &&
            this.state.breakoutSession14Selection > 0 &&
            this.state.breakoutSession15Selection &&
            this.state.breakoutSession15Selection > 0 &&
            this.state.breakoutSession16Selection &&
            this.state.breakoutSession16Selection > 0
        ) {
            this.setState({ page4Complete: true });
        } else {
            this.setState({ page4Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage5 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page5Active: false, page4Active: true });
        if (
            this.state.breakoutSession5ASelection &&
            this.state.breakoutSession5ASelection > 0
        ) {
            this.setState({ page5Complete: true });
        } else {
            this.setState({ page5Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    nextPage5 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page5Active: false, page6Active: true });
        if (
            this.state.breakoutSession5ASelection &&
            this.state.breakoutSession5ASelection > 0
        ) {
            this.setState({ page5Complete: true });
        } else {
            this.setState({ page5Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    previousPage6 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page6Active: false, page5Active: true });
        if (
            this.state.breakoutSession6ASelection &&
            this.state.breakoutSession6ASelection > 0
        ) {
            this.setState({ page6Complete: true });
        } else {
            this.setState({ page6Complete: false });
        }

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };

    nextPage6 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page6Active: false, page7Active: true });
        if (
            this.state.breakoutSession6ASelection &&
            this.state.breakoutSession6ASelection > 0
        ) {
            this.setState({ page6Complete: true });
        } else {
            this.setState({ page6Complete: false });
        }
    };

    previousPage7 = () => {
        this.saveFormData();
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page7Active: false, page6Active: true });

        setTimeout(() => { ReactTooltip.rebuild(); }, 1000);
    };



    closeAndResetProfileModal = () => {
        this.setState({ page1Complete: false });
    };

    render () {
              return (
            <div>

                <Modal
                    onAfterOpen={this.retrieveUserDetails}
                    isOpen={this.props.showSessionSelectionModal}
                    ariaHideApp={false}
                    contentLabel="User Profile"
                    //contentRef={(contentDiv) => (this.profileScrollRef = contentDiv)}
                    overlayClassName={styles.modalOverlay}
                    className={styles.modalContent}
                    style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
                >
                    <ReactTooltip className={styles.toolTip} style={{ zIndex: 5000000000 }} html={true} id={"sessionInfo"} border={true} effect={"float"} />

                    <div className={styles.modalFlexboxColumn}>
                        <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
                            <h1 className={styles.modalPageTitle}>Plan my day</h1>
                        </div>

                        <div className={styles.scrollableArea} ref={(contentDiv) => (this.profileScrollRef = contentDiv)}>
                            <div className={styles.sessionSelectionArea}>
                                <br />
                                {/*Page 0 */}
                                {this.state.page0Active ? (
                                    <React.Fragment>
                                        <p className={styles.smallSubheading} >
                                            Choose which sessions you would like to add to your agenda for each time slot - clicking <strong>Next</strong> to proceed to the next page and then <strong>Save & exit</strong> to confirm your choices.<br /><br />
                                            If you don’t want to attend a session during one of the time slots, please select <strong>Exhibition and networking</strong> where you can freely browse the exhibition stands or network with other delegates.<br /><br />
                                            You can change your selections at any time throughout the event.

                                        </p>
                                        <br /><br />
                                        <div className={styles.buttonArea}>
                                            <Button
                                                onClick={this.nextPage0}
                                                className={`${"btn btn-success"} ${styles.bottomRightButtons}`}
                                            >
                                                Next
                                            </Button>
                                            <Button
                                                onClick={this.closeWithoutSave}
                                                className={`${"btn btn-primary"} ${styles.bottomRightButtons}`}
                                            >
                                                Close
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                ) : null}

                                {/*Page 1 */}
                                {this.state.page1Active ? (
                                    <div className={styles.pageContentPanel}>
                                        <p className={styles.subheading}>Monday 1st December 2021</p>

                                        <br />
                                        <h4 className={styles.dayHeadings}>10:30 - 11:00</h4>
                                        <UserSessionOptionsBreakouts2
                                            {...this.state}
                                            handleBreakoutSessionSelectionChange={this.handleBreakoutSession1SelectionChange}
                                            breakoutSessionSelection={this.state.breakoutSession1Selection}
                                        />
                                        <br />
                                        <h4 className={styles.dayHeadings}>11:00 - 11:30</h4>
                                        <UserSessionOptionsBreakouts1
                                            {...this.state}
                                            handleBreakoutSessionSelectionChange={this.handleBreakoutSession2SelectionChange}
                                            breakoutSessionSelection={this.state.breakoutSession2Selection}
                                        />
                                        <br />
                                        <h4 className={styles.dayHeadings}>11:30 - 12:00</h4>
                                        <UserSessionOptionsBreakouts1
                                            {...this.state}
                                            handleBreakoutSessionSelectionChange={this.handleBreakoutSession3SelectionChange}
                                            breakoutSessionSelection={this.state.breakoutSession3Selection}
                                        />
                                        <br />
                                        <h4 className={styles.dayHeadings}>13:00 - 13:30</h4>
                                        <UserSessionOptionsBreakouts1
                                            {...this.state}
                                            handleBreakoutSessionSelectionChange={this.handleBreakoutSession4SelectionChange}
                                            breakoutSessionSelection={this.state.breakoutSession4Selection}
                                        />
                                        <br />
                                        <h4 className={styles.dayHeadings}>14:00 - 14:30</h4>
                                        <UserSessionOptionsBreakouts2
                                            {...this.state}
                                            handleBreakoutSessionSelectionChange={this.handleBreakoutSession5SelectionChange}
                                            breakoutSessionSelection={this.state.breakoutSession5Selection}
                                        />
                                        <br />
                                        <br />
                                        <div className={styles.buttonArea}>
                                            <Button
                                                onClick={this.nextPage1}
                                                className={`${"btn btn-success"} ${styles.bottomRightButtons}`}
                                            >
                                                Next
                                            </Button>

                                            <Button
                                                onClick={this.saveAndExit}
                                                className={`${"btn btn-primary"} ${styles.bottomRightButtons}`}
                                            >
                                                Save & exit
                                            </Button>
                                            <Button
                                                onClick={this.closeWithoutSave}
                                                className={`${"btn btn-primary"} ${styles.bottomRightButtons}`}
                                            >
                                                Close without saving
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}

                                {/*Page 2 */}
                                {this.state.page2Active ? (
                                    <div className={styles.pageContentPanel}>
                                        <p className={styles.subheading}>Tuesday 2nd December 2021</p>

                                        <br />
                                        <UserSessionOptionsBreakouts5
                                            {...this.state}
                                            handleBreakoutSessionSelectionChange={this.handleBreakoutSession5SelectionChange}
                                            breakoutSessionSelection={this.state.breakoutSession5Selection}
                                        />
                                        <br />
                                        <UserSessionOptionsBreakouts6
                                            {...this.state}
                                            handleBreakoutSessionSelectionChange={this.handleBreakoutSession6SelectionChange}
                                            breakoutSessionSelection={this.state.breakoutSession6Selection}
                                        />
                                        <br />
                                        <UserSessionOptionsBreakouts7
                                            {...this.state}
                                            handleBreakoutSessionSelectionChange={this.handleBreakoutSession7SelectionChange}
                                            breakoutSessionSelection={this.state.breakoutSession7Selection}
                                        />
                                        <br />
                                        <UserSessionOptionsBreakouts8
                                            {...this.state}
                                            handleBreakoutSessionSelectionChange={this.handleBreakoutSession8SelectionChange}
                                            breakoutSessionSelection={this.state.breakoutSession8Selection}
                                        />
                                        <br />
                                        <br />
                                        <div className={styles.buttonArea}>
                                            <Button
                                                onClick={this.nextPage2}
                                                className={`${"btn btn-success"} ${styles.bottomRightButtons}`}
                                            >
                                                Next
                                            </Button>
                                            <Button
                                                onClick={this.saveAndExit}
                                                className={`${"btn btn-primary"} ${styles.bottomRightButtons}`}
                                            >
                                                Save & exit
                                            </Button>
                                            <Button
                                                onClick={this.closeWithoutSave}
                                                className={`${"btn btn-primary"} ${styles.bottomRightButtons}`}
                                            >
                                                Close without saving
                                            </Button>
                                            <Button
                                                onClick={this.previousPage2}
                                                className={`${"btn btn-success"} ${styles.bottomRightButtons}`}
                                            >
                                                Previous
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}



                                {/*Page 7*/}
                                {this.state.page7Active ? (
                                    <div className={styles.pageContentPanel}>
                                        <p className={styles.smallSubheading}>Session selection complete!</p>
                                        <br /><br /><br />
                                        <div className={styles.buttonArea}>
                                            <Button
                                                onClick={this.saveAndExit}
                                                className={`${"btn btn-success"} ${styles.bottomRightButtons}`}
                                            >
                                                Exit
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}