import React, { Component } from "react";
import { Link } from "react-router-dom";
import styles from "./AdminMenu.module.scss";

export default class Home extends Component {
  render () {
    return (
      <div className={styles.background}>
        <div className={styles.adminMenuOptions}>
          <h1>Admin Menu</h1>
          <br /> <br />
          <h2>
            <Link to="/userList">User List</Link>
            <br />
            <br />
            <Link to="/remoteControl">Remote Control</Link>
            <br />
            <br />
            <Link to="/liveStreamAdminList">Live Stream Admin</Link>
            <br />
            <br />
            <Link to="/videoAdminList">Video Upload Admin</Link>
            <br />
            <br />
          </h2>
        </div>
      </div>
    );
  }
}