import React, { Component } from "react";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import UserProfile from "./../userAccount/UserProfile";
import UserSessions from "./../userAccount/UserSessions";
import UserSessionBooking from "./../userAccount/UserSessionBooking";
import { Image, Transformation } from "cloudinary-react";
import CornerMenu from "./../cornerMenu/CornerMenu";
import styles from "./TopMenu.module.scss";

export default class TopMenu extends Component {

  // Keep function here as we do want two actions so as to reload user profile changes throughout site
  handleCloseProfileModal = () => {
    this.props.setShowProfileModal(false);
    this.props.reloadUserProfile();
  };

  handleCloseSessionSelectionModal = () => {
    this.props.setShowSessionSelectionModal(false);
    this.props.reloadUserProfile();
  };

  handleCloseSessionBookingModal = () => {
    this.props.setShowSessionBookingModal(false);
    this.props.reloadUserProfile();
  };

  render() {
    const navBarVisibilityControl =
      this.props.topMenuStatus !== 0
        ? styles.styledNavBarDiv
        : styles.styledNavBarDivHidden;
    return (
      <div>
        <UserProfile
          showProfileModal={this.props.showProfileModal}
          closeProfileModal={this.handleCloseProfileModal}
          setLanguage={this.props.setLanguage}
        />
        <UserSessions
          showSessionSelectionModal={this.props.showSessionSelectionModal}
          closeSessionSelectionModal={this.handleCloseSessionSelectionModal}
        />
        <UserSessionBooking
          showSessionBookingModal={this.props.showSessionBookingModal}
          closeSessionBookingModal={this.handleCloseSessionBookingModal}
        />
        <nav aria-label="Top Menu">
          <h1 className={styles.accessibleHeading}>Arm Big Picture</h1>
          {/* Display Corner Menu for logged in users */}
          {this.props.topMenuStatus !== 0 &&
            !window.location.href.includes("login") &&
            !window.location.href.includes("ssoLogin") &&
            !window.location.href.includes("userList") &&
            !window.location.href.includes("analytics") &&
            !window.location.href.includes("exhibitionAdminList") &&
            !window.location.href.includes("editExhibitionStand") &&
            !window.location.href.includes("liveStreamAdminList") &&
            !window.location.href.includes("viewLiveStream") &&
            !window.location.href.includes("createLiveStream") &&
            !window.location.href.includes("holding") &&
            !window.location.href.includes("zoomBreakout") &&
            !window.location.href.includes("register") ? (
            <CornerMenu
              userProfile={this.props.userProfile}
              reloadUserProfile={this.props.reloadUserProfile}
              setShowSessionBookingModal={this.props.setShowSessionBookingModal}
              showAgendaModal={this.props.showAgendaModal}
              setShowAgendaModal={this.props.setShowAgendaModal}
              setShowProfileModal={this.props.setShowProfileModal}
              showHelpModal={this.props.showHelpModal}
              setShowHelpModal={this.props.setShowHelpModal}
              showLeaderboardModal={this.props.showLeaderboardModal}
              setShowLeaderboardModal={this.props.setShowLeaderboardModal}
              cornerMenuStatus={this.props.cornerMenuStatus}
              language={this.props.language}
              cookies={this.props.cookies}
            />
          ) : null}
          {!window.location.href.includes("login") &&
            !window.location.href.includes("ssoLogin") &&
            !window.location.href.includes("holding") &&
            !window.location.href.includes("onDemandPre") &&
            !window.location.href.includes("zoomBreakout") &&
            !window.location.href.includes("register") ? (
            <div className={navBarVisibilityControl}
              style={{ backgroundImage: "url(" + window.$videoPath + "visualAssets/" + this.props.language + "/topNavBarBackground.png)" }}>
              <Navbar expand="xl" className={styles.styledNavbar}>
                <Link to="/lobby" disabled={this.props.topMenuStatus === 2}>
                  {!this.props.showMobile ?
                    <img
                      className={styles.navBarTopLeftIcon}
                      src={window.$videoPath + "logoFromDark.png"}
                      alt="Logo"
                    /> : null}
                </Link>
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  className={styles.navBarToggle}
                />
                <Navbar.Collapse
                  id="basic-navbar-nav"
                  className={styles.navBarCollapse}
                >
                  <Nav className={styles.styledDropdown}>
                    <NavDropdown
                      className={styles.styledDropdownTitle}
                      title={"NAVIGATE"}
                    >
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        as={Link}
                        to="/lobby"
                      >
                        LOBBY
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        as={Link}
                        to="/plenary"
                      >
                        TALKS
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        as={Link}
                        to="/networking"
                      >
                        DECK
                      </NavDropdown.Item>

                    </NavDropdown>
                    <Nav.Link
                      className={styles.styledNavItem}
                      disabled={this.props.topMenuStatus === 2}
                      as={Link}
                      to="/gallery"
                    >
                      ATTENDEES
                    </Nav.Link>
                    <Nav.Link
                      className={styles.styledNavItem}
                      disabled={this.props.topMenuStatus === 2}
                      as={Link}
                      to="/onDemand"
                    >
                      ON-DEMAND
                    </Nav.Link>
                    <Nav.Link
                      className={styles.styledNavItem}
                      disabled={this.props.topMenuStatus === 2}
                      as={Link}
                      to="/presentersGallery"
                    >
                      SPEAKERS
                    </Nav.Link>
                    <Nav.Link
                      className={styles.styledNavItem}
                      onClick={() => this.props.setShowAgendaModal(true)}
                    >
                      AGENDA
                    </Nav.Link>
                    <Nav.Link
                      className={styles.styledNavItem}
                      disabled={this.props.topMenuStatus === 2}
                      onClick={() => this.props.setShowProfileModal(true)}
                    >
                      MY PROFILE
                    </Nav.Link>
                    {/**<NavDropdown
                      className={styles.styledDropdownTitle}
                      title="MY BEEHIVE "
                    >
                      this.props.userProfile && this.props.userProfile.userAgeBracket > 1 ?
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        as={Link}
                        to="/meetings"
                      >
                        MY MEETINGS
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        onClick={() => this.props.setShowProfileModal(true)}
                      >
                        MY PROFILE
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        onClick={() => this.props.setShowSessionSelectionModal(true)}
                      >
                        MY SESSION SELECTIONS
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className={styles.styledDropdownNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        onClick={() => this.props.setShowSessionBookingModal(true)}
                      >
                        MY ROUNDTABLE SELECTIONS
                      </NavDropdown.Item>
                    </NavDropdown>
                    */}
                    <Nav.Link
                      className={styles.styledNavItem}
                      onClick={() => this.props.setShowHelpModal(true)}
                    >
                      HELP
                    </Nav.Link>
                    {this.props.adminStatus ? (
                      <Nav.Link
                        className={styles.styledNavItem}
                        as={Link}
                        to="/adminMenu"
                      >
                        ADMIN MENU
                      </Nav.Link>
                    ) : null}
                    {this.props.showMobile ? (
                      <Nav.Link
                        className={styles.styledNavItem}
                        as={Link}
                        to="/logout"
                      >
                        LOGOUT
                      </Nav.Link>
                    ) : null}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              {/**
                <Navbar expand="xl" className={styles.styledNavbar}>
                  <Link to="/lobby" disabled={this.props.topMenuStatus === 2}>
                    <img
                      className={styles.navBarTopLeftIcon}
                      src={window.$videoPath + "logoFromDark.png"}
                      alt="Logo"
                    />
                  </Link>
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    className={styles.navBarToggle}
                  />
                  <Navbar.Collapse
                    id="basic-navbar-nav"
                    className={styles.navBarCollapse}
                  >
                    <Nav className={styles.styledDropdown}>
                      <NavDropdown
                        className={styles.styledDropdownTitle}
                        title="Navigation"
                      >
                        <NavDropdown.Item
                          className={styles.styledDropdownNavItem}
                          disabled={this.props.topMenuStatus === 2}
                          as={Link}
                          to="/lobby"
                        >
                          Lobby
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className={styles.styledDropdownNavItem}
                          disabled={this.props.topMenuStatus === 2}
                          as={Link}
                          to="/plenary"
                        >
                          Salle Plénière
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className={styles.styledDropdownNavItem}
                          disabled={this.props.topMenuStatus === 2}
                          as={Link}
                          to="/posters"
                        >
                          Galerie d'Affiches
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className={styles.styledDropdownNavItem}
                          disabled={this.props.topMenuStatus === 2}
                          as={Link}
                          to="/breakouts"
                        >
                          Séminaires
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className={styles.styledDropdownNavItem}
                          disabled={this.props.topMenuStatus === 2}
                          as={Link}
                          to="/networking"
                        >
                          Le Networking
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className={styles.styledDropdownNavItem}
                          disabled={this.props.topMenuStatus === 2}
                          as={Link}
                          to="/exhibition"
                        >
                          Expo
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className={styles.styledDropdownNavItem}
                          disabled={this.props.topMenuStatus === 2}
                          as={Link}
                          to="/lobby2"
                        >
                          Lobby 2D
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className={styles.styledDropdownNavItem}
                          disabled={this.props.topMenuStatus === 2}
                          as={Link}
                          to="/lobby3"
                        >
                          Sanctuaire
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className={styles.styledDropdownNavItem}
                          disabled={this.props.topMenuStatus === 2}
                          as={Link}
                          to="/lobby4"
                        >
                          WAREHOUSE LOBBY
                        </NavDropdown.Item>
                      </NavDropdown>
                      <Nav.Link
                        className={styles.styledNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        as={Link}
                        to="/gallery"
                      >
                        Participants
                      </Nav.Link>
                      <Nav.Link
                        className={styles.styledNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        as={Link}
                        to="/presentersGallery"
                      >
                        Conferenciers
                      </Nav.Link>
                      <Nav.Link
                        className={styles.styledNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        as={Link}
                        to="/meetings"
                      >
                        Mes Reunions
                      </Nav.Link>
                      <Nav.Link
                        className={styles.styledNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        as={Link}
                        to="/ondemand"
                      >
                        OnDemand
                      </Nav.Link>
                      <Nav.Link
                        className={styles.styledNavItem}
                        disabled={this.props.topMenuStatus === 2}
                        onClick={() => this.setShowProfileModal(true)}
                      >
                        Mon Profil
                      </Nav.Link>
                      <Nav.Link
                        className={styles.styledNavItem}
                        onClick={() => this.props.setShowHelpModal(true)}
                      >
                        Aide
                      </Nav.Link>
                      {this.props.adminStatus ? (
                        <Nav.Link
                          className={styles.styledNavItem}
                          as={Link}
                          to="/adminMenu"
                        >
                          ADMIN MENU
                        </Nav.Link>
                      ) : null}
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>*/}
              <div className={styles.userProfileArea}>
                {this.props.userProfile &&
                  this.props.userProfile.userProfilePictureID ? (
                  <div className={styles.userProfilePictureArea}>
                    <img
                      className={styles.userProfilePictureBackground}
                      src={window.$videoPath + "icons/profilePictureBackground.png"}
                      alt="Detail"
                    />
                    <Image
                      alt="User Profile Image"
                      className={styles.userProfilePicture}
                      cloudName="oijqec973f1nf"
                      publicId={
                        this.props.userProfile.userProfilePictureID + ".png"
                      }
                      width="50"
                      secure={true}
                    >
                      <Transformation
                        height="50"
                        width="50"
                        gravity="face"
                        radius="max"
                        crop="fill"
                      />
                    </Image>
                  </div>
                ) : null}
                <p className={styles.userName}>
                  {this.props.userProfile &&
                    this.props.userProfile.firstName.length < 12 ? (
                    <span>Hi {this.props.userProfile.firstName}</span>
                  ) : null}
                  <br />
                  <Link className={styles.logout} to="/logout">
                    <button className={styles.logoutButton}>Logout</button>
                  </Link>
                  {/**<Link className={styles.logout} to="/logout">
                      Se Déconnecter
                    </Link>*/}
                </p>
              </div>
            </div>
          ) : null}
        </nav>
      </div>
    );
  }
}
