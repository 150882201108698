import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { dateFormatterTimeOnly } from "../../helpers/dateFormatter.js"
import styles from "./AgendaRow.module.scss";


export default class AgendaRow extends Component {

    getBreakoutURL = (breakoutRoomNumber) => {
        switch (breakoutRoomNumber) {
            case 1:
                return "/breakout/6086abd759b00d4eefcc69b1";
            case 2:
                return "/breakout/6086abb259b00d4eefcc69b0";
            case 11:
                return "/roundtables";
            case 12:
                return "/roundtables";
            default:
                return "";
        }
    }

    getBreakoutLocationName = (breakoutRoomNumber) => {
        switch (breakoutRoomNumber) {
            case 1:
                return "Breakout 1";
            case 2:
                return "Breakout 2";
            case 11:
                return "Roundtables";
            case 12:
                return "Roundtables";
            default:
                return "";
        }
    }

    getSelectedSessionDetails = (sessionSelection) => {
        switch (sessionSelection) {
            case 1:
                return {
                    sessionTitle: "Sustainable Events",
                    presenters: ["Presenter 1 TBC", "Presenter 2 TBC", "Presenter 3 TBC"],
                    locationName: this.getBreakoutLocationName(11),
                    location: this.getBreakoutURL(11)
                };
            case 2:
                return {
                    sessionTitle: "Importance of CSR",
                    presenters: [],
                    locationName: this.getBreakoutLocationName(12),
                    location: this.getBreakoutURL(12)
                };
            case 11:
                return {
                    sessionTitle: "Making the most out of your Breakouts",
                    presenters: ["Issy FitzGerald-Smith"],
                    locationName: this.getBreakoutLocationName(1),
                    location: this.getBreakoutURL(1)
                };
            case 12:
                return {
                    sessionTitle: "Widgets to enhance your attendee experience",
                    presenters: ["Olivia Falkner-Lee"],
                    locationName: this.getBreakoutLocationName(2),
                    location: this.getBreakoutURL(2)
                };
            case 13:
                return {
                    sessionTitle: "Bringing your imagination to life",
                    presenters: ["Conor Howell-Harte"],
                    locationName: this.getBreakoutLocationName(3),
                    location: this.getBreakoutURL(3)
                };

            default:
                return {
                    sessionTitle: "Optional Session",
                    presenters: ["Presenter Name"],
                    locationName: this.getBreakoutLocationName(0),
                    location: this.getBreakoutURL(0)
                };
        }

    };

    render () {

        return (
            <React.Fragment>
                <div className={styles.visibleOnlyOnMobile}>
                    <div className={styles.mobileAgendaItem}>

                        {/** Case for fixed session */}
                        {this.props.agendaItemDetails.agendaItemTitle ?
                            <>
                                <div className={styles.sideBorderArea} />
                                <div className={styles.agendaRowBlock}>
                                    {this.props.agendaItemDetails.agendaItemTitle != "In-Person Networking Lunch" ?
                                        <Link
                                            to={this.props.agendaItemDetails.agendaItemLocation}
                                            onClick={() => this.props.setShowAgendaModal(false)}
                                        >
                                            <p className={styles.sessionTimeText}>{dateFormatterTimeOnly(this.props.agendaItemDetails.agendaItemTimestamp, this.props.userProfile)}</p>
                                            <p className={styles.sessionTitleText}>{this.props.agendaItemDetails.agendaItemTitle}</p>
                                            {
                                                this.props.agendaItemDetails.agendaItemPresenters.map((host) => {
                                                    return (
                                                        <p className={styles.sessionDetailText} key={host}>
                                                            - {host}
                                                        </p>
                                                    )
                                                }
                                                )
                                            }
                                            <p className={styles.sessionLocationText}>
                                                {this.props.agendaItemDetails.agendaItemLocationText}
                                            </p>

                                        </Link>
                                        :
                                        <Link
                                            to={this.props.agendaItemDetails.agendaItemLocation}
                                            onClick={() => this.props.setShowLeaderboardModal(true)}
                                        >
                                            <p className={styles.sessionTimeText}>{dateFormatterTimeOnly(this.props.agendaItemDetails.agendaItemTimestamp, this.props.userProfile)}</p>
                                            <p className={styles.sessionTitleText}>{this.props.agendaItemDetails.agendaItemTitle}</p>
                                            {
                                                this.props.agendaItemDetails.agendaItemPresenters.map((host) => {
                                                    return (
                                                        <p className={styles.sessionDetailText} key={host}>
                                                            - {host}
                                                        </p>
                                                    )
                                                }
                                                )
                                            }
                                            <p className={styles.sessionLocationText}>
                                                {this.props.userProfile.country == "Austin" ? <span>Training Room A</span> : null}
                                                {this.props.userProfile.country == "San Jose" ? <span>Sycamore</span> : null}
                                                {this.props.userProfile.country == "Cambridge" ? <span>ABCD Lecture Theatre (Auditorium)</span> : null}
                                                {this.props.userProfile.country == "Manchester" ? <span>Bridge A&B</span> : null}
                                                {this.props.userProfile.country == "Sophia" ? <span>Auditorium C</span> : null}
                                                {this.props.userProfile.country == "Bangalore" ? <span>Ganga A & B</span> : null}
                                            </p>

                                        </Link>
                                    }
                                </div>
                            </> : null}

                        {/** Case for selectable session */}
                        {this.props.agendaItemDetails.agendaItemSessionSelector != null ?
                            <>
                                <div className={styles.sideBorderArea} />
                                {this.props.agendaItemDetails.agendaItemSessionSelector === 0 ?
                                    /** Case for no session selected */
                                    <div className={styles.agendaRowBlock}
                                        onClick={() => this.props.switchFromAgendaToSessionSelection()}>
                                        <p className={styles.sessionTimeText}>{dateFormatterTimeOnly(this.props.agendaItemDetails.agendaItemTimestamp, this.props.userProfile)}</p>
                                        <p className={styles.sessionTitleText}>
                                            Visit 'Plan my day' to select your sessions
                                        </p>
                                    </div> :
                                    /** Case for session selected */

                                    <div className={styles.agendaRowBlock}>
                                        <Link
                                            to={this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).location}
                                            onClick={() => this.props.setShowAgendaModal(false)}
                                        ></Link>
                                        <p className={styles.sessionTimeText}>{dateFormatterTimeOnly(this.props.agendaItemDetails.agendaItemTimestamp, this.props.userProfile)}</p>
                                        <p className={styles.sessionTitleText}>
                                            {this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).sessionTitle}
                                        </p>
                                        {/** List presenters */}
                                        {
                                            this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).presenters.map((host) => {
                                                return (
                                                    <p className={styles.sessionDetailText} key={host}>
                                                        {host}
                                                    </p>
                                                )
                                            }
                                            )
                                        }
                                        {/** Location text */}
                                        <p className={styles.sessionLocationText}>{this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).locationName}</p>

                                    </div>

                                }</>
                            : null}

                        {/** Case for 1:1 meeting */}
                        {this.props.agendaItemDetails.meetingSlotID ?
                            <>
                                <div className={styles.sideBorderArea} />
                                <div className={styles.agendaRowBlock} style={{ cursor: "default" }}>
                                    <p className={styles.sessionTimeText}>{dateFormatterTimeOnly(this.props.agendaItemDetails.meetingSlotID.meetingSlotTimestamp, this.props.userProfile)}</p>
                                    <p className={styles.sessionTitleText}>
                                        1:1 Meeting with {this.props.agendaItemDetails.meetingRequesteeUser.firstName}{" "}
                                        {this.props.agendaItemDetails.meetingRequesteeUser.surname}
                                    </p>
                                    <p className={styles.sessionLocationText}>Please access the platform from a desktop or laptop to join this meeting</p>
                                </div>
                            </>
                            : null}
                    </div>
                </div>


                <div className={styles.visibleOnlyOnDesktop}>
                    {/** Case for fixed session */}
                    {this.props.agendaItemDetails.agendaItemTitle ?
                        <div className={styles.desktopAgendaItem}>
                            <div className={styles.sideBorderArea} />
                            <div className={styles.timeAndTitleArea}>
                                <p>
                                    <span className={styles.sessionTimeText}>From {dateFormatterTimeOnly(this.props.agendaItemDetails.agendaItemTimestamp, this.props.userProfile)} </span>
                                    <span className={styles.sessionLocationText}>
                                        {this.props.agendaItemDetails.agendaItemLocationText && this.props.agendaItemDetails.agendaItemLocationText.length > 0 && this.props.agendaItemDetails.agendaItemTitle != "In-Person Networking Lunch" ?
                                            <Link
                                                className={styles.uncolouredLink}
                                                to={this.props.agendaItemDetails.agendaItemLocation}
                                                onClick={() => this.props.setShowAgendaModal(false)}
                                            >
                                                -{" "}{this.props.agendaItemDetails.agendaItemLocationText}
                                            </Link>
                                            : null}
                                        {this.props.agendaItemDetails.agendaItemTitle == "In-Person Networking Lunch" ?
                                            <Link
                                                className={styles.uncolouredLink}
                                                onClick={() => {
                                                    this.props.setShowLeaderboardModal(true)
                                                    this.props.setShowAgendaModal(false)
                                                }}
                                            >
                                                -{" "}
                                                {this.props.userProfile.country == "Austin" ? <span>Training Room A</span> : null}
                                                {this.props.userProfile.country == "San Jose" ? <span>Sycamore</span> : null}
                                                {this.props.userProfile.country == "Cambridge" ? <span>ABCD Lecture Theatre (Auditorium)</span> : null}
                                                {this.props.userProfile.country == "Manchester" ? <span>Bridge A&B</span> : null}
                                                {this.props.userProfile.country == "Sophia" ? <span>Auditorium C</span> : null}
                                                {this.props.userProfile.country == "Bangalore" ? <span>Ganga A & B</span> : null}
                                            </Link>
                                            : null}
                                    </span>
                                </p>
                                <Link
                                    className={styles.uncolouredLink}
                                    to={this.props.agendaItemDetails.agendaItemLocation}
                                    onClick={() => this.props.setShowAgendaModal(false)}
                                >
                                    <p className={styles.sessionTitleText}>{this.props.agendaItemDetails.agendaItemTitle}</p>
                                </Link>
                            </div>
                            <div className={styles.spacerArea} />
                            <div className={styles.presenterArea}>
                                {this.props.agendaItemDetails.agendaItemPresenters.map((host) => {
                                    return (
                                        <p className={styles.sessionDetailText} key={host}>
                                            {host}
                                        </p>
                                    )
                                }
                                )}
                            </div>
                            <div className={styles.linkArea}>
                                <p className={styles.sessionLocationText}>
                                    {this.props.agendaItemDetails.agendaItemLocationText && this.props.agendaItemDetails.agendaItemLocationText.length > 0 && this.props.agendaItemDetails.agendaItemTitle != "In-Person Networking Lunch" ?
                                        <Link
                                            className={styles.uncolouredLink}
                                            to={this.props.agendaItemDetails.agendaItemLocation}
                                            onClick={() => this.props.setShowAgendaModal(false)}
                                        >
                                            <Button variant="success" style={{ borderRadius: '100%', width: "50px", height: "50px" }}>
                                                GO
                                            </Button>
                                        </Link>
                                        : null}
                                    {this.props.agendaItemDetails.agendaItemTitle == "In-Person Networking Lunch" ?
                                        <Link
                                            className={styles.uncolouredLink}
                                            onClick={() => {
                                                this.props.setShowAgendaModal(false)
                                                this.props.setShowLeaderboardModal(true)
                                            }
                                            }
                                        >
                                            <Button variant="success" style={{ borderRadius: '100%', width: "50px", height: "50px" }}>
                                                GO
                                            </Button>
                                        </Link>
                                        : null}
                                </p>
                            </div>
                        </div> : null}



                    {/** Case for selectable session but no session selected*/}
                    {this.props.agendaItemDetails.agendaItemSessionSelector != null && this.props.agendaItemDetails.agendaItemSessionSelector === 0 ?
                        <div className={styles.desktopAgendaItem}>
                            <div className={styles.sideBorderArea} />
                            <div className={styles.timeAndTitleArea}>
                                <p>
                                    <span className={styles.sessionTimeText}>From {dateFormatterTimeOnly(this.props.agendaItemDetails.agendaItemTimestamp, this.props.userProfile)} -{" "}</span>
                                    <span className={styles.sessionLocationText}>
                                        Selectable session
                                    </span>
                                </p>
                                <p className={styles.sessionTitleText}>
                                    <span style={{ cursor: "pointer" }} onClick={() => this.props.switchFromAgendaToSessionSelection()}>
                                        <p className={styles.sessionTitleText}>
                                            Visit 'Plan my day' to select your sessions
                                        </p>
                                    </span>
                                </p>
                            </div>
                            <div className={styles.spacerArea} />
                            <div className={styles.presenterArea}>

                            </div>
                            <div className={styles.linkArea}>
                                <p className={styles.sessionLocationText}>

                                </p>
                            </div>
                        </div>
                        : null}


                    {/** Case for selectable session WITH session selected*/}
                    {this.props.agendaItemDetails.agendaItemSessionSelector != null && this.props.agendaItemDetails.agendaItemSessionSelector === 1 ?
                        <div className={styles.desktopAgendaItem}>
                            <div className={styles.sideBorderArea} />
                            <div className={styles.timeAndTitleArea}>
                                <p>
                                    <span className={styles.sessionTimeText}>From {dateFormatterTimeOnly(this.props.agendaItemDetails.agendaItemTimestamp, this.props.userProfile)} -{" "}</span>
                                    <span className={styles.sessionLocationText}>
                                        <Link
                                            className={styles.uncolouredLink}
                                            to={this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).location}
                                            onClick={() => this.props.setShowAgendaModal(false)}
                                        >
                                            {this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).locationName && this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).locationName.length > 0 ?
                                                <span>
                                                    {this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).locationName}
                                                </span>
                                                : null}
                                        </Link>
                                    </span>
                                </p>
                                <p className={styles.sessionTitleText}>
                                    {this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).sessionTitle}
                                </p>
                            </div>
                            <div className={styles.spacerArea} />
                            <div className={styles.presenterArea}>
                                {this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).presenters.map((host) => {
                                    return (
                                        <p className={styles.sessionDetailText} key={host}>
                                            {host}
                                        </p>
                                    )
                                }
                                )
                                }
                            </div>
                            <div className={styles.linkArea}>
                                <p className={styles.sessionLocationText}>
                                    <Link
                                        className={styles.uncolouredLink}
                                        to={this.getSelectedSessionDetails(this.props.agendaItemDetails.agendaItemSessionSelector).location}
                                        onClick={() => this.props.setShowAgendaModal(false)}
                                    >
                                        <Button variant="success" style={{ borderRadius: '100%', width: "50px", height: "50px" }}>
                                            GO
                                        </Button>
                                    </Link>
                                </p>
                            </div>
                        </div> : null /** Else for session selector set so must be selectable session */}




                    {/** Case for 1:1 session */}
                    {this.props.agendaItemDetails.meetingSlotID ?
                        <div className={styles.desktopAgendaItem}>
                            <div className={styles.sideBorderArea} />
                            <div className={styles.timeAndTitleArea}>
                                <p>
                                    <span className={styles.sessionTimeText}>{dateFormatterTimeOnly(this.props.agendaItemDetails.meetingSlotID.meetingSlotTimestamp, this.props.userProfile)} -{" "}</span>
                                    <span className={styles.sessionLocationText}>
                                        <Link
                                            className={styles.uncolouredLink}
                                            to={"/video/" + this.props.agendaItemDetails._id}
                                            onClick={() => this.props.setShowAgendaModal(false)}
                                        >
                                            Join Meeting
                                        </Link>
                                    </span>
                                </p>
                                <p className={styles.sessionTitleText}>

                                    1:1 Meeting with {this.props.agendaItemDetails.meetingRequesteeUser.firstName}{" "}
                                    {this.props.agendaItemDetails.meetingRequesteeUser.surname}
                                </p>
                            </div>
                            <div className={styles.spacerArea} />
                            <div className={styles.presenterArea}></div>
                            <div className={styles.linkArea}>
                                <p className={styles.sessionLocationText}>
                                    <Link
                                        className={styles.uncolouredLink}
                                        to={"/video/" + this.props.agendaItemDetails._id}
                                        onClick={() => this.props.setShowAgendaModal(false)}
                                    >
                                        <Button variant="success" style={{ borderRadius: '100%', width: "50px", height: "50px" }}>
                                            GO
                                        </Button>
                                    </Link>
                                </p>
                            </div>
                        </div> : null}












                </div>

            </React.Fragment >
        );
    }
}