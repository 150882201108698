import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import VideoPlayer from "../modules/videoPlayer/VideoPlayer"
import { Link } from "react-router-dom";
import OnDemandModal from "./OnDemandModal";
import { Image, Transformation } from "cloudinary-react";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./VisualOnDemand.module.scss";

export default class VisualOnDemand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      introVideoComplete: false,
      onDemandContent: [],
      selectedOnDemand: null,
      showOnDemandModal: false,
    };
  }

  componentDidMount () {
    requestPoints("visitOnDemandArea", "visitOnDemandArea4");

    this.loadCurrentDatabaseContent();

    // Start timeout in case video does not load
    setTimeout(
      function () {
        this.setState({ introVideoComplete: true });
      }.bind(this),
      6000
    );
  }

  loadCurrentDatabaseContent () {
    fetch("/api/getOnDemandContent")
      .then((res) => res.json())
      .then((data) => this.setState({ onDemandContent: data }));
  }


  onDemandFilteredByDay = (selectedDay) => {
    return this.state.onDemandContent.filter(
      (contentItem) => contentItem.day === selectedDay
    );
  };

  showOnDemand (onDemand) {
    requestPoints("viewOnDemandItem", onDemand._id);
    this.setState({ selectedOnDemand: onDemand, showOnDemandModal: true });
  }

  onDemandGallery = (selectedDay) => {
    return this.onDemandFilteredByDay(selectedDay).map((contentItem) => {
      return (
        <div
          key={contentItem._id}
          className={styles.contentItem}
          onClick={() => this.showOnDemand(contentItem)}
        >
          <div className={styles.contentVideoArea}>
            {contentItem.pdfURL ?
              (
                contentItem.pdfThumbnailID ?
                  <Image
                    cloudName="oijqec973f1nf"
                    publicId={contentItem.pdfThumbnailID}
                    width="160"
                    height="90"
                    secure={true}
                  >
                    <Transformation
                      height="90"
                      width="160"
                      crop="pad"
                    />
                  </Image> :
                  <img src={window.$videoPath + "icons/webLink.png"} className={styles.pdfIcon} alt="Web Link Icon" />
              )
              : (contentItem.videoID ? (
                <div className={styles.onDemandVideoDiv}>
                  <VideoPlayer
                    resourceLocator={contentItem.videoID}
                    location={"onDemand"}
                    userProfile={this.props.userProfile}
                  />
                </div>
              ) : null
              )}

          </div>
          <div className={styles.contentInfoArea}>
            <h1 className={styles.contentTitle}>{contentItem.contentTitle}</h1>
            <p className={styles.speakerName}>Presenter: {contentItem.speakerName}<br /><strong>Read More...</strong></p>
          </div>
        </div>
      );
    });
  };

  render () {
    return (
      <div>
        <div className="imageNavDiv">
          <video
            className="imageNavBackground"
            autoPlay
            muted
            id="introVideo"
            onEnded={() => this.setState({ introVideoComplete: true })}
          >
            <source
              src={window.$videoPath + "visuals/" + this.props.language + "/onDemand.webm"}
              type="video/webm"
            />
            <source
              src={window.$videoPath + "visuals/" + this.props.language + "/onDemand.mp4"}
              type="video/mp4"
            />
          </video>

          <CSSTransition
            in={this.state.introVideoComplete}
            timeout={1500}
            classNames="imageNavBackground"
            unmountOnExit
          >
            <div>
              <img
                className="imageNavBackground"
                src={window.$videoPath + "visuals/" + this.props.language + "/onDemand.jpg"}
                alt="Background"
              ></img>

              <Link to="/lobby4">
                <img
                  className={styles.exitHoverHotspot}
                  src="/hoverShapes/hoverSquare.png"
                  alt="Exit"
                ></img>
              </Link>

              <div className={styles.titleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
                <h1 className={styles.pageTitle}>On-Demand</h1>
              </div>

              <section className={styles.onDemandItemsArea}>
                {this.onDemandGallery(1)}
              </section>
            </div>
          </CSSTransition>


          <OnDemandModal
            showOnDemandModal={this.state.showOnDemandModal}
            closeOnDemandModal={() => this.setState({ showOnDemandModal: false })}
            selectedOnDemand={this.state.selectedOnDemand}
            userProfile={this.props.userProfile}
          />

        </div>
      </div>
    );
  }
}
