import React, { Component } from "react";
import NotificationMobile from "./NotificationMobile";
import styles from "./Notification.module.scss";

export default class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationVisibilityStatus: styles.notificationNotVisible,
      playAudioNotification: false,
    };
  }

  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ notificationVisibilityStatus: styles.notificationVisible });
        if (
          this.props.notificationText.includes(
            "Visit the networking lounge to connect with other delegates or participate in roundtables"
          )
        ) {
          this.setState({
            playAudioNotification: true,
          });
        } else {
          this.setState({
            playAudioNotification: false,
          });
        }
      }.bind(this),
      1
    );
  }

  render() {
    return (
      <div>
        {this.props.showMobile ?
          <NotificationMobile
            setNotificationText={this.props.setNotificationText}
            notificationText={this.props.notificationText}
          />
          :
          <div className="imageNavDiv">
            <div className={this.state.notificationVisibilityStatus}>
              <img className={styles.notificationClose} src={window.$videoPath + "icons/close.png"} alt="Close" onClick={() => this.props.setNotificationText("")} />
              <img className={styles.notificationBellIcon} src={window.$videoPath + "icons/bell.png"} alt="Bell" />

              <div className={styles.notificationText}>
                {this.props.notificationText}
              </div>




              {this.state.playAudioNotification ? (
                <audio autoPlay>
                  <source
                    src={window.$videoPath + "notification/vog.mp3"}
                    type="audio/mp3"
                  />
                </audio>
              ) : null}
            </div>
          </div>
        }
      </div>
    );
  }
}
