import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import styles from "./LoginHelp.module.scss";
import preLoginStyles from "./Pre-Login.module.scss";

export default class LoginHelp extends Component {
  render() {
    return (
      <div className={styles.loginHelpModal}>
        <div className={styles.loginHelpModalInnerDiv}>
          <img
            className={preLoginStyles.preLoginBoxLogo}
            style={{ width: "30%" }}
            src={window.$videoPath + "logo.png"}
            alt="Logo"
          />
          <div className={styles.loginHelpTextArea}>
            <p>
              <strong>Help title</strong>
              <br />
              Help text here
              <br />
              <br />

            </p>
            <br />

            <button
              onClick={() => this.props.handleCloseLoginHelpDiv()}
              className={preLoginStyles.highlightedButton}
              style={{position: "absolute", top: "5%", right: "5%"}}
            >
              Back
            </button>
            <br />
          </div>
        </div>
      </div>
    );
  }
}
