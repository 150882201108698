import React, { Component } from "react";
import Modal from "react-modal";
import { requestPoints } from "../helpers/requestPoints.js";
import { determineWhetherUserIsHybrid } from "../helpers/determineWhetherUserIsHybrid.js";
import styles from "./Leaderboard.module.scss";

export default class Leaderboard extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      leaderboardContent: [],
      userPoints: 0,
    };
  }

  componentDidMount() {
    this.reloadData();
  }

  leaderboardModalOnAfterOpen = () => {
    requestPoints("other", "viewLeaderboard");
    this.reloadData();
  }

  reloadData = () => {
    // Get leaderboard
    fetch("/api/getLeaderboard")
      .then((res) => res.json())
      .then((data) => this.setState({ leaderboardContent: data }));
    // Get individual points
    fetch("/api/getUserProfile")
      .then((res) => res.json())
      .then((data) => this.setState({ userPoints: data.user.points }));
  };

  render() {
    const leaderboard = this.state.leaderboardContent.map((user, index) => {
      return (
        <tr key={user._id}>
          <td className={styles.nameCell}>
            {index + 1 + ")  " + user.firstName} {user.surname}
          </td>
          <td className={styles.pointsCell}>
            <div className={styles.numberCircle}> {user.points} </div>
          </td>
        </tr>
      );
    });

    return (
      <div>
        <Modal
          onAfterOpen={this.leaderboardModalOnAfterOpen}
          ariaHideApp={false}
          isOpen={this.props.showLeaderboardModal}
          onRequestClose={() => this.props.setShowLeaderboardModal(false)}
          contentLabel="Leaderboard Modal"
          overlayClassName={styles.modalOverlay}
          className={styles.modalContent}
          style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
        >
          <div className={styles.modalFlexboxColumn}>
            <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
              <h1 className={styles.modalPageTitle}>Hybrid Activity</h1>
              <button
                className={styles.modalClose}
                variant="success"
                onClick={() => this.props.setShowLeaderboardModal(false)}
              >
                Close
                <img
                  className={styles.modalCloseIcon}
                  src="/icons/close-no-circle.png"
                  alt="Close"
                />
              </button>
            </div>

            <div className={styles.scrollableArea}>
              {determineWhetherUserIsHybrid(this.props.userProfile) ?
                <h5 className={styles.leaderboardInfoText}>
                  <br />
                  While the majority of the conference will be conducted virtually, we are delighted to invite you to join an in-person networking lunch held at your office. We strongly encourage you to participate in the conference from your local office, rather than at home, so you can make the most of this networking session. It is an excellent opportunity to expand your professional network, connect with fellow attendees, and create new contacts. Check out the agenda for location details on where your lunch will be served.
                </h5>
                :
                <h5 className={styles.leaderboardInfoText}>
                  <br />
                  We would like you to take the opportunity to connect with other attendees during a Networking Lunch – either in person or virtually. Not only is this a good time to take a break from the virtual broadcast, but it’s a great way to make new contacts, interact with other attendees and build your network. Please check out the suggestions below on how to arrange this.<br /><br />
                  <ol>
                    <li>Visit the Attendee Gallery (once the full platform is open), reach out to other attendees from your office location and organize an in-person meet-up during the networking lunch session. This could be a quick 1:1 coffee or perhaps a group lunch.</li>
                    <li>If there aren’t many new joiners from your local office, please reach out to other members in your local team or perhaps your line manager, to arrange a team snack & chat. Again, this could be a quick 1:1 coffee or perhaps a group lunch.</li>
                    <li>If it is not plausible to visit your local office in person, please reach out to other attendees or team members for a virtual snack & chat over Teams or Zoom.</li>
                  </ol>
              
                  To help facilitate this, Arm will allow you to expense up to the value of $25 for food/refreshments. Please see below for expense instructions:<br /><br />
                  <ul>
                    <li>All receipts submitted for expenses will need to be dated on the day of the conference and have a limit of $25 maximum</li>
                    <li>All receipts will need to be submitted through Concur and labelled “Big Picture Networking Expenses”.</li>
                    <li>Please speak to your Group Finance Business Partner about reallocating these costs back to the cost centre: 0000100215 (UK01 PPL Int Events) and GL code: 0062210900 (Internal Meetings)</li>
                  </ul>
                </h5>
              }


            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
