import React, { Component } from "react";
import styles from "./CornerMenu.module.scss";
import Agenda from "./Agenda";
import { Link } from "react-router-dom";
import Leaderboard from "./Leaderboard";
import Help from "./Help";

export default class CornerMenu extends Component {
  render () {
    return (
      <div>
        <Agenda
          showAgendaModal={this.props.showAgendaModal}
          setShowAgendaModal={this.props.setShowAgendaModal}
          userProfile={this.props.userProfile}
          reloadUserProfile={this.props.reloadUserProfile}
          setShowSessionBookingModal={this.props.setShowSessionBookingModal}
          setShowLeaderboardModal={this.props.setShowLeaderboardModal}
        />

        <Leaderboard
          showLeaderboardModal={this.props.showLeaderboardModal}
          setShowLeaderboardModal={this.props.setShowLeaderboardModal}
          reloadUserProfile={this.props.reloadUserProfile}
          userProfile={this.props.userProfile}
        />

        <Help
          showHelpModal={this.props.showHelpModal}
          setShowHelpModal={this.props.setShowHelpModal}
          userProfile={this.props.userProfile}
          cookies={this.props.cookies}
        />
        {this.props.cornerMenuStatus === 1 && this.props.userProfile ? (
          <div className={styles.navBlockContainer}>
            <div
              onClick={() => this.props.setShowAgendaModal(true)}
              className={`${styles.navBlock} ${styles.agendaBlock}`}
              style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/' + this.props.userProfile.userLanguage + '/agenda.png)', }}
            ></div>
            <div
              onClick={() => this.props.setShowLeaderboardModal(true)}
              className={`${styles.navBlock} ${styles.leaderboardBlock}`}
              style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/' + this.props.userProfile.userLanguage + '/leaderboard.png)', }}
            ></div>

            <div
              onClick={() => this.props.setShowHelpModal(true)}
              className={`${styles.navBlock} ${styles.helpBlock}`}
              style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/' + this.props.userProfile.userLanguage + '/help.png)', }}
            ></div>
          </div>
        ) : null
        }
        {/** Alternative corner menu layout for tepee **/}
        {
          this.props.cornerMenuStatus === 2 ? (
            <div className={styles.stackedNavBlockContainer}>
              <div
                onClick={() => this.props.setShowAgendaModal(true)}
                className={`${styles.stackedNavBlock} ${styles.stackedAgendaBlock}`}
                style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/agenda.png)', }}
              ></div>
              <div
                onClick={() => this.props.setShowLeaderboardModal(true)}
                className={`${styles.stackedNavBlock} ${styles.stackedLeaderboardBlock}`}
                style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/leaderboard.png)', }}
              ></div>
              <div
                onClick={() => this.props.setShowHelpModal(true)}
                className={`${styles.stackedNavBlock} ${styles.stackedHelpBlock}`}
                style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/help.png)', }}
              ></div>
            </div>
          ) : null
        }

        {/**  Bottom Bar */}
        <div className={styles.visibleOnlyOnMobile}>
          <div className={styles.bottomBar}>
            <div className={styles.bottomBarDecorativeLine} />
            <div className={styles.bottomBarFlexContainer}>


              <div className={styles.bottomBarElement}>
                <Link to={"/"}>
                  <img src={window.$videoPath + "mobileAssets/bottomBarIcons/home.png"} className={styles.bottomBarIconImage} alt="Bottom bar icon" />
                  <h4 className={styles.bottomBarIconText}>Home</h4>
                </Link>
              </div>

              <div
                className={styles.bottomBarElement}
                onClick={() => this.props.setShowAgendaModal(true)}
              >
                <img src={window.$videoPath + "mobileAssets/bottomBarIcons/agenda.png"} className={styles.bottomBarIconImage} alt="Bottom bar icon" />
                <h4 className={styles.bottomBarIconText}>Agenda</h4>
              </div>
              <div
                className={`${styles.bottomBarElement} ${styles.hideOnNarrowScreen}`}
                onClick={() => this.props.setShowProfileModal(true)}>
                <img src={window.$videoPath + "mobileAssets/bottomBarIcons/profile.png"} className={styles.bottomBarIconImage} alt="Bottom bar icon" />
                <h4 className={styles.bottomBarIconText}>Profile</h4>
              </div>
              <div
                className={styles.bottomBarElement}
                onClick={() => this.props.setShowHelpModal(true)}
              >
                <img src={window.$videoPath + "mobileAssets/bottomBarIcons/help.png"} className={styles.bottomBarIconImage} alt="Bottom bar icon" />
                <h4 className={styles.bottomBarIconText}>Help</h4>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}
