import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Link, Redirect } from "react-router-dom";
import ChatFunction from "./../twilioChat/ChatFunction";
import VideoMobile from "./VideoMobile";
import $ from "jquery";
import styles from "./Video.module.scss";

//const { connect, LocalDataTrack } = require("twilio-video");
const { connect } = require("twilio-video");
const { createLocalVideoTrack } = require("twilio-video");

//const dataTrack = new LocalDataTrack();
var roomReference = null;

//var meetingRoomNameField = "default";
var videoCallID;

var localTrack;

// Whether video meeting or roundtable
// Set to true if meeting and not roundtable
var videoIsMeeting = true;

export default class Video extends Component {
  static defaultProps = { showButtons: true };
  constructor(props) {
    super(props);

    this.state = {
      remoteDivType: true,
      roomUserCount: 0,
      remoteParticipants: [],
      participantRealNames: [],
      redirectToMyMeetings: false,
    };

    if (this.props.autoConnect === true) {
      this.getRoomToken();
    }
  }

  componentDidMount () {
    // Scroll window to top on load
    $(window).on("load", function () {
      $(window).scrollTop(0);
    });
    // Get meeting room name from props.match - e.g. roomID is encoded into URL
    // Do not attempt to get this if no props.match e.g. component embedded
    if (this.props.match !== undefined) {
      const { meetingID } = this.props.match.params;
      console.log("Getting room name from props.match: " + meetingID);
      if (meetingID !== undefined) {
        videoCallID = meetingID;
        videoIsMeeting = true;
      }
    } else if (this.props.meetingRoomName !== undefined) {
      // If no match props supplied then instead check for props passed by other component
      // This is the case for a roundtable called by a roundtable session
      videoIsMeeting = false;
      videoCallID = this.props.meetingRoomName;
      console.log(
        "Getting room name from props.meetingRoomName: " +
        videoCallID +
        " - " +
        this.props.meetingRoomName
      );
    }

    console.log("Requested video meeting room: " + videoCallID);

    // Register to disconnect call if user navigates away from page
    window.onbeforeunload = () => {
      this.room.disconnect();
    };

    // Do not load preview if showing mobile version
    if (!this.props.showMobile) {
      this.previewVideo();
    }
  }

  componentWillUnmount () {
    this.disconnect();
  }

  loadUserRealNames () {
    // Source of participants and names depends on whether a meeting or roundtable
    if (videoIsMeeting) {
      fetch("/api/getMeeting?meetingID=" + videoCallID)
        .then((res) => res.json())
        .then((data) => this.processRealUserNames(data.participants));
    } else {
      fetch("/api/getRoundtable?roundtableID=" + videoCallID)
        .then((res) => res.json())
        .then((data) =>
          this.processRealUserNames(data.roundtable.participants)
        );
    }
  }

  processRealUserNames (participants) {
    // Copy remote participants array
    const remoteParticipants = this.state.remoteParticipants;
    // Create updated remoteParticipantsArray
    const remoteParticipantsWithRealNames = remoteParticipants.map(
      (remoteParticipant) => {
        var newRemoteParticipant = remoteParticipant;
        // Look in participants[] for _id which matches participant.identity
        const participantRealNameDetails = participants;
        // Set default value
        var matchingRecord = "Unknown";
        matchingRecord = participantRealNameDetails.filter(function (
          participantRealName
        ) {
          return participantRealName._id === remoteParticipant.identity;
        });

        // If matched then do below but with JSON field .firstName + .surname
        // Filter returns array but we only expect 1 match
        newRemoteParticipant.displayName =
          matchingRecord[0].firstName + " " + matchingRecord[0].surname;
        return newRemoteParticipant;
      }
    );
    this.setState({
      remoteParticipants: remoteParticipantsWithRealNames,
    });
    // Work through this.state.remoteParticipants array and match identity with _id from participantRealNames
    // then update remoteParticipants.displayName where matched
  }

  previewVideo = () => {
    createLocalVideoTrack().then((track) => {
      const localMediaContainer = document.getElementById("local-media-div");
      // Store reference to local track so we can stop it on disconnect
      localTrack = track;
      localMediaContainer.appendChild(track.attach());
    });
  };

  connect = () => {
    // Get random username token
    fetch("/api/twiliotoken?userName=" + this.props.userProfile._id)
      //.then((res) => res.text())
      .then((res) => res.json())
      .then((data) => this.connectToRoom(data.tokenValue));
  };

  // Connect to room once token returned
  connectToRoom (token) {
    connect(token, {
      name: videoCallID,
      //tracks: combinedTracks,
      automaticSubscription: true,
    }).then(
      (room) => {
        // Keep reference to room to allow disconnection later
        roomReference = room;

        // Save the LocalVideoTrack so we can disconnect it later
        let localVideoTrack = Array.from(
          room.localParticipant.videoTracks.values()
        )[0].track;

        console.log(`Successfully joined a Room: ${room}`);
        console.log("Room name is: " + videoCallID);

        // --------- Console log events

        room.localParticipant.on("trackPublished", (publication) => {
          console.log("Data track published");
        });
        // Handle failure to publish local data track
        room.localParticipant.on("trackPublicationFailed", (error, track) => {
          console.log("Data track publication failed");
        });

        // Log your Client's LocalParticipant in the Room
        const localParticipant = room.localParticipant;
        console.log(
          `Connected to the Room as LocalParticipant "${localParticipant.identity}"`
        );

        // Log any Participants already connected to the Room
        room.participants.forEach((participant) => {
          console.log(
            `Participant "${participant.identity}" is connected to the Room`
          );
        });

        // --------- Action based events

        // Handle the LocalParticipant's media.
        // TODO here is the option as to whether to display local media as participant
        // Typically on for a video room but off for 1-1 meetup
        // If this is on and we count ourself as a remote participant then need to
        // not manual add ourself to the updateParticipants method
        //participantConnected(room.localParticipant);

        // Update participants of our arrival
        this.updateVideoParticipants(true);

        // Subscribe to the media published by RemoteParticipants already in the Room.
        room.participants.forEach((participant) => {
          this.participantConnected(participant);
        });

        // Subscribe to the media published by RemoteParticipants joining the Room later.
        room.on("participantConnected", (participant) => {
          this.participantConnected(participant);
        });

        // Handle a disconnected RemoteParticipant.
        room.on("participantDisconnected", (participant) => {
          this.participantDisconnected(participant, room);
        });

        // Cleanup for when we disconnect
        room.once("disconnected", (room) => {
          // Stop the LocalVideoTrack.
          localVideoTrack.stop();

          // Handle the disconnected LocalParticipant.
          this.participantDisconnected(room.localParticipant);

          // Handle the disconnected RemoteParticipants.
          room.participants.forEach((participant) => {
            this.participantDisconnected(participant);
          });

          // Handled disconnected LocalParticipant
          this.participantDisconnected(room.localParticipant);
        });
      },
      (error) => {
        console.error(`Unable to connect to Room: ${error.message}`);
      }
    );
  }

  // Used as a lookup to get the real name for a user joining a video call (from their user ID)
  getUserRealName (identity) {
    // Get real user name
    /**fetch("/api/getUserRealName?userID=" + identity)
      .then((res) => res.json())
      .then((data) => this.connectToRoom(data.tokenValue)); */
    return "Fred";
  }

  /** This is no longer used in favour of state array
  setupParticipantContainer(participant, room) {
    const { identity, sid } = participant;
  
    // Add a container for the Participant's media.
  
    const userContainerDiv = document.createElement("div");
    userContainerDiv.setAttribute("data-identity", identity);
    userContainerDiv.setAttribute("id", sid);
  
    // User name
    const userNameH2 = document.createElement("h2");
    userNameH2.innerHTML = this.getUserRealName(identity);
  
    const userAudioElement = document.createElement("audio");
    // Need to mute own audio feed
    if (participant === room.localParticipant) {
      userAudioElement.setAttribute("muted", "");
    }
    userAudioElement.setAttribute("autoplay", "");
  
    const userVideoElement = document.createElement("video");
    userVideoElement.setAttribute("autoplay", "");
    userVideoElement.setAttribute("muted", "");
    userVideoElement.setAttribute("playsinline", "");
  
    userContainerDiv.appendChild(userAudioElement);
    userContainerDiv.appendChild(userVideoElement);
    userContainerDiv.appendChild(userNameH2);
  
    const newVideoArea = document.getElementById("remote-media-div");
    newVideoArea.append(userContainerDiv);
  } **/

  participantConnected (participant) {
    // Update participants list
    this.updateVideoParticipants(true);

    // Set up the Participant's media container.
    // This is no longer used in favour of state array
    //this.setupParticipantContainer(participant, roomReference);

    // Handle the TrackPublications already published by the Participant.
    participant.tracks.forEach((publication) => {
      this.trackPublished(publication, participant);
    });

    // Handle theTrackPublications that will be published by the Participant later.
    participant.on("trackPublished", (publication) => {
      this.trackPublished(publication, participant);
    });

    // Update the count of users in the room
    this.updateRoomUserCount();
  }

  updateRoomUserCount () {
    this.setState({ roomUserCount: roomReference.participants.size });
  }

  trackPublished (publication, participant) {
    // If the TrackPublication is already subscribed to, then attach the Track to the DOM.
    if (publication.track) {
      this.attachTrack(publication.track, participant);
    }

    // Once the TrackPublication is subscribed to, attach the Track to the DOM.
    publication.on("subscribed", (track) => {
      this.attachTrack(track, participant);
    });

    // Once the TrackPublication is unsubscribed from, detach the Track from the DOM.
    publication.on("unsubscribed", (track) => {
      this.detachTrack(track, participant);
    });
  }

  // Attach a track which might be audio, video or data
  attachTrack (track, participant) {
    if (track.kind === "video" || track.kind === "audio") {
      this.attachVideoTrack(track, participant);
    } else if (track.kind === "data") {
      this.attachDataTrack(track, participant);
    }
  }

  attachVideoTrack (track, participant) {
    const $participants = $("#remote-media-div");
    const $media = $(`div#${participant.sid} > ${track.kind}`, $participants);
    $media.css("opacity", "");
    track.attach($media.get(0));

    /**const remoteMediaDiv = document.getElementById(participant.sid);
  const newTrack = track.attach();
  console.log("Attaching user window: " + participant.sid);
  newTrack.setAttribute("id", participant.sid);
  remoteMediaDiv.appendChild(newTrack); **/
  }

  participantDisconnected (participant) {
    // Update participants list
    this.updateVideoParticipants(true);

    /**
     * 
     * This is no longer used now that we used state representation of connected users rather than createElement/remove
    console.log("Detaching user window: " + participant.sid);
    const participantVideo = document.getElementById(participant.sid);
    if (participantVideo) {
      participantVideo.remove();
    }
    */
    // Update the count of users in the room
    this.updateRoomUserCount();
  }

  detachTrack (track, participant) {
    if (track.kind === "video" || track.kind === "audio") {
      this.detachVideoTrack(track, participant);
    } else if (track.kind === "data") {
      this.detachDataTrack(track, participant);
    }
  }

  detachVideoTrack (track, participant) {
    // Detach track
    track.detach();
    console.log("Detataching user window: " + participant.sid);
    /* No longer need to do this as user container gets removed from state
    const participantVideo = document.getElementById(participant.sid);
    if (participantVideo) {
      participantVideo.remove();
    } */
  }

  detachDataTrack (track, participant) {
    // No action for now
  }

  attachDataTrack (track, participant) {
    track.on("message", (data) => {
      this.receiveDataAction(data);
    });
  }

  // Called when user leaves or joins to update the state representation of connected users
  updateParticipantDivs () {
    // Update state represenation of remote users
    console.log("31h0dh3 - Updating partipant divs");
    var participantIdentityArray = [];
    if (roomReference != null) {
      roomReference.participants.forEach((participant) => {
        console.log(
          "08dh1dh3 - Adding participant to state: " + participant.identity
        );
        var participantIdentity = {
          identity: participant.identity,
          sid: participant.sid,
          displayName: "",
        };
        participantIdentityArray.push(participantIdentity);
      });
    }

    this.setState({ remoteParticipants: participantIdentityArray });
  }

  // Include self is set when joining or within room but need to be false after having disconnected
  // else we report outselves as present even though we have disconnected
  updateVideoParticipants (includeSelf) {
    // Update state representation of remote users first
    this.updateParticipantDivs();

    // Need to manually add self as room.participants only gives remote participants
    if (roomReference != null) {
      console.log(
        "Number of participants in room: " + roomReference.participants.size + 1
      );
    } else {
      console.log("No current room reference held");
    }
    var participantIdentities = [];
    // Add self to users connected to room array if not disconnected
    if (includeSelf) {
      participantIdentities.push(roomReference.localParticipant.identity);
    }
    // Iterate through participants to form array of connected userIDs
    if (roomReference != null) {
      roomReference.participants.forEach((participant) => {
        console.log(participant.identity);
        participantIdentities.push(participant.identity);
      });
    }
    console.log("Identities: " + participantIdentities);

    // Update endpoints based on whether this video call is a meeting or roundtable
    if (videoIsMeeting) {
      console.log(
        "Updating meeting participants for meetingID : " + videoCallID
      );
      fetch("/api/updateMeetingParticipants", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          meetingID: videoCallID,
          participants: participantIdentities,
        }),
      })
        .then((result) => result.text())
        .then((response) => {
          console.log("Submitted participants list for meeting");
          this.loadUserRealNames();
        });
    } else {
      fetch("/api/updateRoundtableParticipants", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          roundtableID: videoCallID,
          participants: participantIdentities,
        }),
      })
        .then((result) => result.text())
        .then((response) => {
          console.log("Submitted participants list for roundtable");
          this.loadUserRealNames();
        });
    }
  }

  receiveDataAction (data) {
    console.log("Data received: " + data);
    //videoComponent.passDataTrackTo3D(data);
  }

  disconnect = () => {
    if (roomReference) {
      roomReference.disconnect();
    }

    // Update participants for the case where we are the last to leave
    this.updateVideoParticipants(false);

    // Stop the local video preview tracks too to release camera and turn off light
    if (localTrack) {
      localTrack.stop();
    }

    this.setState({ redirectToMyMeetings: true });
  };

  render () {
    const getRemoteParticipantColor = (index) => {
      if (index === 0) {
        return {
          backgroundColor: "orange",
        };
      } else if (index === 1) {
        return {
          backgroundColor: "yellow",
        };
      } else if (index === 2) {
        return {
          backgroundColor: "green",
        };
      } else if (index === 3) {
        return {
          backgroundColor: "blue",
        };
      } else if (index === 4) {
        return {
          backgroundColor: "purple",
        };
      }
    };

    return (
      <>
        {this.props.showMobile ?
          <VideoMobile
            showMobile={this.props.showMobile}
            userProfile={this.props.userProfile}
            meetingID={this.props.match.params.meetingID}
          />
          :
          <div>
            <div className={styles.outerBackgroundDiv}>
              {/**<img
            className="imageNavBackground"
            src={window.$videoPath + "background.png"}
            alt="Background"
          ></img>*/}
            </div>

            <div className={styles.hotlinkBar}>
              <Link to={"/lobby"} className={styles.backLink}>
                <div className={styles.backDiv}>
                  <img
                    className={styles.backArrow}
                    src={window.$videoPath + "icons/backArrow.png"}
                    alt="Back"
                  />
                  Back to Lobby
                </div>
              </Link>
            </div>

            <div className={styles.headerBar}>
              <h1 className={styles.pageTitle}>
                <strong>1:1 Meeting</strong>
              </h1>
            </div>

            {this.state.roomUserCount < 2 ? (
              //2 < 1 ? ( - Used to force to room display
              <div>
                <h2 className={styles.connectHint}>
                  Please click connect as soon as you are ready to join
                </h2>
                <div className={styles.centralContentArea}>
                  <div
                    id="local-media-div"
                    className={styles.singleRemoteUserLocalMediaDiv}
                  >
                    <div className={styles.namePanel}></div>
                    <h1 className={styles.participantname}>
                      {this.props.userProfile
                        ? this.props.userProfile.firstName +
                        " " +
                        this.props.userProfile.surname
                        : null}
                    </h1>
                  </div>
                  <div
                    id="remote-media-div"
                    className={styles.singleRemoteUserRemoteMediaDiv}
                  >
                    <div className={styles.namePanel}></div>
                    {this.state.remoteParticipants.map((participant) => {
                      console.log(
                        "Output remote participant: " + participant.identity
                      );
                      return (
                        <div
                          id={participant.sid}
                          data-identity={participant.id}
                          key={participant.sid}
                        >
                          <h1 className={styles.participantname}>
                            {participant.displayName}
                          </h1>
                          <audio autoPlay />
                          <video muted autoPlay playsInline />
                        </div>
                      );
                    })}
                  </div>
                  <div className={styles.connectDiv}>
                    <Button
                      className={styles.connectButton}
                      variant="success"
                      onClick={this.connect}
                    >
                      Connect
                    </Button>
                    <Button
                      className={styles.disconnectButton}
                      variant="primary"
                      onClick={this.disconnect}
                    >
                      Disconnect
                    </Button>
                  </div>
                  {3 === 2 &&
                    this.props.userProfile &&
                    this.props.userProfile.firstName ? (
                    <div className={styles.textChatArea}>
                      <h2 style={{ textAlign: "center" }}>
                        If you encounter any difficulties activating your
                        microphone or camera, the text chat below will connect you
                        to a private chat session with your fellow attendee{" "}
                      </h2>
                      <ChatFunction
                        className={styles.textChatArea}
                        userProfile={this.props.userProfile}
                        chatChannel={this.props.match.params.meetingID}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              // *********** BEGIN 2+ Meeting room styling
              <div>
                <div className={styles.participantsBar}>
                  <div
                    id="local-media-div"
                    className={styles.multipleRemoteUserLocalMediaDiv}
                  ></div>

                  <div
                    id="remote-media-div"
                    className={styles.multipleRemoteUserRemoteMediaDiv}
                  >
                    {this.state.remoteParticipants.map((participant, index) => {
                      console.log(
                        "Output remote participant: " + participant.identity
                      );
                      return (
                        <div
                          className={styles.remoteParticipant}
                          id={participant.sid}
                          data-identity={participant.id}
                          key={participant.sid}
                          style={getRemoteParticipantColor(index)}
                        >
                          <audio autoPlay />
                          <video muted autoPlay playsInline />
                          <h1>{participant.displayName}</h1>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className={styles.connectDiv}>
                  <Button
                    className={styles.connectButton}
                    variant="success"
                    onClick={this.connect}
                  >
                    Connect
                  </Button>
                  <Button
                    className={styles.disconnectButton}
                    variant="primary"
                    onClick={this.disconnect}
                  >
                    Disconnect
                  </Button>
                </div>
              </div>
            )}
            {this.state.redirectToMyMeetings ? <Redirect to="/meetings" /> : null}
          </div>
        }
      </>
    );
  }
}
