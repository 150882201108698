import { useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Firebase from "./../helpers/firebase.js";
import { useHistory } from "react-router-dom";

const auth = firebase.auth();

function RemoteControlListener (props) {
  let history = useHistory();

  const retrieveRemoteControlReadCredentials = () => {
    fetch("/api/getRemoteControlReadCredentials")
      .then((res) => res.json())
      .then((data) => authenticateRemoteControlReadAccess(data));
  };

  const authenticateRemoteControlReadAccess = (data) => {
    console.log("Retrieved Firestore read credentials");
    auth
      .signInWithEmailAndPassword(data.username, data.password)
      .then(connectToFirestore)
      .catch((error) => {
        console.error("Error signing into remote control read access", error);
      });
  };

  const connectToFirestore = () => {
    console.log("Including cfsListener");
    var db = Firebase.firestore();
    db.collection("arm-big-picture-2022")
      .orderBy("timestamp", "desc")
      .limit(1)
      .onSnapshot((snapshot) => {
        snapshot.forEach(function (doc) {
          console.log(doc.id, doc.data());
          console.log("Remote control listener received: " + doc.data());
          var navigation = doc.data().navigation;

          if (navigation && navigation.length > 0) {
            // Check we are not already on this page to avoid unnecessary nav
            if (history.location.pathname !== "/" + navigation) {
              history.push("/" + navigation);
            }
          }

          var navBarStatus = doc.data().navBarStatus;
          if (navBarStatus != null) {
            props.setTopMenuStatus(navBarStatus);
          }

          var plenaryContent = doc.data().plenaryContent;
          if (plenaryContent != null) {
            props.setPlenaryContent(plenaryContent);
          }

          var plenaryPosition = doc.data().plenaryPosition;
          if (plenaryPosition != null) {
            props.setPlenaryPosition(plenaryPosition);
          }

          var plenaryOpenStatus = doc.data().plenaryOpenStatus;
          if (plenaryOpenStatus != null) {
            props.setPlenaryOpenStatus(plenaryOpenStatus);
          }

          var notificationText = doc.data().notificationText;
          if (notificationText) {
            props.setNotificationText(notificationText);
          } else {
            props.setNotificationText("");
          }

          var circlesLive = doc.data().circlesLive;
          if (circlesLive != null) {
            props.setCirclesLive(circlesLive);
          }

          var breakout1Live = doc.data().breakout1Live;
          if (breakout1Live != null) {
            props.setBreakout1Live(breakout1Live);
          }

          var breakout2Live = doc.data().breakout2Live;
          if (breakout2Live != null) {
            props.setBreakout2Live(breakout2Live);
          }

          var breakout3Live = doc.data().breakout3Live;
          if (breakout3Live != null) {
            props.setBreakout3Live(breakout3Live);
          }

          var breakout4Live = doc.data().breakout4Live;
          if (breakout4Live != null) {
            props.setBreakout4Live(breakout4Live);
          }

          var breakout5Live = doc.data().breakout5Live;
          if (breakout5Live != null) {
            props.setBreakout5Live(breakout5Live);
          }

          var breakout6Live = doc.data().breakout6Live;
          if (breakout6Live != null) {
            props.setBreakout6Live(breakout6Live);
          }

          var breakout7Live = doc.data().breakout7Live;
          if (breakout7Live != null) {
            props.setBreakout7Live(breakout7Live);
          }

          var breakout8Live = doc.data().breakout8Live;
          if (breakout8Live != null) {
            props.setBreakout8Live(breakout8Live);
          }

          var breakout9Live = doc.data().breakout9Live;
          if (breakout9Live != null) {
            props.setBreakout9Live(breakout9Live);
          }

          var breakout10Live = doc.data().breakout10Live;
          if (breakout10Live != null) {
            props.setBreakout10Live(breakout10Live);
          }

          var lobbyScreen = doc.data().lobbyScreen;
          if (lobbyScreen != null) {
            props.setLobbyScreen(lobbyScreen);
          }
        });
      });
  };

  useEffect(() => {
    // Logout of Firebase
    //auth.signOut();
    // Retrieve credentials and connect
    //retrieveRemoteControlReadCredentials();
    // Do not load remote control listener if on this page
    if (!window.location.href.includes("exhibitionStandAnswers")) {
      connectToFirestore();
    }
  }, []);
  return null;
}

export default RemoteControlListener;
