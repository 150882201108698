import React, { Component } from "react";
import Modal from "react-modal";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./JoinMeetingModal.module.scss";

export default class JoinMeetingModal extends Component {

  render() {
    return (
      <div>
        <Modal
          ariaHideApp={false}
          isOpen={this.props.showJoinMeetingModal}
          onRequestClose={() => this.props.setShowJoinMeetingModal(false)}
          contentLabel="Join Meeting Modal"
          overlayClassName={styles.modalOverlay}
          className={styles.modalContent}
          style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
        >
          <div className={styles.modalFlexboxColumn}>
            <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
              <h1 className={styles.modalPageTitle}>Networking</h1>
              <button
                className={styles.modalClose}
                variant="success"
                onClick={() => this.props.setShowJoinMeetingModal(false)}
              >
                Close
                <img
                  className={styles.modalCloseIcon}
                  src="/icons/close-no-circle.png"
                  alt="Close"
                />
              </button>
            </div>

            <div className={styles.customScrollableAreaNoPadding}>
              <div className={styles.columnContainer}>
                {/**<div className={styles.leftProfileColumn}>
                  <div className={styles.roundtableArea}>
                    {this.props.breakout1Live == "1" ?
                      <a href="https://twine.nyc/r/4g3v" target={'_blank'} rel="noreferrer noopener" onClick={() => alert("Password is BPDEC")}>
                        <img
                          src={window.$videoPath + "visualAssets/en/roundtableBackground.png"}
                          alt="Background"
                          className={styles.roundtableBackground}
                          style={{ opacity: "100%" }}
                        />
                      </a>
                      :
                      <img
                        src={window.$videoPath + "visualAssets/en/roundtableBackground.png"}
                        alt="Background"
                        className={styles.roundtableBackground}
                        style={{ opacity: "50%" }}
                      />
                    }
                    <h1
                      className={styles.roundtableOverlayText}>Coffee Roulette
                    </h1>
                  </div>


                  {this.props.breakout1Live == "1" ? <span>Session is Open (Password is BPDEC)</span> : <span>Session is Closed</span>}<br /><br />
                  These virtual randomized meetings are a great way for you to start some engaging conversations and connect 1-on-1 with someone new.
                  <br />
                  <br />
                  <br />
                  <br />
                </div>*/}

                <div className={styles.rightProfileColumn}>
                  <div className={styles.roundtableArea}>
                    {this.props.breakout2Live == "1" ?
                      <a href="https://armltd.zoom.us/j/97798926276?pwd=K0NjSXlWTVZwNkM1Nk1PUElvU3JpUT09" target={'_blank'} rel="noreferrer noopener">
                        <img
                          src={window.$videoPath + "visualAssets/en/roundtableBackground.png"}
                          alt="Background"
                          className={styles.roundtableBackground}
                          style={{ opacity: "100%" }}
                        />
                      </a>
                      :
                      <img
                        src={window.$videoPath + "visualAssets/en/roundtableBackground.png"}
                        alt="Background"
                        className={styles.roundtableBackground}
                        style={{ opacity: "50%" }}
                      />
                    }
                    <h1
                      className={styles.roundtableOverlayText}>Big Picture Quiz</h1>
                  </div>
                  {this.props.breakout2Live == "1" ? <span>Session is Open</span> : <span>Session is Closed</span>}<br /><br />
                  Join the Big Picture Quiz activity
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
