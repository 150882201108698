import React, { Component } from "react";
import Modal from "react-modal";
import VideoPlayer from "./../modules/videoPlayer/VideoPlayer"
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./ModalStyles.module.scss";

export default class AgendaModal extends Component {


  render () {
    return (
      <div>
        <Modal
          ariaHideApp={false}
          isOpen={true}
          onRequestClose={() => this.props.hideVideo()}
          contentLabel="Leaderboard Modal"
          overlayClassName={styles.modalOverlay}
          className={styles.modalContentShort}
          style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
        >
          <div className={styles.modalFlexboxColumn}>
            <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/holdingModalHeader.png)', }}>
              <h1 className={styles.modalPageTitle}>The Big Picture teaser video</h1>
              <button
                className={styles.modalClose}
                variant="success"
                onClick={() => this.props.hideVideo()}
                aria-label="Close"
              >
                Close
                <img
                  className={styles.modalCloseIcon}
                  src="/icons/close-no-circle.png"
                  alt="Close"
                />
              </button>
            </div>

            <div className={styles.scrollableArea}>
              <div className={styles.modalContentArea}>

                <VideoPlayer
                  resourceLocator={"mux://c6wddq2pP5SJhrbx6021GZOZtmYCqr47ktigPDok5c58"}
                  location={"holding-welcome-video"}
                  userProfile={this.props.userProfile}
                  autoPlay={true}
                />

              </div>
            </div>
          </div>

        </Modal>
      </div>
    );
  }
}
