import React, { Component } from "react";
import dompurify from "dompurify";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Button from "react-bootstrap/Button";
import { CSSTransition } from "react-transition-group";
import ExhibitionStandQuestions from "./../questions/ExhibitionStandQuestions";
import { requestPoints } from "./../helpers/requestPoints.js";
import styles from "./ResourceLibrary.module.scss";

export default class ResourceLibrary extends Component {
  constructor() {
    super();
    //Set default message
    this.state = {
      introVideoComplete: false,
      showDetailModal: false,
      selectDetailModal: 5,
      externalContentIframeSource: "",
    };
  }

  componentDidMount () {
    // If this is a small breakout we need to change the opening frame on load
    // In this case all breakouts are small breakouts
    /**if (
      this.props.match.params.breakoutID.includes("5f240c59a23ba10b65f7f67d")
    ) {
      this.setState({ isSmallBreakout: true });
    } */
    fetch("/api/getResourceCentreContentKey")
      // Need to somehow request by received prop ID
      .then((res) => res.json())
      .then((data) => this.loadResponseIntoState(data));
    // Start timeout in case video does not load

    setTimeout(
      function () {
        this.setState({ introVideoComplete: true });
      }.bind(this),
      6000
    );
  }

  loadResponseIntoState (data) {
    this.setState({
      contentKey: data.contentKey,
    });
  }

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  showStand1 = () => {
    this.setState({ showDetailModal: true, selectDetailModal: 1 });
    requestPoints("viewResourceCentreBooth", 1);
  };

  showStand2 = () => {
    this.setState({ showDetailModal: true, selectDetailModal: 1 });
    requestPoints("viewResourceCentreBooth", 2);
  };

  showStand3 = () => {
    this.setState({ showDetailModal: true, selectDetailModal: 1 });
    requestPoints("viewResourceCentreBooth", 3);
  };

  showStand4 = () => {
    this.setState({ showDetailModal: true, selectDetailModal: 1 });
    requestPoints("viewResourceCentreBooth", 4);
  };

  showStand5 = () => {
    this.setState({ showDetailModal: true, selectDetailModal: 1 });
    requestPoints("viewResourceCentreBooth", 5);
  };

  closeDetailModal = () => {
    this.setState({ showDetailModal: false });
  };

  render () {
    return (
      <div>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.externalContentIframeSource.length > 0}
          contentLabel="External Content Embed"
          style={{
            overlay: {
              position: "fixed",
              top: 100,
              left: 100,
              right: 100,
              bottom: 100,
              backgroundColor: "rgba(255, 255, 255, 0.75)",
              zIndex: 30000,
            },
            content: {
              position: "absolute",
              top: "20px",
              left: "20px",
              right: "20px",
              bottom: "20px",
              border: "1px solid #ccc",
              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              borderRadius: "4px",
              outline: "none",
              padding: "20px",
            },
          }}
        >
          <iframe
            title="More info iFrame"
            dialogueclassname={styles.moreInfoIframe}
            width="100%"
            height="95%"
            frameBorder="0"
            src={this.state.externalContentIframeSource}
          ></iframe>
          <Button
            className={styles.modalClose}
            variant="success"
            onClick={() =>
              this.setState({
                externalContentIframeSource: "",
              })
            }
          >
            Close
          </Button>
        </Modal>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.showDetailModal}
          onRequestClose={this.closeDetailModal}
          contentLabel="Exhibition Stand Detail"
          style={{
            overlay: {
              position: "fixed",

              width: 1040,
              top: 100,
              left: 0,
              right: 0,
              marginLeft: "auto",
              marginRight: "auto",
              height: 640,

              backgroundColor: "rgba(255, 255, 255, 0.75)",
              zIndex: 20000,
              boxShadow: "10px 10px 25px 8px grey",
            },
            content: {
              position: "absolute",
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
              border: "2px solid black",
              background: "#f1f1f1",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              borderRadius: "0px",
              outline: "none",
              padding: "0px",
            },
          }}
        >
          {this.state.selectDetailModal === 1 ? (
            <div className={styles.imageNavContainer}>
              <div className={styles.titleBar}>
                <button
                  className={styles.modalClose}
                  variant="success"
                  onClick={this.closeDetailModal}
                >
                  Close
                  <img
                    className={styles.closeIcon}
                    src="/icons/close-no-circle.png"
                    alt="Close"
                  />
                </button>
              </div>
              <img
                src={
                  window.$videoPath +
                  "visualAssets/" + this.props.language + "/researchBooth-" +
                  this.state.contentKey +
                  ".jpg"
                }
                alt="Arm Research Booth"
                className={styles.detailImage}
              />
              <a href="https://www.dropbox.com/s/lczu76werkwgcmj/Live%20Union%20Hybrid-Unified%20Experiences_v1.pdf?dl=0" target="_blank" rel="noopener noreferrer">
                <div
                  className={styles.detail1UnifiedButton}
                />
              </a>
              <a href="https://liveunion.co.uk/our-work/post-office-managers-event/" target="_blank" rel="noopener noreferrer">
                <div
                  className={styles.detail1PostOfficeButton}
                />
              </a>
              <a href="https://liveunion.co.uk/our-work/unite-students-team-talk-live/" target="_blank" rel="noopener noreferrer">
                <div
                  className={styles.detail1UniteButton}
                />
              </a>
              <a href="https://mitmagazine.co.uk/news/welcome-to-the-new-event-era-how-to-thrive-in-the-new-normal/" target="_blank" rel="noopener noreferrer">
                <div
                  className={styles.detail1EraButton}
                />
              </a>
              <a href="https://liveunion.co.uk/physical/" target="_blank" rel="noopener noreferrer">
                <div
                  className={styles.detail1WebsiteButton}
                />
              </a>
              <div
                className={styles.detail1ChatButton}
                onClick={() => this.setState({ selectDetailModal: 6 })}
              />
            </div>
          ) : null
          }

          {
            this.state.selectDetailModal === 6 ? (
              <div>
                <div className={styles.titleBar}>
                  <button
                    className={styles.modalClose}
                    variant="success"
                    onClick={() => this.setState({ selectDetailModal: 1 })}
                  >
                    Close
                    <img
                      className={styles.closeIcon}
                      src="/icons/close-no-circle.png"
                      alt="Close"
                    />
                  </button>
                </div>
                <ExhibitionStandQuestions
                  exhibitionStandID={"5f340ca7df57370e1a37e646"}
                />
              </div>
            ) : null
          }
        </Modal >

        <div className="imageNavDiv">
          <video
            className="imageNavBackground"
            autoPlay
            muted
            id="introVideo"
            onEnded={() => this.setState({ introVideoComplete: true })}
          >
            <source
              src={window.$videoPath + "visuals/" + this.props.language + "/marketplace.mp4"}
              type="video/mp4"
            />
            <source
              src={window.$videoPath + "visuals/" + this.props.language + "/marketplace.webm"}
              type="video/webm"
            />
          </video>

          <CSSTransition
            in={this.state.introVideoComplete}
            timeout={1500}
            classNames="imageNavBackground"
            unmountOnExit
          >
            <img
              className="imageNavBackground"
              src={window.$videoPath + "visuals/" + this.props.language + "/marketplace.jpg"}
              alt="Background"
            ></img>
          </CSSTransition>

          <Link to="/breakoutsStill">
            <img
              className={`${styles.hoverHotspot} ${styles.exitHoverHotspot}`}
              src="/hoverShapes/hoverSquare.png"
              alt="Exit"
            ></img>
          </Link>

          <div
            onClick={this.showStand1}
            className={`${styles.hoverHotspot} ${styles.stand1ClickArea}`}
          />
          <div
            onClick={this.showStand2}
            className={`${styles.hoverHotspot} ${styles.stand2ClickArea}`}
          />
          <div
            onClick={this.showStand3}
            className={`${styles.hoverHotspot} ${styles.stand3ClickArea}`}
          />
          <div
            onClick={this.showStand4}
            className={`${styles.hoverHotspot} ${styles.stand4ClickArea}`}
          />
          <div
            onClick={this.showStand5}
            className={`${styles.hoverHotspot} ${styles.stand5ClickArea}`}
          />
        </div>
      </div >
    );
  }
}
