import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import dompurify from "dompurify";
import { requestPoints } from "../helpers/requestPoints.js";
import CustomOnDemandModal from "./CustomOnDemandModal";
import VideoPlayer from "../modules/videoPlayer/VideoPlayer"
import CustomOnDemandMobile from "./CustomOnDemandMobile"
import styles from "./CustomOnDemand.module.scss";

export default class CustomOnDemand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onDemandContent: [
        {
          id: 1,
          contentTitle: "AMS",
          contentInfo: "OneArm is our Arm Management System. This is how we run the company to work towards our vision and strategic goals. Check out our intranet page to learn more about how Arm works as a company.",
          urls: [
            {
              id: 1,
              url: "https://armh.sharepoint.com/sites/OneArm",
              title: "Learn about One Arm"
            }
          ]
        },
        {
          id: 2,
          contentTitle: "Talent Acquisition (TA)",
          contentInfo: "Welcome to Arm! During your recruitment and onboarding process into Arm you would have interacted with some of our TA team members. Of course, they are great at running our recruitment services, but they also provide a number of exciting programs for early careers, learning and development and people management.",
          urls: [
            {
              id: 1,
              url: "https://arm.service-now.com/people_hub?id=ph_kb_article_view&sys_kb_id=c117c7541b078d10a0b7848cd34bcb70&spa=1",
              title: "Applying for internal vacancies"
            },
            {
              id: 2,
              url: "https://arm.service-now.com/people_hub?id=ph_kb_article_view&sys_kb_id=8a9343b21be2f0d429b04009b04bcbb5&spa=1",
              title: "Arm’s referral program"
            },
            {
              id: 3,
              url: "https://arm.service-now.com/people_hub?id=ph_kb_article_view&sys_kb_id=b748813b1bc90110139bdb19b04bcb4c&spa=1",
              title: "Arm’s hiring process"
            },
            {
              id: 4,
              url: "https://armh.sharepoint.com/:b:/s/Arm_internal_events/EfzVI6_FmLpBl-of-BbpjWYBCsaTCFuN1DbgcYS8LcPitQ?e=3TXscg",
              title: "Check out the early careers program"
            }
          ]
        },
        {
          id: 3,
          contentTitle: "Workplace",
          contentInfo: "The Workplace team plays a key role at Arm to provide an environment where people come together to connect and get inspired to achieve great things. The team not only look after our amazing offices, but they also take care of our health & safety, wellbeing, space design and much more!",
          urls: [
            {
              id: 1,
              url: "https://armh.sharepoint.com/sites/Workplace-home",
              title: "Workplace Hub"
            },
            {
              id: 2,
              url: "https://armh.sharepoint.com/sites/WorkplaceSustainability",
              title: "Arm’s sustainability & environmental responsibility"
            },
            {
              id: 3,
              url: "https://armh.sharepoint.com/sites/BusinessContinuityManagement",
              title: "Business continuity and crisis management information"
            },
            {
              id: 4,
              url: "https://armh.sharepoint.com/sites/Workplace-home/SitePages/Workplace-Health-&-Safety-Hub.aspx",
              title: "Health & safety at Arm"
            },
            {
              id: 5,
              url: "https://armh.sharepoint.com/sites/Community/SitePages/Wellbeing.aspx",
              title: "Wellbeing Hub"
            },
            {
              id: 6,
              url: "https://armh.sharepoint.com/sites/Regionalleadershipsite",
              title: "Learn about Arm’s Regional Leadership team"
            },
            {
              id: 7,
              title: "Discover Unmind",
              videoID: "mux://ZAypMEgEXgIzZ9FwFyZCw021HeF00eD9bOr1hL9019Hczk"
            }
          ]
        },
        {
          id: 4,
          contentTitle: "Reward",
          contentInfo: "At Arm, our rewards and benefits program is designed to recognize your contribution, offer you the opportunity to stay happy and healthy and support you in ensuring the wellness of your family, all in our own Arm-unique way.",
          urls: [
            {
              id: 1,
              url: "https://arm.service-now.com/people_hub?id=ph_fav_topic&kb_category=7bf0d06fdb6f7700bbd5f3561d96196a",
              title: "An overview of Reward at Arm"
            },
            {
              id: 2,
              url: "https://reward.arm.com/",
              title: "Visit the Reward Hub"
            }
          ]
        },
        {
          id: 5,
          contentTitle: "Team Arm",
          contentInfo: "Team Arm is our employee community engagement program, giving you the opportunity to take action on the Global Goals for Sustainable Development through volunteering, campaigning and fundraising. Everyone at Arm can use half a day per month of company time to volunteer, bringing about a positive change to our communities.",
          urls: [
            {
              id: 1,
              url: "https://armh.sharepoint.com/sites/teamarm-home",
              title: "Visit the Team Arm intranet page"
            },
            {
              id: 2,
              url: "https://arm.benevity.org/",
              title: "Visit Arm’s Giving Hub"
            },
            {
              id: 3,
              url: "https://arm.benevity.org/volunteer/list",
              title: "Find opportunities to volunteer"
            },
            {
              id: 4,
              url: "https://armh.sharepoint.com/sites/teamarm-home/SitePages/Team-Arm-Champions-and-Amassadors.aspx?csf=1&web=1&e=Ijdrsa&cid=4a511822-2b81-4147-a9c6-8e40a22c3322",
              title: "Meet our Team Arm Champions & Ambassadors"
            },
            {
              id: 5,
              url: "https://www.globalgoals.org/",
              title: "Find out about Global Goals for Sustainable Development"
            },
            {
              id: 6,
              url: "https://app.slack.com/client/TC55NM0BT/C0137TGL9V0",
              title: "Join the Team Arm Slack channel"
            },
            {
              id: 7,
              title: "Hear Anna's story",
              videoID: "mux://3n02zxlux2xWaBB47TBg4hBR00k6GK33cC9eS1hQI2ZVE"
            }
          ]
        },
        {
          id: 6,
          contentTitle: "Sustainability",
          contentInfo: "When you work with great people, everything you do has the potential to make billions of lives better. Arm’s foundational technology touches the lives of more than 70% of the world's population, so we have a responsibility to ensure that our continued growth works in harmony with the needs of people and the planet. Check out our links to find more information on Arm’s commitment to sustainability.",
          urls: [
            {
              id: 1,
              url: "https://armh.sharepoint.com/sites/Corporate-Sustainability/SitePages/Sustainability-at-Arm.aspx",
              title: "Sustainability at Arm"
            },
            {
              id: 2,
              url: "https://www.arm.com/why-arm/innovation/made-possible",
              title: "See what’s made possible on Arm"
            },
            {
              id: 3,
              title: "How technology can achieve sustainable development goals",
              videoID: "mux://7Z3z7St6nQrjawK025obvumxWeOQi6i01CEYuWtmkXj200"
            },
            {
              id: 4,
              title: "Arm’s commitment to the Global Goals",
              videoID: "mux://hIXCwvzv41mHatO4KuanYGEDMAagMLiV1EVReLVMRVQ"
            },
          ]
        },
        {
          id: 7,
          contentTitle: "Learning & Development",
          contentInfo: "Each individual owns their own career and it is their responsibility to clearly articulate career goals and aspirations, in addition to partnering with their manager to identify a path forward. Check out the People Hub to learn more about how you can develop your own career at Arm.",
          urls: [
            {
              id: 1,
              url: "https://arm.service-now.com/people_hub?sys_kb_id=11724a581b130254d8e8ec69b04bcb55&id=ph_kb_article_view&sysparm_rank=2&sysparm_tsqueryId=49fc4d3f1b570a18d8e8ec69b04bcb54",
              title: "myImpact"
            },
            {
              id: 2,
              url: "https://arm.service-now.com/people_hub?id=ph_kb_article_view&sys_kb_id=3d790da497a059d07de074ee2153af42",
              title: "Arm’s grade framework"
            },
            {
              id: 3,
              url: "https://armh.sharepoint.com/sites/LearningAndDevelopment/?xsdata=MDV8MDF8fGM0NDRjOGIyMTI5MzRhYzJkMDhhMDhkYWY0YTlmN2RlfGYzNGU1OTc5NTdkOTRhYWFhZDRkYjEyMmE2NjIxODRkfDB8MHw2MzgwOTEzMDgwNzE1NzE1OTR8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxNVFkzTXpVek5EQXdOakE0TVRzeE5qY3pOVE0wTURBMk1EZ3hPekU1T2pWa1pXVmtORGs1TFRka04yUXROR1V6TVMwNE56RXhMVGs0TnpJM01UbGpaVGxqTlY4NE9UZzNZekZsTXkwNU1HUTBMVFE0WmpndFltTm1aQzFtWVRneE1HUTVNVEppWVRoQWRXNXhMbWRpYkM1emNHRmpaWE09fDFiODU4MTM0OTk3ODQ3NjVkMDhhMDhkYWY0YTlmN2RlfDc2MmM2MTYyMDg4OTRmMjI4M2NiMTI1NGMzOGY5ODEz&sdata=WkVFSVhFTUhlbnlKMFVzWVlDTkdHTzZGbFdJV2VDV0FvR1NDeDlwUkxZVT0%3D&ovuser=f34e5979-57d9-4aaa-ad4d-b122a662184d%2CKyle.Fischer%40arm.com&OR=Teams-HL&CT=1673545771914&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMzAxMDUwOTQwMCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D",
              title: "Learning & Development Digest"
            },
            {
              id: 4,
              url: "https://arm.service-now.com/people_hub?id=ph_kb_article_view&sys_kb_id=dff05ac41b5d011029b04009b04bcb1c",
              title: "Mentoring at Arm"
            },
          ]
        },
        {
          id: 8,
          contentTitle: "Diversity, Equity & Inclusion (DEI)",
          contentInfo: "Our DEI Commitment: Each day we will enable every voice to be heard, create equitable opportunities and experiences for all, and bring the best people into Arm from the broadest talent pool.",
          urls: [
            {
              id: 1,
              url: "https://armh.sharepoint.com/sites/DiversityInclusion",
              title: "Learn more about DEI at Arm"
            },
            {
              id: 2,
              url: "https://armh.sharepoint.com/sites/DiversityInclusion/SitePages/DEI-Strategy.aspx",
              title: "Understand the DEI Strategy"
            },
            {
              id: 3,
              url: "https://armh.sharepoint.com/sites/DiversityInclusion/SitePages/Employee-Resource-Groups-(ERGs).aspx",
              title: "Discover Arm’s Employee Resource Groups (ERGS)"
            },
            {
              id: 4,
              url: "https://slack.com/app_redirect?channel=diversity-equity-inclusion",
              title: "Join the DEI Slack channel"
            },
            {
              id: 5,
              title: "Hear from our DEI Champions",
              videoID: "mux://uOhqjFmvO221pBhNYI800Ek1018hVgB7VhlnllSkVcjFM"
            },
          ]
        }
      ],
      selectedOnDemand: null,
      showOnDemandModal: false,
      visualGallery: false,
    };
  }

  componentDidMount () {
    // Hide corner menu
    this.props.setCornerMenuStatus(3);
    // TODO Update analytics event
    requestPoints("visitOnDemandArea", "visitOnDemandArea");

  }

  componentDidUpdate () {
    // Double check corner menu status is correct
    if (this.props.cornerMenuStatus !== 3) {
      console.log(this.props.cornerMenuStatus);
      this.props.setCornerMenuStatus(3);
    }
  }

  componentWillUnmount () {
    // Set corner menu to displayed again
    this.props.setCornerMenuStatus(1);
  }

  sanitizeCustomField = (customField) => {
    return { __html: dompurify.sanitize(customField) };
  };

  showContentModal = (contentItem) => {
    requestPoints("viewOnDemandItem", contentItem.id);
    this.setState({ selectedOnDemand: contentItem, showOnDemandModal: true });
  }
  // TODO Update analytics naming

  contentGallery = (selectedDay) => {
    return this.state.onDemandContent.map((contentItem) => {
      return (
        <div
          key={contentItem.id}
          className={styles.contentContainer}
          onClick={() => this.showContentModal(contentItem)}
        >
          <div className={styles.contentContainerUpper}>
            {contentItem.contentTitle}
          </div>
          <div className={styles.contentContainerLower}>

            <div className={styles.contentTextDiv}>
              <p className={styles.speakerName}>{contentItem.contentInfo}</p>

            </div>
          </div>
          <div className={styles.buttonPositionDiv}>
            <button className={styles.ctaButton} style={{ width: '120px', height: '40px' }}>Find out more</button>
          </div>
        </div>
      );
    });
  };

  render () {
    return (
      <div>
        {this.props.showMobile ?
          <CustomOnDemandMobile
            onDemandContent={this.state.onDemandContent}
            userProfile={this.props.userProfile}
            holdingVersion={this.props.holdingVersion}
          />
          :
          this.state.visualGallery ?
            <div className="imageNavDiv">
              <video
                className="imageNavBackground"
                autoPlay
                muted
                id="introVideo"
                onEnded={() => this.setState({ introVideoComplete: true })}
              >
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/onDemand.webm"}
                  type="video/webm"
                />
                <source
                  src={window.$videoPath + "visuals/" + this.props.language + "/onDemand.mp4"}
                  type="video/mp4"
                />
              </video>

              <CSSTransition
                in={this.state.introVideoComplete}
                timeout={1500}
                classNames="imageNavBackground"
                unmountOnExit
              >
                <div>
                  <img
                    className="imageNavBackground"
                    src={window.$videoPath + "visuals/" + this.props.language + "/onDemand.jpg"}
                    alt="Background"
                  ></img>

                  <Link to="/lobby4">
                    <img
                      className={styles.exitHoverHotspot}
                      src="/hoverShapes/hoverSquare.png"
                      alt="Exit"
                    ></img>
                  </Link>

                  <div className={styles.visualTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
                    <h1 className={styles.visualPageTitle}>ON-DEMAND</h1>
                  </div>

                  <section className={styles.onDemandItemsArea}>
                    {this.contentGallery(1)}
                  </section>
                </div>
              </CSSTransition>
            </div>
            :
            <>
              <div className={styles.galleryBackgroundDiv}>
                {/**<img
            className="imageNavBackground"
            src={window.$videoPath + "background.png"}
            alt="Background"
          ></img>*/}
              </div>

              <div className={styles.customTabBarToTop}>
                {this.props.holdingVersion ?
                  <Link to={"/holding"} className={styles.backLink}>
                    <div className={styles.tabBackDiv}>
                      <img
                        className={styles.backArrow}
                        src={window.$videoPath + "icons/backArrow.png"}
                        alt="Back"
                      />
                      Back to Menu
                    </div>
                  </Link>
                  :
                  <Link to={"/lobby"} className={styles.backLink}>
                    <div className={styles.tabBackDiv}>
                      <img
                        className={styles.backArrow}
                        src={window.$videoPath + "icons/backArrow.png"}
                        alt="Back"
                      />
                      Back to Lobby
                    </div>
                  </Link>
                }
              </div>


              <div className={styles.titleAndSearchBar}>
                <h1 className={styles.pageTitle}>
                  <strong>ON-DEMAND</strong>
                </h1>

                <div className={styles.searchTitleArea}>
                  <p></p>
                </div>
              </div>
              <div className={styles.contentContainerDiv}>
                {/**this.onDemandGallery(1).length > 0 ? <h1 className={styles.dayTitle}>Day 1</h1> : null*/}
                <section className={styles.contentSegmentsArea}>{this.contentGallery(1)}</section>
                {/**this.onDemandGallery(1)*/}
              </div>
            </>
        }

        <CustomOnDemandModal
          showOnDemandModal={this.state.showOnDemandModal}
          closeOnDemandModal={() => this.setState({ showOnDemandModal: false })}
          selectedOnDemand={this.state.selectedOnDemand}
          userProfile={this.props.userProfile}
        />
      </div>
    );
  }
}
