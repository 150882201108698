import React, { Component } from "react";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import styles from "./../UserSessions.module.scss";

export default class UserSessionsOptionsBreakouts1 extends Component {


    render () {
        return (
            <div>

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={0}
                        disabled={this.props.session1Disabled}
                        className={styles.sessionOption}
                    //data-tip="<h4>No session selected</h4><br /><br />"
                    // data-for={"sessionInfo"}
                    >
                        No session selected
                    </ToggleButton>
                </ToggleButtonGroup>


                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={11}
                        disabled={this.props.session1Disabled}
                        className={styles.sessionOption}
                    //data-tip="<h4>Additional text 1</h4><br /><br />"
                    //data-for={"sessionInfo"}
                    >
                        Making the most out of your Breakouts
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={12}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                    //data-tip="<h4>Additional Text 2</h4><br /><br />"
                    //data-for={"sessionInfo"}
                    >
                        Widgets to enhance your attendee experience
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    type="radio"
                    value={this.props.breakoutSessionSelection}
                    name={"breakoutSessionSelection"}
                    onChange={this.props.handleBreakoutSessionSelectionChange}
                    className={styles.buttonGroupRow}
                >
                    <ToggleButton
                        value={13}
                        disabled={this.props.session3Disabled}
                        className={styles.sessionOption}
                    //data-tip="<h4>Additional Text 2</h4><br /><br />"
                    //data-for={"sessionInfo"}
                    >
                        Bringing your imagination to life
                    </ToggleButton>

                </ToggleButtonGroup >

            </div >
        );
    }
}