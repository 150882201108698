export const TRANSLATIONS_EN = {
    login_help: "Help",
    login_welcome: "Welcome",
    login_sign_in_if_registered: "Already registered? Sign in:",
    login_email_address: "Email address",
    login_email_password: "Password",
    login_forgot_password: "Forgot Password?",
    login_register: "New User? Register Here",
    login_login: "Login",
    login_back: "Back",
    reset_password_reset_password: "Reset Password",
    reset_password_info_text: "If a valid user account exists for the email address entered, you will shortly receive an email with a link to reset your password.",
    register_first_name: "First Name",
    register_surname: "Surname",
    register_job_title: "Job Title",
    register_email: "Email",
    register_password: "Password",
    register_confirm_password: "Confirm Password",
    register_profile_picture: "Profile Picture Upload",

    attendees: "ATTENDEES",
};

