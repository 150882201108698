import React, {useState, useEffect} from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import JoinMeetingModal from "./JoinMeetingModal";
import { requestPoints } from "../helpers/requestPoints.js";
import styles from "./NetworkingMobile.module.scss";

export default function Breakouts (props) {
  const [showJoinMeetingModal, setShowJoinMeetingModal] = useState(false);

  useEffect(() => {
    if (props.openNetworkingModal) {
      setShowJoinMeetingModal(true);
    }
  }, []);

  return (
    <div className={styles.mobileListView} >
      <div className={styles.mobileTitleArea}>
        <h1 className={styles.mobileTitleText}>DECK</h1>
        <Link to="/lobby"><Button>Back to Lobby</Button></Link>
      </div>

      <Link to="/gallery">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}>Attendees</h3>
        </div>
      </Link>

      
        <div
          className={styles.mobileListItem}
          onClick={() => setShowJoinMeetingModal(true)}
        >
          <h3 className={styles.mobileListTitle}>Networking</h3>
        </div>

      <Link to="/onDemand">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}>On-demand</h3>
        </div>
      </Link>
      <a href="https://armrunner.com/" target="_blank" rel="noopener noreferrer">
        <div
          className={styles.mobileListItem}
        >
          <h3 className={styles.mobileListTitle}>Gaming</h3>
        </div>
      </a>

      <JoinMeetingModal
              showJoinMeetingModal={showJoinMeetingModal}
              setShowJoinMeetingModal={setShowJoinMeetingModal}
              breakout1Live={props.breakout1Live}
              breakout2Live={props.breakout2Live}
            />

    </div>
  );
}
