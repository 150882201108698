import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import { CSSTransition, TransitionGroup } from "react-transition-group";

// Pre-login
import Login from "./registrationAndLogin/Login";
import StaffLogin from "./registrationAndLogin/StaffLogin";
import SSOLogin from "./registrationAndLogin/SSOLogin";
import ConfirmUserEmail from "./registrationAndLogin/ConfirmUserEmail";
import RequestPasswordReset from "./registrationAndLogin/RequestPasswordReset";
import ResetPassword from "./registrationAndLogin/ResetPassword";
import Logout from "./Logout";

// Custom ARM holding site
import Holding from "./holding/Holding";
import RegistrationPage from "./registrationAndLogin/RegistrationPage";

// Standard spaces
import Home from "./Home";
import Lobby from "./imageNav/Lobby";
import Plenary from "./imageNav/Plenary";
import Networking from "./imageNav/Networking";
import Breakouts from "./breakouts/Breakouts";
import Breakout from "./breakouts/Breakout";
import LargeBreakout from "./breakouts/LargeBreakout";
import CustomBreakout from "./breakouts/CustomBreakout";
import ResourceLibrary from "./breakouts/ResourceLibrary";
import HoldingPage from "./registrationAndLogin/HoldingPage";


// Gallery spaces
import Gallery from "./gallery/Gallery";
import PresentersGallery from "./gallery/PresentersGallery";
import OnDemand from "./gallery/OnDemand";
import VisualOnDemand from "./gallery/VisualOnDemand";
import JobSearch from "./gallery/JobSearch";
import CustomOnDemand from "./customOnDemand/CustomOnDemand";

// 1:1 Meetings
import Meetings from "./meetings/Meetings";
import Video from "./video/Video";

// Exhibition
import ExhibitionStandAnswers from "./questions/ExhibitionStandAnswers";

// Live Stream admin
import LiveStreamAdminList from "./muxAdmin/liveStream/LiveStreamAdminList";
import ViewLiveStream from "./muxAdmin/liveStream/ViewLiveStream";
import CreateLiveStream from "./muxAdmin/liveStream/CreateLiveStream";

// Mux Video Admin
import VideoAdminList from "./muxAdmin/videoLibrary/VideoAdminList";
import ViewVideo from "./muxAdmin/videoLibrary/ViewVideo";
import CreateVideo from "./muxAdmin/videoLibrary/CreateVideo";

// Analytics
import Analytics from "./analytics/Analytics";

// Admin Menu
import AdminMenu from "./admin/AdminMenu";
import UserList from "./userManagement/UserList";
import RemoteControl from "./remoteControl/RemoteControl";

// Access control
import ProtectedRoute from "./ProtectedRoute";
import AdminRoute from "./AdminRoute";


class Routes extends React.Component {
  render () {
    const { location } = this.props;
    return (
      <main>
        <TransitionGroup className="transition-group">
          <CSSTransition
            key={location.key}
            timeout={{ enter: 1000, exit: 1000 }}
            classNames="fade"
          >
            <section className="route-section">
              <Switch location={location}>

                {/**Login and Registration Routes - not protected*/}
                <Route path="/" exact component={Home} />

                <Route
                  path="/login"
                  render={(props) => (
                    <Login
                      {...props}
                      cookies={this.props.cookies}
                      checkLoginStatus={this.props.checkLoginStatus}
                      setLanguage={this.props.setLanguage}
                      showMobile={this.props.showMobile}
                    />
                  )}
                />
                <Route
                  path="/staffLogin"
                  render={(props) => (
                    <StaffLogin
                      {...props}
                      cookies={this.props.cookies}
                      checkLoginStatus={this.props.checkLoginStatus}
                      setLanguage={this.props.setLanguage}
                    />
                  )}
                />
                <Route
                  path="/ssoLogin"
                  render={(props) => (
                    <SSOLogin
                      {...props}
                      checkLoginStatus={this.props.checkLoginStatus}
                      showMobile={this.props.showMobile}
                    />
                  )}
                />
                <Route path="/confirmUserEmail" component={ConfirmUserEmail} />
                <Route
                  path="/requestPasswordReset"
                  component={RequestPasswordReset}
                />
                <Route path="/resetPassword" component={ResetPassword} />
                <Route
                  path="/logout"
                  render={(props) => (
                    <Logout
                      {...props}
                      checkLoginStatus={this.props.checkLoginStatus}
                      setShowProfileModal={this.props.setShowProfileModal}
                    />
                  )}
                />

                {/**Custom ARM Holding Site Routes - protected*/}
                <ProtectedRoute
                  path="/holding"
                  component={Holding}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                  setShowProfileModal={this.props.setShowProfileModal}
                  showProfileOnLogin={this.props.showProfileOnLogin}
                  userProfile={this.props.userProfile}
                  showMobile={this.props.showMobile}
                  cookies={this.props.cookies}
                />

                <ProtectedRoute
                  path="/register"
                  component={RegistrationPage}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                  setShowProfileModal={this.props.setShowProfileModal}
                  showProfileOnLogin={this.props.showProfileOnLogin}
                  showMobile={this.props.showMobile}
                  userProfile={this.props.userProfile}
                />

                {/**Image Navigation Area Routes*/}
                <ProtectedRoute
                  path="/lobby"
                  component={Lobby}
                  cookies={this.props.cookies}
                  setShowHelpModal={this.props.setShowHelpModal}
                  setShowAgendaModal={this.props.setShowAgendaModal}
                  setShowLeaderboardModal={this.props.setShowLeaderboardModal}
                  setShowProfileModal={this.props.setShowProfileModal}
                  lobbyScreen={this.props.lobbyScreen}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                  cornerMenuStatus={this.props.cornerMenuStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                />

                <ProtectedRoute
                  path="/plenary"
                  component={Plenary}
                  plenaryContent={this.props.plenaryContent}
                  plenaryPosition={this.props.plenaryPosition}
                  plenaryOpenStatus={this.props.plenaryOpenStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                  language={this.props.language}
                  userProfile={this.props.userProfile}
                  showMobile={this.props.showMobile}
                />

                <ProtectedRoute
                  path="/holding"
                  component={HoldingPage}
                  language={this.props.language}
                />

                <ProtectedRoute
                  path="/networking"
                  component={Networking}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                  breakout1Live={this.props.breakout1Live}
                  breakout2Live={this.props.breakout2Live}
                />

                <ProtectedRoute
                  path="/networkingOpen"
                  component={Networking}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                  breakout1Live={this.props.breakout1Live}
                  breakout2Live={this.props.breakout2Live}
                  openNetworkingModal={true}
                />

                <ProtectedRoute
                  path="/breakouts"
                  component={Breakouts}
                  playVideo={true}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                />

                <ProtectedRoute
                  path="/breakout/:breakoutID"
                  component={Breakout}
                  breakout1Live={this.props.breakout1Live}
                  breakout2Live={this.props.breakout2Live}
                  breakout3Live={this.props.breakout3Live}
                  breakout4Live={this.props.breakout4Live}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                  userProfile={this.props.userProfile}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                />
                <ProtectedRoute
                  path="/largeBreakout/:breakoutID"
                  component={LargeBreakout}
                  breakout1Live={this.props.breakout1Live}
                  breakout2Live={this.props.breakout2Live}
                  breakout3Live={this.props.breakout3Live}
                  breakout4Live={this.props.breakout4Live}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                  zoomMeetingID={this.props.zoomMeetingID}
                  zoomPassword={this.props.zoomPassword}
                  webinarURL={this.props.webinarURL}
                  userProfile={this.props.userProfile}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                />
                <ProtectedRoute
                  path="/customBreakout/:breakoutID"
                  component={CustomBreakout}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                  userProfile={this.props.userProfile}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                />
                <ProtectedRoute
                  path="/breakoutsStill"
                  component={Breakouts}
                  playVideo={false}
                  language={this.props.language}
                  showMobile={this.props.showMobile}
                />
                <ProtectedRoute
                  path="/resourceLibrary"
                  component={ResourceLibrary}
                  language={this.props.language}
                />

                {/** Galleries*/}
                <ProtectedRoute
                  path="/gallery"
                  component={Gallery}
                  cornerMenuStatus={this.props.cornerMenuStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                  userProfile={this.props.userProfile}
                />

                <ProtectedRoute path="/visualGallery"
                  component={Gallery}
                  visualGallery={true}
                  userProfile={this.props.userProfile}
                  cornerMenuStatus={this.props.cornerMenuStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                />

                <ProtectedRoute
                  path="/presentersGallery"
                  cornerMenuStatus={this.props.cornerMenuStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                  component={PresentersGallery}
                />

                <ProtectedRoute path="/standardOndemand"
                  component={OnDemand}
                  userProfile={this.props.userProfile}
                  showMobile={this.props.showMobile}
                  cornerMenuStatus={this.props.cornerMenuStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                />

                <ProtectedRoute path="/onDemand"
                  component={CustomOnDemand}
                  userProfile={this.props.userProfile}
                  showMobile={this.props.showMobile}
                  cornerMenuStatus={this.props.cornerMenuStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                />

                <ProtectedRoute path="/onDemandPre"
                  component={CustomOnDemand}
                  userProfile={this.props.userProfile}
                  showMobile={this.props.showMobile}
                  cornerMenuStatus={this.props.cornerMenuStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                  holdingVersion={true}
                />

                <ProtectedRoute path="/visualOndemand"
                  component={VisualOnDemand}
                  userProfile={this.props.userProfile}
                  language={this.props.language}
                />
                <ProtectedRoute
                  path="/jobSearch"
                  component={JobSearch}
                  showMobile={this.props.showMobile}
                  cornerMenuStatus={this.props.cornerMenuStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                />

                {/** 1:1 Meetings */}
                <ProtectedRoute
                  path="/meetings"
                  component={Meetings}
                  userProfile={this.props.userProfile}
                  showMobile={this.props.showMobile}
                  cornerMenuStatus={this.props.cornerMenuStatus}
                  setCornerMenuStatus={this.props.setCornerMenuStatus}
                />
                <ProtectedRoute
                  path="/video/:meetingID"
                  component={Video}
                  userProfile={this.props.userProfile}
                  showMobile={this.props.showMobile}
                />
                <ProtectedRoute path="/video" component={Video} />

                {/** Admin page for Q&A pages */}
                <ProtectedRoute
                  path="/exhibitionStandAnswers/:exhibitionStandID/:answerToken"
                  component={ExhibitionStandAnswers}
                />

                {/** Live stream admin */}
                <AdminRoute
                  path="/liveStreamAdminList"
                  component={LiveStreamAdminList}
                />
                <AdminRoute
                  path="/viewLiveStream/:liveStreamID"
                  component={ViewLiveStream}
                  userProfile={this.props.userProfile}
                />
                <AdminRoute
                  path="/createLiveStream"
                  component={CreateLiveStream}
                />

                {/** Mux video admin */}
                <AdminRoute
                  path="/videoAdminList"
                  component={VideoAdminList}
                />
                <AdminRoute
                  path="/viewVideo/:videoID"
                  component={ViewVideo}
                  userProfile={this.props.userProfile}
                />
                <AdminRoute
                  path="/createVideo"
                  component={CreateVideo}
                />

                {/** Analytics */}
                <AdminRoute path="/analytics" component={Analytics} />

                {/** Admin pages */}
                <AdminRoute path="/adminMenu" component={AdminMenu} />
                <AdminRoute path="/remoteControl" component={RemoteControl} />
                <AdminRoute path="/userList" component={UserList} />

              </Switch>
            </section>
          </CSSTransition>
        </TransitionGroup>
      </main>
    );
  }
}

// Higher order component withRouter is used to include location in props to Router for use by TransitionGroup
export default withRouter(Routes);
